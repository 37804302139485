import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateInfoUser,getCommandeById,UpdateUserAddresse,UpdateUserAddresseInf} from "../../../actions/user";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { defaultimg } from "../../../assets/images";
import { MenuVendeur } from "../../../components";

const Page = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { commandeslist } = useSelector(state => state.restaurent)

    useEffect(() => {
       // dispatch(getCommandeById(user.id));
    }, []);

    const history = useHistory();
    function gotoCmd(item) {
       history.push(item);
     }
    
    return (
        <div className=" dashboard" >
            <Container>
                <Row>
            <Col  className="text-center mx-auto "  lg="3">

           
               
            </Col>
              
           <Col  className="text-center mx-auto " style={{padding: '0px'}}  >
            
           <div className='flxdash1'>
                <br/>
                 <h6>Gérer ma boutique</h6>

                <div className='item item7790'>

                      <div className='bg667 item887'> <a href='javascript:void(0)' onClick={()=>gotoCmd('/vendeur/ajouter-produit/')} className='coldash'><label>Ajouter des articles</label> <img src='/images/icogererb1.png' /> </a> </div>
                      

                </div>

                  <div className='item item7790'>

                      <div className='bg668 item887'> <a href='javascript:void(0)' onClick={()=>gotoCmd('/vendeur/liste-produits/')} className='coldash'><label>Voir tous les articles</label> <img src='/images/icogererb.png' /> </a> </div>
                      

                </div>

                <div className='item item7790'>

                      <div className='bg669 item887'> <a href='javascript:void(0)' onClick={()=>gotoCmd('/vendeur/promo/liste-produits/')} className='coldash'><label>Gérer les articles en promo </label> <img src='/images/icogererb2.png' /> </a> </div>
                      

                </div>

          

           </div>

                    <form className="mb-5" style={{display:'none'}}>
                           
                        
                          <Row className="mb-3"  >


                              <div className='col-md-4'>
                                  <div className="inline-facts-wrap gradient-bg " style={{height: '113.6px'}}>
                                                <div className="inline-facts">
                                                    <i className="fa fa-eye"></i>
                                                    <div className="milestone-counter">
                                                        <div className="stats animaper">
                                                            <div className="num" data-content="0" data-num="1054">14</div>
                                                        </div>
                                                    </div>
                                                    <h6>Total colis récupérés</h6>
                                                </div>
                                                
                                    </div>
                              </div>

                              <div className='col-md-4'>
                                  <div className="inline-facts-wrap gradient-bg1 " style={{height: '113.6px'}}>
                                                <div className="inline-facts">
                                                    <i className="fa fa-check"></i>
                                                    <div className="milestone-counter">
                                                        <div className="stats animaper">
                                                            <div className="num" data-content="0" data-num="1054">10</div>
                                                        </div>
                                                    </div>
                                                    <h6>Total colis livrés</h6>
                                                </div>
                                                
                                    </div>
                              </div>

                               <div className='col-md-4'>
                                  <div className="inline-facts-wrap gradient-bg2 " style={{height: '113.6px'}}>
                                                <div className="inline-facts">
                                                    <i className="fa fa-clock-o"></i>
                                                    <div className="milestone-counter">
                                                        <div className="stats animaper">
                                                            <div className="num" data-content="0" data-num="1054">7</div>
                                                        </div>
                                                    </div>
                                                    <h6>Total colis non livrés</h6>
                                                </div>
                                                
                                    </div>
                              </div>

                              <div className='col-md-4'>
                                  <div className="inline-facts-wrap gradient-bg4 " style={{height: '113.6px'}}>
                                                <div className="inline-facts">
                                                    <i className="fa fa-barcode"></i>
                                                    <div className="milestone-counter">
                                                        <div className="stats animaper">
                                                            <div className="num" data-content="0" data-num="1054">105</div>
                                                        </div>
                                                    </div>
                                                    <h6>Total produits</h6>
                                                </div>
                                                
                                    </div>
                              </div>

                            <div className='col-md-4'>
                                  <div className="inline-facts-wrap gradient-bg5 " style={{height: '113.6px'}}>
                                                <div className="inline-facts">
                                                    <i className="fa fa-anchor"></i>
                                                    <div className="milestone-counter">
                                                        <div className="stats animaper">
                                                            <div className="num" data-content="0" data-num="1054">76</div>
                                                        </div>
                                                    </div>
                                                    <h6>Total commandes</h6>
                                                </div>
                                                
                                    </div>
                              </div>



                             <div className='col-md-4'>
                                  <div className="inline-facts-wrap gradient-bg6 " style={{height: '113.6px'}}>
                                                <div className="inline-facts">
                                                    <i className="fa fa-credit-card-alt"></i>
                                                    <div className="milestone-counter">
                                                        <div className="stats animaper">
                                                            <div className="num" data-content="0" data-num="1054">1054 ACF</div>
                                                        </div>
                                                    </div>
                                                    <h6>Total montant</h6>
                                                </div>
                                                
                                    </div>
                              </div>
                           
                          
                           

                          </Row>

                    </form>
                        

                    </Col>
                      <Col  className="text-center mx-auto my-5"  lg="3"></Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;