import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Alert, Button,Nav,ListGroup,ListGroupItem,Card,Table,Form,Modal,CloseButton} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { ProductItem, RestaurentItem } from "../../../components";
import { updateInfoUser,getOneCommandeById,UpdateUserAddresse,UpdateUserAddresseInf} from "../../../actions/user";
import "react-datepicker/dist/react-datepicker.css";
import {  getRestaurentById } from "../../../actions/restaurent";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { apiClient } from "../../../actions/api"
import { markerHome,defaultimg } from "../../../assets/images";
import { MenuVendeur } from "../../../components";
import { SET_CURRENT_COMMANDE} from "../../../constants/actions";
import moment from 'moment'
import GoogleMapReact from 'google-map-react';
import Slider from "react-slick";

const Page = () => {

    const { id } = useParams()
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { commandeinfo,currentRestau } = useSelector(state => state.restaurent)
    const [latitudeliv,setLatitudeliv]=useState(47.7198437);
    const [langitudelivreur,setLangitudelivreur]=useState(-1.3211272);
    const [detailcommande,setDetailcommande]=useState([]);
    const [commandeinfoadresse,setCommandeinfoadresse]=useState({});
     const [fairelepont,setFairelepont]=useState(0);
     const [checkdtype,setCheckdtype]=useState('');
      const [vendeur,setVendeur]=useState('');
       const [filtres,setFiltres]=useState([]);
       const [counter, setCounter] = useState('')
       const [vendeurs,setVendeurs]=useState([]);
        const [livreurs,setLivreurs]=useState([]);
       const [curentvendeur,setCurrentvendeur]=useState({});

      const [showmodal, setShowmodal] = useState(false);
      const handleClose = () => setShowmodal(false);


    useEffect(() => {

       let paramsd={
              datelivraison:id,
              user_id:user.user_id,
              token:user.api_token,
              api_token:user.api_token,
              type:'adminsuivilivraison'
           };
           
           apiClient().post(`/getdetailcommande`,paramsd)
           .then((res) => {
         
             setDetailcommande(res.data.commande)
             setVendeurs(res.data.vendeurs)
             setLivreurs(res.data.livreurs)
             
             //dispatch({type:SET_CURRENT_COMMANDE,payload:res.data.commande})
           
           }) 

 
    }, []);

 useEffect(() => {
   if(counter){
      setFormFiltre()
   }
    
}, [counter]);


   const HandleFiltre =  (slug,type,e) => {
      
       e.preventDefault();
          switch(type){
             case 'vendeur':
                
                if(slug){
                 
                   setCurrentvendeur(vendeurs[slug])
                   setVendeur(vendeurs[slug].user_id)
                }
                else{
                    setCurrentvendeur({})
                   setVendeur('')
                }
                
                setCounter(1)

             break;

            
          }

        
            
 
    }


    const  setFormFiltre = async () => {
    
     

            let paramsd={
              datelivraison:id,
              user_id:user.user_id,
              token:user.api_token,
              api_token:user.api_token,
              type:'adminsuivilivraison',
              filtre:vendeur ? '1' : '',
              vendeur:vendeur
           };
           
           apiClient().post(`/getdetailcommande`,paramsd)
           .then((res) => {
         
             setDetailcommande(res.data.commande)
              setCounter('')
           
           }) 
      

    }


const setstatuscommande =  (status) => {
    
        let paramsd={
              id_order:id,
              user_id:user.user_id,
              token:user.api_token,
              api_token:user.api_token,
              status:status,
           };
           
           apiClient().post(`/setstatusorder`,paramsd)
           .then((res) => {
             setCurrentvendeur({})
             setVendeur('')
         
             setDetailcommande(res.data.commande)
             dispatch({type:SET_CURRENT_COMMANDE,payload:res.data.commande})
           
           })

}

 const handleChange = async (e,item,key) => {
    
      //  setCheckdtype(item.order_id+'_'+e.target.value)

         let newcommandes=[...detailcommande];
         newcommandes[key].statuslivraison=e.target.value;
         setDetailcommande(newcommandes)
             
  
    }


     const setAdresse = async (e,item) => {
    
        setCommandeinfoadresse(item)
        setShowmodal(true)
            
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        formDataObj.user_id=user.user_id
        formDataObj.token=user.api_token
        formDataObj.api_token=user.api_token
        formDataObj.datelivraison=id
        formDataObj.typereq='admin'



 
  
           
        apiClient().post(`/updateorder`,formDataObj)
           .then((res) => {
         
             setDetailcommande(res.data.commande)
             setCurrentvendeur({})
             setVendeur('')
               alert('Votre demande a bién été enregistrée!')
             
           
           })

    }


const getRetours =  (detail) => {
      let retour=0;
       for(let i=0; i < detail.length; i++){

           if(detail[i]?.retour==1){
              retour=retour+1;
           }

      }

      return retour;

}


const getNbArticles =  (detail) => {
      let nbarticles=0;
       for(let i=0; i < detail.length; i++){

          
              nbarticles=nbarticles+detail[i].quantity;
           

      }

      return nbarticles;

}


const getValeurs =  (detail) => {
      let valeur=0;
       for(let i=0; i < detail.length; i++){

          
              valeur=valeur+(parseInt(detail[i].qterecupere - detail[i].qteretour) * parseFloat(detail[i].product_price) );
           

      }

      return valeur;

}






 const createSelectItems =  (qte,r) => {
     let items = [];       
      items.push(<option key={0} value={0}>Quantité</option>);  
     for (let i = 1; i <= qte; i++) {             
         
           if(r==i) {
         items.push(<option key={i} selected value={i}>{i}</option>); 
       }          
           
      else{
         items.push(<option key={i} value={i}>{i}</option>); 
      }  
           
     }
     return items;
 }  



    var settingsSlide = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide:0,
     responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
             infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            
            slidesToShow: 1,
            slidesToScroll: 1,
             infinite: true,
            dots: false
          }
        }
      ]
   };





   const { zoom } = useState(11);
   const [center,setCenter]=useState({lat:47.7198437,lng:-1.3211272});
   const AnyReactComponent = ({ text }) => <Row><img src={markerHome} alt="" className={"imgliv"}  /></Row>;
    return (
        <div className="register detailcmd suivilivraison " >
            <Container>
                <Row>
          
              
                    <Col  className="text-center mx-auto my-5"  >
                        
                 
                    
                  
         
<br/>
<Row className="g-5" >
                 
                  <Col md={12}  >

<div className='detcommande'>
     
        <div className='items'>
                            
                              <div className='rowitmtop'>
                              { commandeinfo.createdat ?

                                    <span> {moment(new Date(commandeinfo.createdat)).format("DD MMM YYYY")}</span>
                                    :
                                    <span></span>
                              }
                                   
                                   
                                   <div className='mescommandes'>
                                      <select className="orderbyorder"  style={{width: '116px'}} onChange={(event)=>HandleFiltre(event.target.value,'vendeur',event)}  >
                                        <option value=''>Vendeur(s)</option>
                                         {vendeurs?.map((item,key) => (
                                            <option value={key}>{item.nomboutique ? item.nomboutique : item.name}</option>
                                          ))}
                                       </select>
                                   </div>
                                   
                                     
                              </div>

                                <div className='process-item'>

                                   
                                     
                                  <div className='rowsitm'>
                                      <div> <span>- Colis récupérées: </span></div>
                                       <div><b>{parseInt(commandeinfo.nbcolirec)}</b></div>
                                       
                                    </div>
                                     <div className='rowsitm'>
                                      <div> <span>- Valeurs des colis: </span></div>
                                       <div><b>{parseFloat(commandeinfo.total).toFixed(2)} CFA</b></div>
                                       
                                    </div>
                                    <div className='rowsitm'>
                                       <div> <span>- Total articles: </span></div>
                                       <div><b>{parseInt(commandeinfo?.totalarticles)}</b></div>
                                       
                                    </div>

                                 </div>
                             
       </div>

 </div>
                 
                       


 
 
 <Row>
 
 <Col md={12}>

 


<div class="col-md-12"><h4>Détails </h4><div><img src="/images/fleshtobtm.png" style={{width: '30px'}} /></div></div>



    <Slider {...settingsSlide}>
          
             {detailcommande?.map((item,key) => (


 <form className="mb-5" onSubmit={handleSubmit} >

<div className='tabsitems'>

   <div className='items '>
                            
                                <div className='rowitmtop'>
                                   <span>{
                                 item.type_commande=='1' ? 'Fashion Style' : item.type_commande=='2' ? 'Afro Création' :item.type_commande=='3' ? 'Ventout' : 'Sur commande' 
                              }</span> 
                                  <div>

                                  {
                                    vendeur &&
                                       <a href='javascript:void(0)' onClick={(e)=>setAdresse(e,curentvendeur)} style={{color: '#004abf',fontWeight: 'bold',lineHeight: '0px'}} >Vendeur: {curentvendeur?.nomboutique ? curentvendeur?.nomboutique: curentvendeur?.name }</a>
                                  }

                                 

                                  </div>
                                </div>



           <Table striped bordered hover >
            
              <tbody>
            <tr>
            
            <td className='left' style={{fontWeight:'bold'}}>
               Numéro de commande
            </td>
             <td>
              
                  Réf: {item?.track_code}
                  <input type='text' value={item?.order_id} name='order_id' style={{display:'none'}} />
                       
            </td>
            </tr> 


        
    <tr>
            
            <td className='left' style={{fontWeight:'bold'}}>
               Date commande
            </td>
             <td>
              
              
                    {moment(new Date(item?.created_at)).format("DD MMM YYYY")}
                       
            </td>
    </tr>
 

    <tr>
            
            <td className='left' style={{fontWeight:'bold'}}>
               Date livraison
            </td>
             <td>
             { item?.datelivraison && 
              moment(new Date(item?.datelivraison)).format("DD MMM YYYY")

             }
             <br/>
             <input type='date' name={"datelivraisonupdate"} />
              
              
                
                       
            </td>
    </tr>

 

    <tr>
            <td className='left' style={{fontWeight:'bold'}}>
               Sous Total
            </td>
             <td>
              
                      {(parseFloat(item?.total_price) - parseFloat(item?.fraislivraison)).toFixed(2) }  CFA
            </td>
    </tr>

    <tr>
            <td className='left' style={{fontWeight:'bold'}}>
               frais de livraison
            </td>
             <td>
              
                      {parseFloat(item?.fraislivraison).toFixed(2) }  CFA
            </td>
    </tr>
    {
      item?.type_commande==4 &&

          <tr>
            <td className='left' style={{fontWeight:'bold'}}>
               Mode Expédition
            </td>
             <td>
              
                      { item?.modeexpedition}  
            </td>
    </tr>


    }


    <tr>
            <td className='left' style={{fontWeight:'bold'}}>
               Total
            </td>
             <td>
              
                      {parseFloat(item?.total_price).toFixed(2) }  CFA
            </td>
    </tr>


    <tr>
            <td className='left' style={{fontWeight:'bold'}}>
                Mode de paiement
            </td>
             <td>
              
              {
                parseInt(item.type_paiement) == 1 ? 'Paiement à la livraison' :  parseInt(item.type_paiement) == 2 ? 'Orange Money, Moov Money,MTN Money': parseInt(item.type_paiement) == 3 ? 'Wave' : 'Paiement par carte bancaire'
               }
            </td>
    </tr>


   <tr>
            <td className='left' style={{fontWeight:'bold'}}>
               Valeur 
            </td>
             <td>
              
                      {getValeurs(item?.details_order).toFixed(2) }  CFA
            </td>
    </tr>


    <tr>
            <td className='left' style={{fontWeight:'bold'}}>
                Montant reçu
            </td>
             <td>
              
                      {item?.montantrecu.toFixed(2) }  CFA
            </td>
    </tr>
    {
      item?.fraislivreur==0 ? 

      <tr>
            <td className='left' style={{fontWeight:'bold'}}>
               Frais livraison (Séléctionner  par le livreur)
            </td>
             <td>
              
                     Non
            </td>
    </tr>
    : item?.fraislivreur==1 ? 
    <tr>
            <td className='left' style={{fontWeight:'bold'}}>
               Frais livraison (Séléctionner  par le livreur)
            </td>
             <td>
              
                     Oui
            </td>
    </tr>
    :
    ''

    }

    



    <tr>
            <td className='left' style={{fontWeight:'bold'}}>
               Statut
               <div className='alert alert-warning'>merci de marquer la commande comme Traitée si la commande est bien Livrée !</div>
            </td>
             <td>
              
                <div className='mescommandes' style={{    textAlign: 'center',margin: '0 auto'}}>

                          <select className="orderbyorder" name={"statut"} style={{width: '116px'}} defaultValue={item?.order_status}>
                               <option value={0}>En attente</option>
                               <option value={1}>Traitée</option>
                               <option value={6}>Incomplet</option>
                               <option value={5}>Validée</option>
                               <option value={4}>En cours de livraison</option>
                               <option value={3}>Livrée</option>
                               <option value={2}>Annulée</option>
                               <option value={7}>Refusée</option>
                          </select>
                </div>

             


                     
            </td>
    </tr>

  <tr>
            <td className='left' style={{fontWeight:'bold'}}>
               Livreur
                
            </td>
             <td>

               <select className="orderbyorder" name='employee_id'  >
                                        <option value=''>Assigner un livreur</option>
                                         {livreurs?.map((item1,key) => (
                                             item.employee_id==item1.user_id ? 
                                              <option selected value={item1.user_id}>{item1.name}</option>
                                            :
                                             <option value={item1.user_id}>{item1.name}</option>
                                          ))}
                </select>

                     
            </td>
    </tr>




     <tr>

           <td colspan='2'>

           <div style={{    textAlign: 'left',marginBottom: '20px'}}>  <strong>Articles à récupérer</strong></div>

                 {item?.details_order?.map((item1) => ( 
                      
                <div class="form-check" style={{display:'flex',paddingLeft: '0px',marginBottom:'15px'}} >
                     <label title="" class="form-check-label" style={{minWidth: '153px',fontWeight: 'bold'}}>{item1.pro_name}</label>
                      <div className='mescommandes' style={{    textAlign: 'center',margin: '0 auto'}}>

                          <select className="orderbyorder" name={"quantity_"+item1.details_order_id} style={{width: '116px'}} >
                             {createSelectItems(item1.quantity,item1.qterecupere)}
                          </select>
                          <input type='text' value={item1.details_order_id} name={"product_id_"+item1.details_order_id}  style={{display:'none'}}  />

                          
                        </div>
                  </div>
                  
                  ))}
             
                   
              

            </td>
     </tr>

     <tr>

           <td colspan='2'>

           <div style={{    textAlign: 'left',marginBottom: '20px'}}>  <strong>Retour lors de la livraison</strong></div>

                 {item?.details_order?.map((item1) => ( 
                      
                <div class="form-check" style={{display:'flex',paddingLeft: '0px',marginBottom:'15px'}} >
                     <label title="" class="form-check-label" style={{minWidth: '153px',fontWeight: 'bold'}}>{item1.pro_name}</label>
                      <div className='mescommandes' style={{    textAlign: 'center',margin: '0 auto'}}>

                          <select className="orderbyorder" name={"quantity_retour"+item1.details_order_id} style={{width: '116px'}} >
                             {createSelectItems(item1.quantity,item1.qteretour)}
                          </select>
                          <input type='text' value={item1.details_order_id} name={"product_id_retour"+item1.details_order_id}  style={{display:'none'}}  />

                          
                        </div>
                  </div>
                  
                  ))}
             
            </td>
     </tr>





 

 
  
    <tr>
            <td colspan="2">
                 <h5>Mixed Mode express a récupéré </h5>
                 <span>

                 {item?.details_order?.map((item1) => ( 

                   item1.recupere==1 &&
                        <span style={{paddingRight:'10px'}}>{item1?.qterecupere} {item1?.pro_name}</span>
                  ))}


                 </span>
            </td>
       
    </tr>

   <tr>
            <td colspan="2">
                 <h5 className='red'>Mixed mode express a retourné </h5>
                 {item?.details_order?.map((item1) => ( 

                   item1.retour==1 &&
                        <span style={{paddingRight:'10px'}}>{item1?.qteretour} {item1?.pro_name}</span>


                  ))}
            </td>
       
    </tr>
 
       <tr>
            <td colspan="2">
                 <h5  className='red'>Motif</h5>
                 <p>
                    {item?.motif}
                 </p>
            </td>
       
    </tr>
 


 

 
 
     <tr>
            <td colspan="2">
                 
                 <p>

                  
<br/>
                       <Button className="ps-btn ps-btn--fullwidth ps-btn-dark"  type="submit">
                                Valider
                         </Button>

                 </p>
            </td>
       
    </tr>

 
 

   



         </tbody>
</Table> 

         
                             
   </div>
</div>
</form>
 ))}               
                            

    </Slider>
  </Col>


</Row>
                   
  </Col>
                  
</Row>

<Row className="mb-3"  >
 <Col>
 
 </Col>

</Row>

 

 <Modal show={showmodal} onHide={handleClose} centered>
        <Modal.Header closeButton>
           Adresse du vendeur
        </Modal.Header>
        <Modal.Body>


    <div class="ps-block--shopping-total cardlivraison">
       <h4>Adresse du vendeur</h4>
       <br/>
        {
        curentvendeur?.nomboutique ? 
        <div>

        <label style={{display:'block'}}><strong>Boutique:</strong> {curentvendeur?.nomboutique}  </label>
       <label style={{display:'block'}}><strong>Lieu:</strong> {curentvendeur?.lieu}</label>
       <label style={{display:'block'}}><strong>Ville:</strong> {curentvendeur?.ville}</label>
       <label style={{display:'block'}}><strong>Téléphone: </strong>{curentvendeur?.tel}</label>
       <label style={{display:'block'}}><strong>Numéro WhatsApp: </strong>{curentvendeur?.whatsap}</label>

        </div>
        :

         <div>

       <label style={{display:'block'}}><strong>Vendeur:</strong> {curentvendeur?.name}  </label>
       <label style={{display:'block'}}><strong>Lieu:</strong> {curentvendeur?.lieuv}</label>
       <label style={{display:'block'}}><strong>Téléphone: </strong>{curentvendeur?.phone}</label>
       <label style={{display:'block'}}><strong>Numéro WhatsApp: </strong>{curentvendeur?.numwhatsap}</label>

        </div>

       }
    </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
           
        </Modal.Footer>
 </Modal>



 


 {
  /*

  <Row className="mb-3"  >
                           
                           <Col>
                           {commandeinfo.order?.state_id==10  && <Button className="w-100" variant="primary" type="submit">
                               En cours
                            </Button>}
                          
                            {commandeinfo.order?.state_id==20  && <Button className="w-100" variant="success" type="submit">
                              Validée
                            </Button>}
                             {commandeinfo.order?.state_id==40  && <Button className="w-100" variant="danger" type="submit">
                             Annulée
                            </Button>}
                         {commandeinfo.order?.state_id==30  && <Button className="w-100" variant="primary" type="submit">
                              Livrée
                            </Button>}
                          
                           </Col>
                        
                          </Row>
<Row className="g-5" >
                    <Col md={6}  >
                        <RestaurentItem item={currentRestau.establishment} />
                    </Col>
                  <Col md={6}  >
                  <h3>Menu</h3>
                         <Table striped bordered hover variant="dark">
                          <thead>
                            <tr>
                                <th>Désignation</th>
                                <th key="0">Prix</th>
                            
                             
                            </tr>
                          </thead>
              <tbody>
                      {commandeinfo.order?.products?.map((paniers) => (
                       <tr>
                        <td>{paniers._joinData.quantity}x{paniers.title}</td> <td>€{paniers._joinData.price.toFixed(2)}</td>
                         </tr>
                      
                    
                        

                        ))}

            <tr>
            <td>
 Prix livraison
            </td>
             <td>
              
                    {
                      commandeinfo?.order?.delivery_price==0 ? 'Gratuit'
                      :
                      '€'+commandeinfo?.order?.delivery_price.toFixed(2)
                    }
            </td>
            </tr>

   <tr>
            <td>
 Total
            </td>
             <td>
              
                      €{commandeinfo.order?.total.toFixed(2)}
            </td>
            </tr>

 <tr>
            <td>
 Mode de paiement
            </td>
             <td>
              
                Paiement par carte bancaire
            </td>
            </tr>

         </tbody>
</Table>
 
                   
                
  
                   

                    </Col>
                  
</Row>

    <Row className="mb-3"  >
    <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key:"AIzaSyBkcUpl985AUArtxvhkoOZokGN5ZdKDykE"}}
          defaultCenter={center}
          defaultZoom={11}
        >
          <AnyReactComponent
            lat={latitudeliv}
            lng={langitudelivreur}
            text="My Marker"
          />
        
        </GoogleMapReact>
        </div>
    
</Row>

  */
 }                       

  
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;