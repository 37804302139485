import React,{useEffect,useState} from "react";
import './styles.scss';
import { logo } from "../../assets/images";
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import { loginUser } from "../../actions/user";
import { LOGOUT_USER } from "../../constants/actions";
import {IS_SUBMITED} from "../../constants/actions"
import { registerUser } from "../../actions/user";
import { useParams } from "react-router";



const Page = () => {
    const dispatch = useDispatch();
    const { id } = useParams()
   
    const { user } = useSelector(state => state.user)
    const { issubmited } = useSelector(state => state.global)
    const history = useHistory()
    const [active,setActive]=useState(1);
    const [active2,setActive2]=useState(1);
    const [active3,setActive3]=useState(1);
    const [iflivreur,setIflivreur]=useState(false);
    


    const queryParams = new URLSearchParams(window.location.search);
    const back = queryParams.get('back');
    const tab = queryParams.get('tab');
    const livreur = queryParams.get('livreur');
 

 
    useEffect(() => {
 
        if(issubmited){
            dispatch({ type: IS_SUBMITED, payload: false })
            history.push("/compte-cree");
        }

        if(back || tab){
          setActive(2)
        }

       if(livreur){
          setActive2(3)
          setIflivreur(true)
        }



          
        if (user.user_id) {
        if(user.type=='A' || user.google_id=='A'){
            if(back){
               history.replace("/"+back)
            }
            else{
                   history.replace("/client/dashboard")
            }
        }
        else if(user.type=='V' || user.google_id=='V'){
              history.replace("/vendeur/dashboard/")
        
        }
        else if(user.type=='L' || user.google_id=='L'){
             history.replace("/livreur/dashboard/")
        
        }
        
        else if(user.type=='R' || user.google_id=='R'){
             history.replace("/ramasseurs/dashboard/")
        
        }

        else{
           history.replace("/admin/dashboard/")
        
        }
        }
    }, [user.user_id,issubmited])

    const handleTabs=(a)=>{
    
             setActive(a)
 
    }

    const handleTabs2=(a)=>{
       
             setActive2(a)
             if(a==2){
               history.push("/devenir-vendeur/")
             }
 
    }
      const handleTabs3=(a)=>{
        
             setActive3(a)
 
    }

    const handleSubmit = (e) => {


        e.preventDefault();

        let type='';
        if(active3==1){

             type='A';
              

        }
        else if(active3==2){
              type='V';
        
        }
        else{
              type='L';
        
        }

       
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())

         if(localStorage.getItem('token')){
            if(localStorage.getItem('typepush')=='ios'){
                formDataObj.push_ios=localStorage.getItem('token');
            }
            else{
                formDataObj.push_android=localStorage.getItem('token');
            }

        }





        
        dispatch(loginUser(formDataObj,type));
    }

  const handleSubmitAchteur = (e) => {


        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())

        if(localStorage.getItem('token')){
            if(localStorage.getItem('typepush')=='ios'){
                formDataObj.push_ios=localStorage.getItem('token');
            }
            else{
                formDataObj.push_android=localStorage.getItem('token');
            }

        }

        if(active2==1){
              dispatch(registerUser(formDataObj,'A'));
        }
        else{
            dispatch(registerUser(formDataObj,'L'));
        }
      
        
    }


     const handleSubmitLivreur = (e) => {


        e.preventDefault();

       
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())

          if(localStorage.getItem('token')){
            if(localStorage.getItem('typepush')=='ios'){
                formDataObj.push_ios=localStorage.getItem('token');
            }
            else{
                formDataObj.push_android=localStorage.getItem('token');
            }

        }
        
        dispatch(loginUser(formDataObj));
    } 

   const gotTocreate = (e) => {
         history.replace("/register")
    }

    return (
        <div className="login" >
          
            <Container>

            <div className="row">
                        <div className="col-lg-12">
                           <div className="section-title text-center mb-5">
                            <h2 className="border-left-right-btm">Bienvenue </h2>
                           </div>
                        </div>
            </div>

             <div className="row">
                        <div className='col-lg-3'></div>
                        <div className="col-lg-6">
                            <h2 className="border-left-right-btm center" style={{border:'none', fontSize:'17px'}}>Mixed Mode votre style en un clic !</h2>
                        </div>
                        <div className='col-lg-3'></div>
              </div>


                <Row>
                    <Col lg={{ span: 6 }} className="text-center mx-auto my-5" >
                      
                    

                      <div className="mb-5 divform">

                        <div className='section8890'>
                         <a className={active == 1 ? 'active' : '' } href='javascript:void(0)' onClick={()=>handleTabs(1)}  >Inscription</a>
                         <a className={active == 2 ? 'active' : '' } href='javascript:void(0)' onClick={()=>handleTabs(2)} >Connexion</a>
                      </div>

                         {
                         active ==1 ?

                         <div>

                         <p className='center' style={{color:'#5c5a5a'}}>Je m'inscris en tant que </p>
                          
                          {
                            iflivreur ?
                            <div className='section8891'>
          
                             <a  className={active2 == 3 ? 'active' : '' } href='javascript:void(0)' onClick={()=>handleTabs2(3)} ><img src='/images/icolivreur.png' /> <span>Livreur</span></a>
                          </div>
                          :
                          <div className='section8891'>
                             <a className={active2 == 1 ? 'active' : '' } href='javascript:void(0)' onClick={()=>handleTabs2(1)}  ><img src='/images/achteurico.png' /><span>Acheteur</span></a>
                             <a className={active2 == 2 ? 'active' : '' } href='javascript:void(0)' onClick={()=>handleTabs2(2)} ><img src='/images/vendeurico.png' /> <span>Vendeur</span></a>
                            
                          </div>
                          }
                         


                          {
                             active2 == 1 ? 

                                                           <Form className="mb-5" onSubmit={handleSubmitAchteur}  >
                           
                            <Form.Group className="mb-3" >
                                
                                <Form.Control
                                    name="first_name"
                                    type="text" placeholder="Votre Prénom.." required  />
                            </Form.Group>
                             <Form.Group className="mb-3" >
                                
                                <Form.Control
                                    name="last_name"
                                    type="text" placeholder="Votre Nom.." required />
                            </Form.Group>
                             <Form.Group className="mb-3" >
                              
                                <Form.Control
                                    name="email"
                                    type="email" placeholder="Votre Email" required />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                
                                <Form.Control
                                    name="password"
                                    type="password" placeholder="Mot de passe.." required />
                            </Form.Group>
                             
                            <Button
                                className="ps-btn ps-btn--fullwidth ps-btn-dark" type="submit">
                                S'inscrire
                            </Button>

                        </Form>

                             : active2 == 3 ? 

                                                          <Form className="mb-5" onSubmit={handleSubmitLivreur}  >
                           
                            <Form.Group className="mb-3" >
                                
                                <Form.Control
                                    name="firstname"
                                    type="text" placeholder="Votre Nom et Prénom*.." />
                            </Form.Group>

                               <Form.Group className="mb-3" >
                                
                                <Form.Control
                                    name="firstname"
                                    type="text" placeholder="Ville ou commune*.." />
                            </Form.Group>

                               <Form.Group className="mb-3" >
                                
                                <Form.Control
                                    name="firstname"
                                    type="text" placeholder="Numéro de téléphone*.." />
                            </Form.Group>
                            



                  
                             <Form.Group className="mb-3" >
                              
                                <Form.Control
                                    name="email"
                                    type="email" placeholder="Votre Email*" />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                
                                <Form.Control
                                    name="password"
                                    type="password" placeholder="Mot de passe*.." />
                            </Form.Group>
                             
                            <Button
                                className="ps-btn ps-btn--fullwidth ps-btn-dark" type="submit">
                                S'inscrire
                            </Button>

                        </Form>

                             :

                             <div></div>

                          }


                         </div>

                       

                        :

                        <div>


                       
 

                       <Form className="mb-5" onSubmit={handleSubmit}  >
                           
                            <Form.Group className="mb-3" >
                                <Form.Label>Email*</Form.Label>
                                <Form.Control
                                    name="email"
                                    type="email" placeholder="mail@website.com" />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Mot de passe*</Form.Label>
                                <Form.Control
                                    name="password"
                                    type="password" placeholder="Min. 8 character" />
                            </Form.Group>
                            <Form.Group className="d-flex">
                                <Form.Check type="checkbox" label="Se souvenir de moi" />
                                <Link className="forgetlink" to="/password">Mot de passe oublié ?</Link>
                            </Form.Group> 
                            <Button
                                className="ps-btn ps-btn--fullwidth ps-btn-dark" type="submit">
                                Connexion
                            </Button>


                        </Form>

                        </div>


                      }


                      </div>


                     
                
            
                         
                   
                        
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;