import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateInfoUser,getCommandeById,UpdateUserAddresse,UpdateUserAddresseInf} from "../../../actions/user";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { defaultimg } from "../../../assets/images";
import { Menu } from "../../../components";
import { BoutiqueItem } from "../../../components";
import { getmesfavoris } from "../../../actions/user";

const Page = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { commandeslist } = useSelector(state => state.restaurent)
    const { productsFavoris } = useSelector(state => state.mixedmode)

    useEffect(() => {
    dispatch(getmesfavoris({ user_id: user.user_id, token: user.api_token, api_token: user.api_token ,type:'vendeur'}));

  }, []);

    const history = useHistory();
    function gotoCmd(item) {
       history.push(`/commande/detail/${item.id}`);
     }
    
    return (
        <div className="mesfavoris register " >
            <Container>
                <Row>
            <Col  className="text-center mx-auto my-5"  lg="3">

               <Menu slug='preferees' />
               
                </Col>
              
                    <Col  className="text-center mx-auto my-5"  >

                    <form className="mb-5">
                           
                            <h3 className="mb-4" > Mes Boutiques Préférées </h3>
                             <br/> 
                          <Row className="mb-3"  >

                    
                              {productsFavoris.map((item) => (
                                  <Col md={4} className='col-12' >
                                    
                                    <BoutiqueItem favori={1} item={item} />
                                  </Col>
                                ))}
                           

                          </Row>

                    </form>
                        
                         






   

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;