import React,{ useState,useEffect ,useRef} from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card,Modal,CloseButton } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getStories } from "../../../actions/user";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { apiClient } from "../../../actions/api"
import Switch from '@material-ui/core/Switch';
import moment from 'moment'
import { MenuVendeur } from "../../../components";
import { useParams } from "react-router";



const Page = () => {

    const dispatch = useDispatch();
    const { id } = useParams()
    const { user } = useSelector(state => state.user)
    const label = { inputProps: { 'aria-label': '' } };

     const [show,setShow]=useState(false);
     const handleClose = () => setShow(false);
     const handleShow = () => setShow(true);

    const [photo, setPhoto] = useState([])
    const [titre, setTitre] = useState('')
    const [video, setVideo] = useState([])
    const [videoname, setVideoname] = useState([])
    const [ifloading, setIfloading] = useState(0)

    const fileInput = useRef(null)
    const fileInput2 = useRef(null)

    const [liststories,setListstories]=useState([]);
 
  
    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
 

     const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        let colorslist=[]
            

              

       if(liststories.length <= 0){
          alert('Veuillez ajouter au moins une image ou vidéo !')
          return false
       }

    

        formDataObj.user_id=user.user_id;
        formDataObj.token=user.api_token;
        formDataObj.api_token=user.api_token;
        formDataObj.stories=liststories;
        if(id){

          formDataObj.type='updatestorie';
          formDataObj.id=id;
        }
        else{
            formDataObj.type='addstorie';
        }
       

        //console.log(formDataObj)
        //return false;
  
         apiClient().post(`/mystories`,formDataObj)
              .then((res) => {

     
                      history.push('/vendeur/stories/')

              
                 
        })
    
            
      
    }


       useEffect(() => {
 
       let paramsd={
              id:id ,
              user_id:user.user_id,
              token:user.api_token,
              api_token:user.api_token,
              type:'liststories'
           };
           
           apiClient().post(`/mystories`,paramsd)
           .then((res) => {
         
            setListstories(res.data.stories)
            if(res.data?.stories.length > 0){
                setTitre(res.data.stories[0].titre)
            }
           
           }) 

 
    }, []);


    const handleClickfile = (t) => {

      switch(t){
             case 1:
               fileInput.current.click()
             break;
              case 2:
               fileInput2.current.click()
             break;
         



      }
     
  }


function isImage(filename) {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'jpg':
    case 'gif':
    case 'bmp':
    case 'png':
      //etc
      return true;
  }
  return false;
}

function isVideo(filename) {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'm4v':
    case 'avi':
    case 'mpg':
    case 'mp4':
    case 'mov':
      // etc
      return true;
  }
  return false;
}



function getExtension(filename) {
  var parts = filename.split('.');
  return parts[parts.length - 1];
}

  const removesto = (event,t) => {
     
        let newliststories=[...liststories];
 
         newliststories.splice(t,1);
         setListstories(newliststories)
                      
                 

  }

  const handleFileChange = (event,t) => {
       
      const formData =  new FormData();
      formData.append('image',event.target.files[0]);
 

       if(t==1 ){

            if(!isImage(event.target.files[0].name)){
               alert('Veuillez sélectionner une image valide (png,jpg,jpeg,gif..)')
               return false;
            }

            formData.append('typefile','image');

       }
       else{
            if(!isVideo(event.target.files[0].name)){
               alert('Veuillez sélectionner une vidéo valide (mp4,mov,avi,m4v..)')
               return false;
            }
            formData.append('typefile','video');
       }

      setIfloading(1)


         formData.user_id=user.user_id;
        formData.token=user.api_token;
        formData.api_token=user.api_token;


    
      apiClient().post(`/uploadstorie`,formData)
              .then((res) => {

         if(res.data.status=='successs'){
          setIfloading(0)

           let newliststories=[...liststories];
           let index = liststories.length
           let paramsd={}

        switch(t){
             case 1:
                  
               paramsd={
                    id:index,
                    file:res.data.message,
                    type:'image'
                 };
             break;
       
            case 2:


              paramsd={
                    id:index,
                    file:res.data.message,
                    type:'video'
                 };
             
                
             break;


      }

      
               
       newliststories.push(paramsd) 
       setListstories(newliststories)
      
          

 }
                else{
                alert('Erreur !')
                }
                 
        })
     
  }





    return (
        <div className="register colstories " >

            <Container>
                <Row>
  <Col  className="text-center mx-auto my-5 froweb98"  lg="3">

         <MenuVendeur slug='stories' />

               
                </Col>
                    <Col  className="text-center mx-auto my-5"  >
                        <Form className="mb-5" onSubmit={handleSubmit} >
                            <h3 className="mb-4" >Ajouter une nouvelle storie</h3>


                          <Row className="colstories">
                          <div  className='col-md-3' ></div>
                                <Form.Group  className='col-md-6' >
                                    <Form.Label>Titre *</Form.Label>
                                    <Form.Control type="text" placeholder="" name="titre" required defaultValue={titre} />
                                </Form.Group>
                           <div  className='col-md-3' ></div>
                            
                          </Row>

                          <Row className="">
                           <div  className='col-md-3' ></div>

                          <div className='colstoriesflex col-md-6'>
                            <div className='item' onClick={() => handleClickfile(1)}  style={{cursor:'pointer'}} >
                              <img src='/images/Groupe7130.png' />
                              <label>Ajouter photo</label>
                              <form style={{padding:'0px'}}>
                                 <input
                                 style={{display:'none'}}
                                  type="file"

                                  onChange={(e) => handleFileChange(e,1)}
                                  ref={fileInput} 
                              />
                              </form>

                            </div>

                             <div className='item' onClick={() => handleClickfile(2)}  style={{cursor:'pointer'}} >
                              <img style={{width: '75px'}} src='/images/videoico.png' />
                              <label>Ajouter vidéo</label>
                              <form style={{padding:'0px'}}>
                                 <input
                                 style={{display:'none'}}
                                  type="file"

                                  onChange={(e) => handleFileChange(e,2)}
                                  ref={fileInput2} 
                              />
                              </form>
                            </div>

                          </div>

                           <div  className='col-md-3' ></div>
                       

                          </Row>


                        <div className="storiescols">

                        
                  
                              <Row >
                           <div  className='col-md-3' ></div>

                          <div className=' col-md-6'>
                                {liststories?.map((item,key) => (


                            <div className='item'>
                              
                              {
                                item?.type=='image' ?  <img src={item?.file} /> : <img src='/images/play.png' />
                              }
                              
                              <label style={{cursor:'pointer'}} onClick={(e)=>removesto(e,key)}><i className='fa fa-remove'></i></label>
                              
                            </div>
                                ))}

                         
                          </div>

                           <div  className='col-md-3' ></div>
                       

                          </Row>


                      

                          

                        </div>

                     

                       <br/>
              
                            <Button className="ps-btn ps-btn--fullwidth ps-btn-dark"  type="submit">
                                Publier
                            </Button>
                        </Form>

                         <div className='badge alert-warning'>
                           Le format idéal pour vos stories est : 1080px X 1920px
                         </div>
                    </Col>
                </Row>

   <div className='mask' style={{display:ifloading== 1 ? 'flex' : 'none'}}>
          <img src="/images/157446935584697.gif"  />
      </div>
            </Container>
        </div>
    );
}

export default Page;