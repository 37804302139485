import React,{useEffect,useState} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal,CloseButton } from "react-bootstrap"
import { ProductItem } from "../../components";
import { useHistory } from "react-router";
import Carousel from 'react-bootstrap/Carousel'
import Slider from "react-slick";
import Stories from 'react-insta-stories';
import { getBest , getPage, getSlides,getStories} from "../../actions/mixedmode";
import { BASE_URL_PROD } from "../../components/config/keys";
import { defaultimg } from "../../assets/images";


const Page = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const queryParams = new URLSearchParams(window.location.search);

    const typepush = queryParams.get('typepush');
    const token = queryParams.get('token');


     const [show,setShow]=useState(false);
     const [stories,setStories]=useState([]);
     const { data,pageinfo,slides,storieslist } = useSelector(state => state.mixedmode);
    var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide:0,
     responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
             infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
             infinite: true,
            dots: false
          }
        }
      ]
   };


  var settingsSlide = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide:0,
     responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
             infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
             infinite: true,
            dots: false
          }
        }
      ]
   };
   
 

    useEffect(()=>{
       dispatch(getBest())
       dispatch(getSlides())
       dispatch(getStories(1))
       localStorage.setItem('lastpagedetail', '');

       if(token){

          localStorage.setItem('token', token);
          localStorage.setItem('typepush', typepush);
       }


    },[])

     const handleClick = (slug) => {
        history.push(slug);
    }

     const handleClose = () => setShow(false);
     const handleShow = (item) => {
      
      setStories(item?.stories)
      setShow(true);

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        console.log("formDataObj", formDataObj);
        if(formDataObj.keyword){
         history.push(`/search/${formDataObj.keyword}`)   
        }else{
            alert("merci de remplir le champ")
        }
        
    }

    return (
        <div className="home" >
            <section className="" >
                 
                 <Slider {...settingsSlide}>
                           {slides.map((item) => (
                            <div className="ps-banner--market-4"   >
                                  <div className="ps-banner_img" style={{backgroundImage:`url(${BASE_URL_PROD}/gestion/public/slides/${item.img})`}} >
                                     <div className='mixedtitle'><div dangerouslySetInnerHTML={{ __html:item?.text }}></div></div>
                                     


                                  </div>
                               </div>
                             ))}
                           

                  </Slider>
               
               
            </section>
            <section className="section2 chosecateg" >
                <Container>
                    <h3 className="text-center mb-5"> Choisissez votre catégorie   <span></span> </h3>
                   

                  <div className='categforweb'>

                     <Row className="g-5 "  >

                        <Col md={1}></Col>

                          <Col md={10}>
                          <Row  >

                          <Col md={2}></Col>

                          <Col md={4} onClick={()=>handleClick('/fashion-style/produits/Homme')}   >
                                 <div className='cardplatform '>
                                  <div className='fashion'>
                                
                                   <img src="/images/fashionicon.png" alt=''   />
                                   <div>
                                   <h2 className=''>FASHION</h2>
                                   <p>Trouvez votre style chic et tendance</p>
                                   </div>
                                   </div>

                                 </div>
                            </Col>
                    
                            <Col md={4} onClick={()=>handleClick('afro-creation/produits/Homme')}    >
                                 <div className='cardplatform '>
                                  <div className='africreation'>
                                   <img src="/images/afrocreationicon.png" alt=''   />
                                   <h2 className=''>Afro creation</h2>
                                   <p>L'espace des meilleurs créateurs africains</p>
                                   </div>

                                 </div>
                            </Col>
                            <Col md={2}></Col>
                            {

                            /*

                             <Col md={4}  onClick={()=>handleClick('ventout')}   >
                                 <div className='cardplatform '>
                                  <div className='vendtout'>
                                   <img src="/images/ventouticon.png" alt=''   />
                                   <h2 className=''>Ventout</h2>
                                   <p>Tu ne le portes ? vends-le !</p>
                                   </div>

                                 </div>
                            </Col>

                            */
                            }

                          

                          

                            </Row>



                          </Col>

                        <Col md={1}></Col>


                       
            

     
                      
                    </Row>

                    {
                      /*

                    <Row>

                     <Col md={1}></Col>

                      <Col md={10}>


                      <div onClick={()=>handleClick('/sur-commande/produits/1')} className='cardplatform cardplatform1' style={{backgroundImage:"url('/images/coellectionsurcommande.png')"}}>
                                  <div className='surcommande'>

                                  <div className='surcommandetitle'>
                                     <p>Collections sur commande </p>
                                  </div>

 
                                   </div>

                                 </div>


                      </Col>

                       <Col md={1}></Col>


                    </Row>


                      */


                    }

             
                  </div>

                  <div className='categformobile clearboth'>

                    <div className=" bigcategories">
                       
                

                   
                     

                        <div className='item '>
                        <a href='javascript:void(0)' onClick={()=>handleClick('/fashion-style/produits/Homme')} className='fashion ' ><img src='/images/fashionicon.png' /></a>
                         <label>Fashion</label>
                       </div>

                       <div className='item '>
                        <a href='javascript:void(0)'  onClick={()=>handleClick('/afro-creation/produits/Homme')} className='afrocreation ' ><img src='/images/afrocreationicon.png' /></a>
                         <label>Afro Creation</label>
                       </div>

                       {
                        /*

                      <div className='item '>
                        <a href='javascript:void(0)' className='ventout' onClick={()=>handleClick('/ventout')}  ><img src='/images/ventouticon.png' /></a>
                         <label>Ventout</label>
                       </div>

                        */
                       }


                     
                   
                         
                   </div>



                     {
                      /*

                          <Row className="g-5 "  >

                              <Col md={3}></Col>
                       
                                <Col md={6} onClick={()=>handleClick('/sur-commande/produits/1#')}  style={{marginTop: '0px',padding:'0px'}}   >
                                 <div className='cardplatform ' style={{backgroundImage:"url('/images/coellectionsurcommande.png')"}}>
                                  <div className='surcommande'>

                                  <div className='surcommandetitle'>
                                     <p>Collections sur commande </p>
                                  </div>

 
                                   </div>

                                 </div>
                            </Col>
                               <Col md={3}></Col>
                           </Row>


                      */
                     }

                  </div>

                    

                                       

 
                </Container>
            </section>

            {
              storieslist?.length > 0 && 

                     <section className="section4 " >
                <Container>
                    <h3 className="text-center mb-5"> Stories des boutiques <span></span> </h3>
                   <br/>

                   <Row>

                      <Col md={3}></Col>

                         <Col md={6}>
                           <div className='storiesflex'>

                               {storieslist?.map((item) => (

                                       <div className='item stories'>
                                         <div onClick={()=>handleShow(item)}  className="w-32 h-32 rounded-full conic-gradient relative" style={{backgroundImage:item.picture== null ? `url(${defaultimg})` : `url("${BASE_URL_PROD}/gestion/public/uploads/${item.picture}")`}}></div>
                                         <div className='center' style={{    paddingTop: '10px'}}>{item.nomboutique}</div>
                                        
                                      </div> 
                                ))}

 


                     
                     
                    
                   
                      <div className='item stories' style={{marginRight:'0px'}}>
                         <div  className="center seemore"  onClick={()=>history.push('/stories')} >

                             <img src='/images/pngegg.png'/>
                             <div >Voir plus</div>
                            
                         </div>
                      </div> 

                    </div>
                         </Col>

                       <Col md={3}></Col>


                   </Row>

                

                </Container>

              </section>
            }


 {

  /*



            <section className='section3' style={{display:'none'}}>
              <Container>
                 <h3 className="text-center mb-5">   <span>Pourquoi choisir</span> MIXED MODE ?</h3>
                   

                 <div className="ps-block--site-features ps-block--site-features-2">
                    <div className="ps-block__item">
                        <div className="ps-block__left text-center"><img src='/images/7345.png' /></div>
                        <div className="ps-block__right">
                            <h4 className="text-center">Petit frais de service</h4>
                            <p className="text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        </div>
                    </div>
                    <div className="ps-block__item">
                        <div className="ps-block__left text-center"><img src="/images/5287.png" /></div>
                        <div className="ps-block__right text-center">
                            <h4 className="text-center">Un panier unique pour tous vos achats</h4>
                            <p className="text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        </div>
                    </div>
                    <div className="ps-block__item">
                        <div className="ps-block__left text-center"><img src="/images/6673.png" /></div>
                        <div className="ps-block__right">
                            <h4 className="text-center">économisez sur l'envoi </h4>
                            <p className="text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        </div>
                    </div>
                    
                </div>
              </Container>
            </section>
  */
 }
       



{
  storieslist?.length > 0 && 



            <section className='section4' style={{background:'#FFF'}}>
              <Container>
                 <h3 className="text-center mb-5">Top 20 des meilleures ventes</h3>
                  

                   <Row className="g-5" >

                    
                       <Col md={12} >
                       <Slider {...settings}>
                           {data.map((item) => (
                             <ProductItem item={item}  /> 
                             ))}
                           

                        </Slider>
                     
                                 
                       </Col>
                      
                   </Row>

                  

                 
              </Container>
            </section>
}



      <Modal show={show} onHide={handleClose} className='modelhermix909'>
        <Modal.Header   >
          <CloseButton variant="white" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <div className='contentstoris'>
            <Stories stories={stories}  loop={true}/>
         </div>
        </Modal.Body>
        <Modal.Footer>
        
        
        </Modal.Footer>
      </Modal>
        </div>
    );
}

export default Page;