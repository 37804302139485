import React,{useState,useEffect} from "react";
import './styles.scss';
import { Container, Navbar, Nav,CloseButton } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { logo,addtocart } from "../../assets/images";
import { LOGOUT_USER } from "../../constants/actions";
import { BiUser,BiLogOut} from 'react-icons/bi';
import { useHistory } from "react-router";
import Accordion from 'react-bootstrap/Accordion'
import { getSettings,getCategoryListAll,getNotification} from "../../actions/mixedmode";
import { REMOVE_PRODUCT_BASKET, GET_TOTAL_CART } from "../../constants/actions";

const Component = ({back}) => {
    const dispatch = useDispatch();
    const history = useHistory()
 
     const { user } = useSelector(state => state.user)
     const { data,total } = useSelector(state => state.basket)
     const { settings,allcategories ,notification} = useSelector(state => state.mixedmode)
   
    const [showmenu,setShowMenu]=useState(false);
    const [expand,setExpand]=useState(0);

     const [scrool,setScrool]=useState(false);

  useEffect(() => {
         dispatch(getSettings())
         
         dispatch(getCategoryListAll('all'))
         const param = {}
         if(user.user_id){

            param.user_id=user.user_id;
         param.token=user.api_token;
         param.api_token=user.api_token;
          dispatch(getNotification(param))

         }

         if(!scrool){
           window.scrollTo(0,0);
           setScrool(true)
         }
        

       

    }, []);

    const handleDelete = (item) => {
        dispatch({ type: REMOVE_PRODUCT_BASKET, payload: item.id })  
        dispatch({ type: GET_TOTAL_CART })  
    }
 
    const handleLogout = () =>{
        dispatch({type:LOGOUT_USER});
    }

     const goToCart = () =>{
         history.push(`/panier`);
    }

  const goToPage = (src) =>{
         history.push(src);
    }

    const goToLoginOrAcount = () =>{
         history.push(`/login`);
    }

        const handlerMenu = () => {
        
          setShowMenu(!showmenu)

    }
   const goTowhtasapp = () =>{
           let url =`https://api.whatsapp.com/send/?phone=${settings.site_fax}&type=phone_number&text=Bonjour,`
        window.open(url);
    }

     const handleClose = () => setShowMenu(false);

    return (
        <div>
        <header className="header header--standard header--market-place-2" data-sticky="true">
        
        <div className="header__content">
            <div className="container">
               <div className='row'>

          

                     <div className="col-md-8 ">
                          <a className="ps-logo" href="#" onClick={()=>history.push('/')}><img src="/images/logo.png" alt=""/></a>
                     </div>

                      <div className="col-md-4">

                <div className="header__content-right">
                    <div className="header__actions">
                    <a className="header__extra" href="javascript:void(0)" onClick={goToLoginOrAcount}  ><i className="fa fa-globe "></i><span style={{background: '#f47777'}}><i>{notification}</i></span></a>
                        <div className="ps-cart--mini"><a className="header__extra" href="javascript:void(0)"><i className="icon-bag2"></i><span><i>{data?.length}</i></span></a>
                            <div className="ps-cart__content">
                                <div className="ps-cart__items">

                                  {data.map((item) => (
                                    <div className="ps-product--cart-mobile">
                                        <div className="ps-product__thumbnail"><a href="javascript:void(0)"><img src={item.product.img} alt=""/></a></div>
                                        <div className="ps-product__content"><a className="ps-product__remove" href="javascript:void(0)" onClick={()=>handleDelete(item)}><i className="icon-cross"></i></a><a href="product-default.html">{item.product.title}</a>
                                            <p></p>
                                            <small>{item.qty} x {item.product.price} CFA</small>
                                        </div>
                                    </div>
                                     ))}


                                  
                                </div>
                                <div className="ps-cart__footer">
                                    <h3>Sous Total:<strong>{total} CFA</strong></h3>
                                    <figure><a onClick={goToCart} className="ps-btn ps-fullwidth ps-btn--black" href="javascript:void(0)">Voir panier</a></figure>
                                </div>
                            </div>
                        </div>
                        <div className="ps-block--user-header">
                            <div className="ps-block__left" onClick={goToLoginOrAcount}><i className="icon-user"></i></div>
                            
                        </div>

 <div className="ps-block--user-header" onClick={()=>goTowhtasapp()}>
                            <div className="ps-block__left" ><img  style={{width: '33px'}} src="/images/f304f8984a6f9cc880a5e0e913b609b2.png" /></div>
                            
                        </div>



                    </div>
                </div>
                    
                     </div>

               </div>
               
          
 
            </div>
        </div>
        <nav className="navigation">
            <Container fluid="md">
                <ul className="menu menu--market-2">
                    <li><a href="javascript:void(0)" onClick={()=>goToPage('/#')}>Accueil</a>
                    </li>

              
                    <li><a href="javascript:void(0)" onClick={()=>goToPage('/fashion-style/produits/Homme')}>FASHION</a>
                    </li>
                      <li><a href="javascript:void(0)" onClick={()=>goToPage('/afro-creation/produits/Homme')}>AFRO-CREATION</a>
                    </li>
                  
                    {
                       
                      /*
                       <li><a href="javascript:void(0)" onClick={()=>goToPage('/ventout#')}>VENTOUT</a>
                    </li>
                        <li><a href="javascript:void(0)" onClick={()=>goToPage('/sur-commande/produits/1')}>SUR COMMANDE</a>
   </li>
                      */

                    }
                    
                 
                     <li><a href="javascript:void(0)" onClick={()=>goToPage('/en-promo/produits/2')}>EN PROMO</a>
                    </li>
                   
                    <li><a href="javascript:void(0)" onClick={()=>goToPage('/devenir-vendeur#')}>DEVENIR VENDEUR</a>
                    </li>
                   
                    <li><a href="javascript:void(0)" onClick={()=>goToPage('/contact#')}>CONTACTS</a>
                    </li>
                     <li><a href="javascript:void(0)" onClick={()=>goToPage('/apropos#')}>Qui sommes nous</a>
                    </li>
                </ul>
            </Container>
        </nav>
    </header>
    <header className="header header--mobile" data-sticky="true">
        <div className="header__top" style={{display:'none'}}>
            <div className="header__left">
                 
            </div>
            <div className="header__right">
               
            </div>
        </div>
        <div className="navigation--mobile">
           
            <div className="navigation__center">

            {
                 back==1 ? 
                    <a className="ps-goback" href="javascript:void(0)" onClick={() => history.goBack()}><img src="/images/goback.png" alt=""/> Retour</a>
                 :
                  <a className="ps-logo" href="javascript:void(0)" onClick={()=>history.push('/')}><img src="/images/logo.png" alt=""/></a>


            }

           


            </div>


            <div className="navigation__right">
                <div className="header__actions">
                    

                    <a className="header__extra icomobileheader" href={"tel:"+settings?.site_phone?.split('<br/>')?.[0]}><img src="/images/phoneico.png" /></a>
                    <a className="header__extra icomobileheader" href="javascript:void(0)" onClick={()=>goTowhtasapp()}><img src="/images/watsapico.png" /></a>
                     <a className="header__extra " href="javascript:void(0)" onClick={goToLoginOrAcount} style={{marginLeft: '10px'}}  ><i style={{lineHeight: '27px'}} className="fa fa-globe "></i><span style={{background: '#f47777',top:'-3px'}}><i>{notification}</i></span></a>
                    <a onClick={handlerMenu} className="navigation__item ps-toggle--sidebar" href="javascript:void(0)"><i className="icon-menu"></i></a>
                       
                 
                 
                </div>
            </div>

            
        </div>
        <div className="ps-search--mobile" style={{display:'none'}}>
            <form className="ps-form--search-mobile" action="">
                <div className="form-group--nest">
                    <input className="form-control" type="text" placeholder="Rechercher un produit..."/>
                    <button><i className="icon-magnifier"></i></button>
                </div>
            </form>
        </div>
    </header>
    {
        showmenu==true && 
          <div className='mask'></div>

    }
      
        <div className={showmenu==true ? "ps-panel--sidebar active" : "ps-panel--sidebar" } id="menu-mobile">
        <div className="ps-panel__header">
            <CloseButton variant="white" onClick={handlerMenu} />
            <h3>Menu</h3>

        </div>
        <div className="ps-panel__content">

        <ul className="menu--mobile menu--mobile-border">
             <li className="menu-item accordion"><div  onClick={()=>setExpand(1) } href="javascript:void(0)" style={{textTransform:'uppercase'}} >Fashion</div>
               <ul style={{display:expand==1 ? 'block' : 'none'}}>
               {allcategories?.map((item) => (
                  item?.rubrique==1 &&
                           <li onClick={()=>goToPage('/fashion-style/produits/'+item?.category_id)}>{ item?.category_name}</li>
                  ))}
                
             
                

               </ul>

             </li>
             <li className="menu-item accordion"><div onClick={()=>setExpand(2) }  href="javascript:void(0)" style={{textTransform:'uppercase'}}>Afro Création</div>
               <ul style={{display:expand==2 ? 'block' : 'none'}}>
                
                 {allcategories?.map((item) => (
                  item?.rubrique==2 &&
                           <li onClick={()=>goToPage('/afro-creation/produits/'+item?.category_id)}>{ item?.category_name}</li>
                  ))}

               </ul>
             </li>
     
             {
              /*
              <li className="menu-item accordion"><div href="javascript:void(0)" onClick={()=>setExpand(3) } style={{textTransform:'uppercase'}} >Ventout </div>
                <ul style={{display:expand==3 ? 'block' : 'none'}}>
                  {allcategories?.map((item) => (
                  item?.rubrique==3 &&
                           <li onClick={()=>goToPage('/ventout/produits/'+item?.category_id)}>{ item?.category_name}</li>
                  ))}

               </ul>
             </li>
              <li className="menu-item accordion"><a onClick={()=>goToPage('/sur-commande/produits/1#')} href="javascript:void(0)" style={{textTransform:'uppercase'}}>Sur commande </a></li>
              */
             }
            
         
        </ul>
         <ul className="menu--mobile menu--mobile-border">
   
            
             <li className="menu-item"><a onClick={()=>goToPage('/apropos#')} href="javascript:void(0)">Qui sommes nous</a></li>
             <li className="menu-item"><a onClick={()=>goToPage('/contact#')} href="javascript:void(0)">Nous contacter</a></li>
         
             <li className="menu-item"><a onClick={()=>goToPage('/rgbd#')} href="javascript:void(0)">RGPD</a></li>
             <li className="menu-item"><a onClick={()=>goToPage('/cgv#')} href="javascript:void(0)">CGV</a></li>
             <li className="menu-item"><a onClick={()=>goToPage('/cgu#')} href="javascript:void(0)">CGU</a></li>
             <li className="menu-item"><a onClick={()=>goToPage('/retour#')} href="javascript:void(0)">Conditions de retour</a></li>
             <li className="menu-item"><a onClick={()=>goToPage('/livraison#')} href="javascript:void(0)">Conditions de livraison</a></li>
             <li className="menu-item"><a onClick={()=>goToPage('/aide#')} href="javascript:void(0)">FAQ</a></li>
             <li className="menu-item"><a onClick={()=>goToPage('/login?livreur=1')} href="javascript:void(0)">Livreur</a></li>


 
            </ul>
        </div>


        {
          !user?.google_id ? 
          <div className='panelfooter'>
              <img src='/images/devenirmixedmode.png' />
              <p>Vendez  gratuitement sans abonnement !</p>
              <a href='javascript:void(0)' onClick={()=>goToPage('/devenir-vendeur#')}>Devenez vendeur sur Mixed Mode</a>
          </div>

          :
          user?.google_id=='V' &&
            
          <div className='panelfooter'>
              <img src='/images/devenirmixedmode.png' />
                <a href='javascript:void(0)' onClick={()=>goToPage('/login')}>Dashboard</a>
             
          </div>

        }
        
    </div>

    </div>
    );
}

export default Component;