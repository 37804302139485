import { TOGGLE_LOADING,IS_SUBMITED } from "../constants/actions"

const defaultState = {
    loading:false,
    issubmited:false
}

export default (state = defaultState, action) => {
    const { type, payload = {} } = action

    switch (type) {
        case TOGGLE_LOADING:
            return {...state, loading:payload}
         case IS_SUBMITED:
            return {...state, issubmited:payload}    
        default:
            return state
    }
}
