import React, { useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col } from "react-bootstrap";
import { addContact, getSettings} from "../../actions/mixedmode";
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router";

const Page = () => {


    const dispatch = useDispatch();
    const history = useHistory()
    const { settings } = useSelector(state => state.mixedmode)
    const [tel1,setTel1]=useState('');
    const [tel2,setTel2]=useState('');


   const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        
        if(formDataObj.name=="" || formDataObj.email=="" || formDataObj.sujet==""  || formDataObj.message=="" ){
           alert("Merci de remplir tous les champs.");
        }else{
            dispatch(addContact(formDataObj));
        }
    
    }

    useEffect(() => {
     dispatch(getSettings())
     if(settings){
        let tel1=settings?.site_phone?.split('<br/>')?.[0];
        let tel2=settings?.site_phone?.split('<br/>')?.[1];
        setTel2(tel2)
        setTel1(tel1)
     }
   }, [])


    return (
    <div>
        <div className="ps-breadcrumb"><div className="container"><div className="ps-container"><ul className="breadcrumb"><li><a href="#">Accueil</a></li><li>Nous contacter </li></ul></div></div></div>
        <div className="quisommesnous" >

            <Container>
                <Row>
                    <Col className="mx-auto my-5" lg="12">

                       <h1 className="text-center mb-5">Nous contacter </h1>
                       <p className='center'>Mixed Mode votre style en un clic !</p>
                        <span className="section-separator"></span>
<br/><br/>

       

 
                    </Col>
                </Row>




            </Container>
        </div>


         <Container>
              

               <div className="ps-section__content">

                                      <h1 className="text-center mb-5">Service client </h1>
                     
                        <span className="section-separator"></span>
<br/><br/>

                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 ">
                            <div className="ps-block--contact-info">
                               <div style={{fontSize:'30px'}}><i className="fa fa-volume-control-phone" aria-hidden="true"></i></div>
                                <h4>Téléphone</h4>
                                <p><a style={{color:'#666'}} href={"tel:"+tel1}> {tel1} </a></p>
                                <p><a style={{color:'#666'}} href={"tel:"+tel2}> {tel2} </a></p>
                            </div>
                        </div>

                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 ">
                            <div className="ps-block--contact-info">
                                <div style={{fontSize:'30px'}}><i class="fa fa-whatsapp" aria-hidden="true"></i></div>
                                <h4>WhatsApp</h4>
                                <p><p><a style={{color:'#666'}} href={"tel:"+settings?.site_fax}> {settings?.site_fax} </a></p></p>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 ">
                            <div className="ps-block--contact-info">
                             <div style={{fontSize:'30px'}}><i class="fa fa-map-marker" aria-hidden="true"></i></div>
                                <h4>Adresse</h4>
                                <p><span dangerouslySetInnerHTML={{ __html: settings?.site_address }}></span></p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 ">
                            <div className="ps-block--contact-info">
                             <div style={{fontSize:'30px'}}><i class="fa fa-envelope-o" aria-hidden="true"></i></div>
                                <h4>Email</h4>
                                <p><a style={{color:'#666'}} href={"mailto:"+settings?.site_email}> {settings?.site_email} </a></p>
                            </div>
                        </div>
                        
                        
                        
                    </div>
                </div>




            </Container>

            <br/><br/>
                 <div className="ps-contact-form" style={{paddingTop:'50px',paddingBottom:'50px'}}>
            <div className="container">
                <form className="ps-form--contact-us" onSubmit={handleSubmit}  >
                  
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                            <div className="form-group">
                                <input className="form-control" type="text" name="name" placeholder="Nom complet *" />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                            <div className="form-group">
                                <input className="form-control" type="text" name="email" placeholder="Email *"/>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                            <div className="form-group">
                                <input className="form-control" type="text" name="sujet" placeholder="Sujet *"/>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                            <div className="form-group">
                                <textarea className="form-control" name="message" rows="5" placeholder="Message"></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="form-group submit">
                        <button className="ps-btn"  type="submit" >Envoyer</button>
                    </div>
                </form>
            </div>
        </div>
 
             
    </div>
    );
}

export default Page;