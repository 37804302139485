import { SET_SLIDE,SET_NOTIFICATION,SET_LIST_STORIES,SET_LIST_OFFRES,SET_COLOR,SET_TAIL,SET_BOUTIQUES,SET_INFO_BOUTIQUES,SET_SOUS_CATEGORY_LIST,SET_COUNT,SET_SELECT_PAGE,SET_AVG,SET_CURRENT_PAGE,SET_FAQ,SET_CURRENT_SETTINGS,SET_COMMENTS,SET_CURRENTPAGE,PRODUCT_LIKED,PRODUCT_FAVORIS,SET_LIST_RECLAMATIONS,SET_CATEGORY_LIST,SET_CATEGORY_LIST_ALL,SET_CURRENT_BOUTIQUE,SET_BEST,SET_LIST_AMAZON,SET_CURRENT_PRODUCT,SET_LIST_COMMANDES,SET_CURRENT_COMMANDE,SET_HORAIRE_RESTAURENT } from "../constants/actions";

const INITIAL_STATE = {
    data: [],
    slides:[],
    pageCount: 1,
    pageSelectPage: 1,
    dataamazon: [],
    addresseslist: [],
    commandeslist: [],
    allcategories: [],
    reclamations: [],
    infoboutique: {},
    categorieslist: [],
    souscategorieslist: [],
    colors: [],
    tails: [],
    boutiques:[],
    horaires: [],
    comments: [],
    avgnote:0,
    product: {},
    settings:{},
    productsFavoris:[],
    faqs:[],
    likedpro:false,
    commandeinfo: {},
    pageinfo: {},
    currentBoutique:{
        establishment:{},
        categories:[]
    },
    storieslist:[],
    products:[],
    offres:{},
    notification:0
};

function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_SLIDE :
            return {...state,slides:action.payload}
        case SET_BEST :
            return {...state,data:action.payload}
        case SET_AVG :
            return {...state,avgnote:action.payload}
        case SET_LIST_AMAZON :
            return {...state,dataamazon:action.payload}
        case SET_CURRENT_BOUTIQUE :
            return {...state,currentBoutique:action.payload}
        case PRODUCT_LIKED :
                return {...state,likedpro:action.payload}
        case PRODUCT_FAVORIS :
                    return {...state,productsFavoris:action.payload}
        case SET_COMMENTS :
                    return {...state,comments:action.payload}
      // case SET_LIST_ADDRESS :
      //     return {...state,addresseslist:action.payload}
    case SET_LIST_COMMANDES :
          return {...state,commandeslist:action.payload}
     case SET_LIST_OFFRES :
          return {...state,offres:action.payload}     
        case SET_LIST_RECLAMATIONS :
          return {...state,reclamations:action.payload}
    case SET_CATEGORY_LIST :
          return {...state,categorieslist:action.payload}
    case SET_CATEGORY_LIST_ALL :
          return {...state,allcategories:action.payload}      
    case SET_SOUS_CATEGORY_LIST :
          return {...state,souscategorieslist:action.payload}      
    case SET_COLOR :
          return {...state,colors:action.payload}
    case SET_TAIL :
          return {...state,tails:action.payload}

     case SET_BOUTIQUES :
          return {...state,boutiques:action.payload} 
     case SET_INFO_BOUTIQUES :
          return {...state,infoboutique:action.payload} 
      
 case SET_NOTIFICATION :
          return {...state,notification:action.payload} 

   case SET_CURRENT_COMMANDE :
          return {...state,commandeinfo:action.payload}
   case SET_CURRENT_PAGE :
          return {...state,pageinfo:action.payload}
    case SET_CURRENT_SETTINGS :
          return {...state,settings:action.payload}  
    case SET_FAQ :
          return {...state,faqs:action.payload}            
  case SET_CURRENTPAGE :
          return {...state,currentPage:action.payload}
  case SET_COUNT :
          return {...state,pageCount:action.payload}
  case SET_SELECT_PAGE:
          return {...state,pageSelectPage:action.payload}        
  case SET_CURRENT_PRODUCT :
          return {...state,product:action.payload}
  case SET_LIST_STORIES :
          return {...state,storieslist:action.payload}       
   case SET_HORAIRE_RESTAURENT :
            return {...state,horaires:action.payload}
        default:
            return state;
    }
}
export default reducer;