import React, { useEffect,useState } from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button,Modal,CloseButton } from "react-bootstrap"
import { ProductItem, RestaurentItem } from "../../components";
import { getAllRestaurents, getRestaurentById } from "../../actions/restaurent";
import { useParams } from "react-router";
import { useHistory } from "react-router";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Carousel from 'react-bootstrap/Carousel'
import Slider from "react-slick";


const Page = () => {
    const { id } = useParams()
    const dispatch = useDispatch();
     const history = useHistory()
    const { currentRestau, products } = useSelector(state => state.restaurent)

      const [value, setValue] = useState(0);
      const [show,setShow]=useState(false);
      const [showtype,setShowType]=useState(0);

      const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    const handleAddCart = (slug) => {
        history.push(`/panier`);
    }

     const handleGoTo= (slug) => {
        history.push(slug);
    }

     const handleClose = () => setShow(false);
     const handleShowAch = () => {
      setShowType(0)
      setShow(true);
    }
     const handleShowNeg = () => {
      setShowType(1)
      setShow(true);
    }

    useEffect(() => {
        
    }, [])


        var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide:0,
     responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
             infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
             infinite: true,
            dots: false
          }
        }
      ]
   };

    return (

        <div className='detailproduct ventout'>
           
            
            <div className="ps-breadcrumb">
             <Container>
                <div className="ps-container">
                    <ul className="breadcrumb">

                        <li><a href="#">Accueil</a></li>
                        <li><a href="#">ventout</a></li>
                        <li>Oversize Cotton Dress </li>
                    </ul>
               </div>
                </Container>
             </div>
           

                <Container>
                <Row className="g-5" >
                   
                    <Col md={12}  >

                        <div className="ps-product--detail ps-product--fullwidth">
                        <div className="ps-product__header">
                         <div className="ps-product__thumbnail" data-vertical="true">
                                <figure>
                                    <div className="ps-wrapper">
                                        <div className="ps-product__gallery slick-initialized slick-slider" ><a href="#" className="slick-arrow slick-disabled" aria-disabled="true" ><i className="fa fa-angle-left"></i></a>
                                            <div  className="slick-list draggable"><div className="slick-track"  style={{opacity: '1', width: '1260px'}}><div className="item slick-slide slick-current slick-active"  style={{width: "420px", position: "relative", left: "0px",top: "0px", zIndex: '999', opacity: '1'}}>
                                            <a href="" >
                                             <img src=" /images/big-product4.jpg" alt=""/></a>
                                             </div>
                                             </div>
                                        </div>
                                            
                                            
                                        <a href="#" className="slick-arrow"  aria-disabled="false"><i className="fa fa-angle-right"></i></a></div>
                                    </div>
                                </figure>
                                <div style={{display:'none'}} className="ps-product__variants slick-initialized slick-slider slick-vertical" data-item="4" data-md="4" data-sm="4" data-arrow="false">
                                    <div aria-live="polite" className="slick-list draggable" style={{height: '280px'}}><div className="slick-track" role="listbox" style={{opacity: '1', height: '210px', transform: 'translate3d(0px, 0px, 0px)'}}><div className="item slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" tabindex="-1" role="option" aria-describedby="slick-slide10" style={{width: '60px'}}><img src="/images/template/1(3).jpg" alt=""/></div></div></div>
                                    
                                    
                                </div>
                            </div>
                            <div className="ps-product__info">
                                <h1 className='dettitleprweb'>Oversize Cotton Dress</h1>
                            

                                <div className='slidemobile'>
                                     <div className='video'>
                                      
                                       <img src=" /images/big-product4.jpg" alt=""/>
                                     </div>

                                    
                                    
                                </div>
                                <h4 className="ps-product__price">36.78 CFA</h4>
                                <div className='center'> <h1 className='dettitleprmobile'>Oversize Cotton Dress</h1></div>
                                
                                 <div className='addrs11'>
                                  <img src='/images/marker2.png' />
                                  Abidjan cocody mahan
                                </div>

                                <div className='variations listing  ' style={{marginTop:'30px'}}>
                                
                                <Row className="g-5" >

                                  <Col md={6} className='col-6' >
                                     <strong>Catégorie:</strong>
                                     <p>Chaussures femme </p>

                                     <strong>Etat:</strong>
                                     <p>Neuf </p>
                                  </Col>
                                  <Col md={6} className='col-6' >
                                    <strong>Couleur:</strong>
                                     <p>Noir </p>

                                     <strong>Taille:</strong>
                                     <p>40 </p>
                                  </Col>

                                </Row>
                                </div>


                             <div className='variations listing  ' style={{marginTop:'10px'}}>
                                
                                <Row className="g-5" >

                                  <Col md={12} className='col-12' >
                                     <strong>Authencité:</strong>
                                     <p className='Nonauthentique'>Non-authentique</p>
                                  </Col>
                                   

                                </Row>
                                </div>

                                <div className='variations listing  ' style={{marginTop:'10px'}}>
                                
                                <Row className="g-5" >

                                  <Col md={12} className='col-12' >
                                     <strong>A propos du vendeur:</strong>
                                     <p>Embodying the Raw, Wayward Spirit of Rock 'N' Roll</p>
                                  </Col>
                                   

                                </Row>
                                </div>


                                
                                <div className="">

                                 <div className='flx124'>
                  
                   <a className="ps-btn ps-btn--black f1" href="javascript:void(0)" onClick={()=>handleShowAch()} >Acheter</a>
                   <a className="ps-btn ps-btn--black f2" href="javascript:void(0)" onClick={()=>handleShowNeg()}>Négocier le prix</a>

                </div>
                               
                                    
                

                                
                                
                                </div>
                             
                                 
                            </div>
                        </div>

 


 
                    </div>
                       
                    </Col>
                </Row>
            </Container>

       <Modal show={show} onHide={handleClose} className='modalpublish'>
        <Modal.Header   >
           <span className='mop1'>
            {
              showtype==0 ? 'Acheter le produit' : 'Négocier le prix'
            }
           </span>
          <CloseButton variant="black" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>

        {
          showtype==1 &&

           <div className='marg5'>
           <h5 className='mop1'>Votre offre</h5>
           <p style={{fontSize:'13px',    lineHeight: '16px'}} className='mop2'>Vous pouvez négocier le prix en proposant un prix  plus bas que celui demandé par le vendeur. Attention, si le vendeur accepte votre offre, vous ne pourrez plus annuler.</p>
         </div>

        }
         

  

  
      
  

     

  

   

  

          <div className='marg6'>
           <label><strong>identité</strong></label>
           <input type="text" className="form-control" placeholder='' />
         </div>

         <div className='marg6'>
           <label><strong>Adresse de livraison</strong></label>
           <input type="text" className="form-control" placeholder='Rentrez ici l'adresse />
         </div>

          <div className='marg6'>
           <label><strong>Informations complémentaires</strong></label>
           <input type="text" className="form-control" placeholder='Ex: à coté de la pharmacie..' />
         </div>

          <div className='marg6'>
           <label><strong>Numéro de téléphone</strong></label>
           <input type="text" className="form-control" placeholder='N° de téléphone' />
         </div>
          <div className='marg6'>
           <label><strong>Numéro Whatsapp</strong></label>
           <input type="text" className="form-control" placeholder='N° de téléphone (Whatsapp)' />
         </div>

          <div className='marg6'>
  
           <div className='photosblock'>
               <div className='item it889'>
                          <p className='psr'>Service livraison et paiement assurés par Mixed Mode.</p>
               </div>

           </div>
         </div>
 <div className='marg6'>
 <a className="ps-btn ps-btn--black btn88900" href="javascript:void(0)"  > {
              showtype==0 ? 'Confirmer' : 'Négocier'
            } </a>
 </div>





        </Modal.Body>
        <Modal.Footer>


        
        
        </Modal.Footer>
      </Modal>

        </div>
    
     
  
     
    );
}


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default Page;