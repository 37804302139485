import React,{ useState,useEffect,useRef } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateInfoUser,getCommandeById,UpdateUserAddresse,UpdateUserAddresseInf} from "../../../actions/user";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory,BiLogInCircle } from 'react-icons/bi';
import { defaultimg } from "../../../assets/images";
import { MenuVendeur } from "../../../components";
import { LOGOUT_USER } from "../../../constants/actions";
import { changepicture ,getNotification} from "../../../actions/mixedmode";
import { getcommentsvendeur} from "../../../actions/user";



const Page = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { commandeslist, comments } = useSelector(state => state.restaurent)
    const {notification} = useSelector(state => state.mixedmode)
    
       const fileInput = useRef(null)
   
    useEffect(() => {
          
        if (!user.user_id || user.google_id!='V' ) {
            history.replace("/")
        }

         if(user.user_id){
 const param = {}
            param.user_id=user.user_id;
         param.token=user.api_token;
         param.api_token=user.api_token;
          dispatch(getNotification(param))
            dispatch(getcommentsvendeur(user.user_id,user.api_token,{}));

         }

    }, [user.user_id])


    const history = useHistory();
    function gotoCmd(item) {
       history.push(item);
     }


    

            const handleLogout = () =>{
       
        dispatch({type:LOGOUT_USER});
    }

      const handleClickfile = () => {
      fileInput.current.click()
  }

  const handleFileChange = event => {
       
      const formData =  new FormData();
      formData.append('image',event.target.files[0]);
      formData.append('token',user.api_token);
      formData.append('api_token',user.api_token);
      console.log("formData",formData)
      dispatch(changepicture(formData));
     
  }


const getRetours =  () => {
      let retour=0;
       for(let i=0; i < comments.length; i++){

           retour=retour+comments[i]?.nombreproduct

      }

      return retour;

}


    return (
        <div className=" dashboard" >
            <Container>
                <Row>
            <Col  className="text-center mx-auto "  lg="3">

           
               
            </Col>
              
           <Col  className="text-center mx-auto "  >
            
           <div className='flxdash1'>
                 <h5>Bienvenu dans votre espace</h5>

                 <div>


                     {(user.picture=="" || !user.picture) && <Card.Img variant="top" style={{cursor:'pointer',width:100,height:100}}  onClick={() => handleClickfile()} src={defaultimg} />}
              {user.picture && <Card.Img onClick={() => handleClickfile()}  style={{cursor:'pointer',width:100,height:100,backgroundImage:`url(${"https://mixedmode.fr/gestion/public/uploads/"+user.picture})`,borderRadius:50,backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',alignSelf: 'center' ,marginTop:20}} />}
               <form style={{padding:'0px'}}>
               <input
               style={{display:'none'}}
                type="file"

                onChange={(e) => handleFileChange(e)}
                ref={fileInput} 
            />
            </form>


                 </div>
                 <h6>{user?.boutique?.nomboutique}</h6>

                <div className='item'>

                      <div> <a href='javascript:void(0)' onClick={()=>gotoCmd('/vendeur/menu/boutique')} className='coldash'><img src='/images/vendico2.png' /> <label>Gérer ma boutique</label></a></div>
                      <div> <a href='javascript:void(0)' onClick={()=>gotoCmd('/vendeur/commandes/')}   className='coldash'><img src='/images/venico1.png' /> <label>Gérer mes commandes </label><span className='pos88909'>{notification}</span></a> </div>

                </div>

                <div className='item'>

                      <div> <a href='javascript:void(0)' onClick={()=>gotoCmd('/vendeur/livraisons')} className='coldash'><img src='/images/vendico3.png' /> <label>Suivi de livraison</label></a></div>
                      <div> <a href='javascript:void(0)' onClick={()=>gotoCmd('/vendeur/vos-ventes')}  className='coldash'><img src='/images/venico4.png' /> <label>Le point de vos ventes</label></a></div>

                </div>

                <div className='item'>

                      <div> <a href='javascript:void(0)' onClick={()=>gotoCmd('/vendeur/profile')}  className='coldash'><img src='/images/venico6.png' /> <label>Mes informations</label></a></div>
                      <div> <a href='javascript:void(0)' onClick={()=>gotoCmd('/vendeur/stories')}className='coldash'><img src='/images/venico5.png' /> <label>Gérer mes stories</label></a></div>

                </div>

                <div className='item'>

                      <div> <a href='javascript:void(0)' onClick={()=>gotoCmd('/vendeur/commentaires')} className='coldash'><img src='/images/venico7.png' /> <label>Avis et commentaires</label> <span className='pos88909'>{getRetours()}</span> </a></div>
                      <div> <a  href='javascript:void(0)' onClick={()=>gotoCmd('/vendeur/reclamations')}  className='coldash'><img src='/images/venico8.png' /> <label>faire une réclamation</label></a></div>

                </div>

           </div>
           <div className='menudash center'>
   <div className='logout'>
                     <button className="logout_btn color2-bg" onClick={handleLogout}>Déconnexion <BiLogInCircle color={"#FFF"} size={20} /></button>
                </div>
           </div>

             
 
                        

                    </Col>
                      <Col  className="text-center mx-auto my-5"  lg="3"></Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;