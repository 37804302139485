import {SET_SLIDE,SET_COLOR,SET_NOTIFICATION,SET_LIST_STORIES,SET_BOUTIQUES,SET_INFO_BOUTIQUES,SET_TAIL,SET_SOUS_CATEGORY_LIST, AUTHENTICATE_USER,SET_CURRENTPAGE,SET_AVG,SET_COMMENTS,SET_CURRENT_PAGE,SET_SELECT_PAGE,SET_FAQ,SET_CURRENT_SETTINGS,SET_CATEGORY_LIST,SET_CATEGORY_LIST_ALL,SET_COUNT,SET_CURRENT_BOUTIQUE, SET_BEST,SET_LIST_AMAZON,SET_CURRENT_PRODUCT } from "../constants/actions"
import { apiClient } from "./api"

export const getSlides = (params) => dispatch => {
    return apiClient().get(`/slides`, params)
        .then((res) => {
             console.log(res)
            dispatch({type:SET_SLIDE,payload:res.data})
        })
}

export const getBest = (params) => dispatch => {
    return apiClient().post(`/best`, params)
        .then((res) => {
            console.log("res", res.data.products)
            dispatch({type:SET_BEST,payload:res.data.products})
        })
}

export const getCategoryList = (slug,store='') => dispatch => {
    return apiClient().get(`/category/${slug}/?store=`+store)
        .then((res) => {
          
             dispatch({type:SET_CATEGORY_LIST,payload:res.data})
           
            
        })
}

export const getCategoryListAll = (slug) => dispatch => {
    return apiClient().get(`/category/${slug}`)
        .then((res) => {
          
             dispatch({type:SET_CATEGORY_LIST_ALL,payload:res.data})
           
            
        })
}

export const getNotification = (params) => dispatch => {
    return apiClient().post(`/getnotification`, params)
        .then((res) => {
                  dispatch({type:SET_NOTIFICATION,payload:res.data.data})
        })
}



export const getsousCategoryList = (slug,categ='',store='') => dispatch => {
    return apiClient().get(`/categorybyrubrique/${slug}/?categ=`+categ+`&store=`+store)
        .then((res) => {
          
             dispatch({type:SET_SOUS_CATEGORY_LIST,payload:res.data})
            
            
        })
}

export const getColors = () => dispatch => {
    return apiClient().get(`/colors/`)
        .then((res) => {
            
             dispatch({type:SET_COLOR,payload:res.data})
             
            
        })
}
export const getTails = () => dispatch => {
    return apiClient().get(`/tails/`)
        .then((res) => {
            
             dispatch({type:SET_TAIL,payload:res.data})
             
            
        })
}
export const getBoutiques = () => dispatch => {
    return apiClient().get(`/stores/`)
        .then((res) => {
            
             dispatch({type:SET_BOUTIQUES,payload:res.data})
             
            
        })
}

export const getInfoBoutique = (slug) => dispatch => {
    return apiClient().get(`/infoboutique/${slug}`)
        .then((res) => {
            
             dispatch({type:SET_INFO_BOUTIQUES,payload:res.data})
             
            
        })
}


export const getListProduct = (currentPage,params) => dispatch => {

    return apiClient().post(`/getlistproduct?page=`+currentPage, params)
        .then((res) => {

            let newdata=res.data.products.data;


            if(currentPage>1 && !params.order){
                            newdata.sort( () => Math.random() - 0.5);
            }


             
            dispatch({type:SET_LIST_AMAZON,payload:newdata})
            dispatch({type:SET_COUNT,payload:res.data.products.last_page})
            dispatch({type:SET_SELECT_PAGE,payload:currentPage})
            
        })
}









export const getAmazon = (currentPage,params) => dispatch => {
    return apiClient().post(`/amazon?page=`+currentPage, params)
        .then((res) => {
            console.log("res", res.data.products.data)
            dispatch({type:SET_LIST_AMAZON,payload:res.data.products.data})
            dispatch({type:SET_COUNT,payload:res.data.products.last_page})
            
        })
}
export const getAmazonSearch = (currentPage,params) => dispatch => {
    return apiClient().post(`/amazonsearch?page=`+currentPage, params)
        .then((res) => {
            console.log("res", res.data.products.data)
            dispatch({type:SET_LIST_AMAZON,payload:res.data.products.data})
            dispatch({type:SET_COUNT,payload:res.data.products.last_page})
            
        })
}
export const getEbaySearch = (currentPage,params) => dispatch => {
    return apiClient().post(`/ebaysearch?page=`+currentPage, params)
        .then((res) => {
            console.log("res", res.data.products.data)
            dispatch({type:SET_LIST_AMAZON,payload:res.data.products.data})
            dispatch({type:SET_COUNT,payload:res.data.products.last_page})
            
        })
}

export const getEbay = (currentPage,params) => dispatch => {

    return apiClient().post(`/ebay?page=`+currentPage, params)
        .then((res) => {
            console.log("res", res.data.products)
            dispatch({type:SET_LIST_AMAZON,payload:res.data.products.data})
            dispatch({type:SET_COUNT,payload:res.data.products.last_page})
           // dispatch({type:SET_CURRENTPAGE,payload:res.data.products.current_page})
        })
}
export const getProductById = (id,u) => dispatch => {
    return apiClient().post(`/product/${id}`,{user:u})
        .then((res) => {
            console.log("res", res.data.product)
            dispatch({type:SET_CURRENT_PRODUCT,payload:res.data.product})
            dispatch({type:SET_COMMENTS,payload:res.data.comments})
            dispatch({type:SET_AVG,payload:res.data.avgnote})

        })
}
export const getRestaurentById = (id) => dispatch => {
    return apiClient().get(`/products/${id}`)
        .then((res) => {
            console.log("res", res.data.product)
            //dispatch({type:SET_CURRENT_RESTAURENT,payload:res.data})
        })
}
export const addContact = (params) => dispatch => {
    return apiClient().post(`/contactez`, params)
        .then((res) => {
            alert("Merci pour votre message. nous reviendrons vers vous dans les plus brefs délais")
        })
}
export const changepicture = (params) => dispatch => {
    return apiClient().post(`/changepicture`, params)
        .then((res) => {
            dispatch({ type: AUTHENTICATE_USER, payload: res.data.customer })
        })
}
export const addCommentaire = (params) => dispatch => {
    return apiClient().post(`/addcomment`, params)
        .then((res) => {
            dispatch(getProductById(params.product_id,''));
            alert("Merci pour votre commentaire.")
        })
}

export const addNewsletter = (params) => dispatch => {
    return apiClient().post(`/newsletter`, params)
        .then((res) => {
            alert("Merci pour votre inscription.")
        })
}
export const getPage = (params) => dispatch => {
    return apiClient().get(`/page/`+params)
        .then((res) => {
          
            console.log("res", res.data)
             dispatch({type:SET_CURRENT_PAGE,payload:res.data.page})
            // dispatch({type:SET_COUNT,payload:res.data.products.last_page})
            
        })
}
export const getSettings = () => dispatch => {
    return apiClient().get(`/settings`)
        .then((res) => {
           
             dispatch({type:SET_CURRENT_SETTINGS,payload:res.data.page})
            // dispatch({type:SET_COUNT,payload:res.data.products.last_page})
            
        })
}

export const getFaq = () => dispatch => {
    return apiClient().get(`/getfaq`)
        .then((res) => {
           
             dispatch({type:SET_FAQ,payload:res.data.page})
            // dispatch({type:SET_COUNT,payload:res.data.products.last_page})
            
        })
}

export const getStories = (i) => dispatch => {
    return apiClient().get(`/getStories?r=${i}`)
        .then((res) => {
           
             dispatch({type:SET_LIST_STORIES,payload:res.data})
         
            
        })
}

