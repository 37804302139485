import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SET_CURRENT_COMMANDE} from "../../../constants/actions";
import { updateInfoUser,getlistproducts} from "../../../actions/user";
 import { apiClient } from "../../../actions/api"
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory ,BiMoney} from 'react-icons/bi';
import { defaultimg } from "../../../assets/images";
import { Menu } from "../../../components";
import moment from 'moment'

const Page = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
     const { products } = useSelector(state => state.restaurent)
    const [startDate, setStartDate] = useState(new Date());
    const [filtres,setFiltres]=useState([]);
    const [order, setOrder] = useState('')
    const [rubrique, setRubrique] = useState('')
    const [date, setDate] = useState('')
    const [counter, setCounter] = useState('')
    const history = useHistory();
     const [search, setSearch] = useState('')
 
    useEffect(() => {
      history.push('/')
        let params ={filtre:'1',rubrique:3}
        dispatch(getlistproducts(user.user_id,user.api_token,'product',params));
    }, []);

 useEffect(() => {
   if(counter){
      setFormFiltre()
   }
    
}, [counter]);


  
  const setstatuscommande =  (ids,status) => {
    
        let paramsd={
              id_product:ids,
              user_id:user.user_id,
              token:user.api_token,
              api_token:user.api_token,
              status:status,
           };
         
           apiClient().post(`/setstatusproduct`,paramsd)
           .then((res) => {
              let params ={filtre:'1',rubrique:3}
              dispatch(getlistproducts(user.user_id,user.api_token,'product',params));
           
           })

}

    function gotoCmd(id,item) {
    
      if(item.status == 0){
        return false;
      }
       dispatch({type:SET_CURRENT_COMMANDE,payload:item})
       history.push(`/mes-offres-recus/${id}`);
     }

   const HandleFiltre =  (slug,type,e) => {
      
       e.preventDefault();
          switch(type){
             case 'date':

                setDate(slug)
                setCounter(1)

             break;

             case 'rubrique':

                 setRubrique(slug)
                 setCounter(1)
             break;
            case 'order':

                 setOrder(slug)
                 setCounter(1)
             break;

          }

        
            
 
    }



    const  setFormFiltre = async () => {
      let params ={filtre:'1',rubrique:3}


     

      if(order){
        
        params.order=order

      }
       dispatch(getlistproducts(user.user_id,user.api_token,'product',params));
       
  
  
    
     setCounter('')

    }


 const searchproduct = (e) => {
          e.preventDefault();

           let params ={filtre:'1',rubrique:3}
 
          if(search){
       
              params.search=search
               dispatch(getlistproducts(user.user_id,user.api_token,'product',params));

          }
          else{
           
            
                dispatch(getlistproducts(user.user_id,user.api_token,'product',params));

         
          }

          initfiltre()

    }

const initfiltre = () => {
     setDate('')
     setRubrique('')
     setOrder('')

  }





    
    return (
        <div className="mescommandes register listproducts mesannonces" >
            <Container>
                <Row>
            <Col  className="text-center mx-auto my-5"  lg="3">

               <Menu slug='mesannonces' />
               
            </Col>
              
            <Col  className="text-center mx-auto my-5"  >

            <h3 className="mb-4" > Mes Annonces ventout </h3>

             <a onClick={()=>history.push('/client/vos-ventes')}  className='ps-btn ps-btn--black' style={{color:'#FFF'}}><BiMoney   size={20} /> Le point de vos ventes</a>

            <br/> 

            <div className='row'>
                 <div>

                 </div>
            </div>

            <div className='orderactionmob'>

              
            </div>

            <div className='filtreorder'>
                 
         
 <div className='item listing ' style={{width: '50%'}}>
                    <div className="form-group--nest" style={{    width: '100%'}}><input className="form-control" type="text" placeholder="Rechercher un article.." onChange={e => setSearch(e.target.value)}/><div className='ico' onClick={(event)=>searchproduct(event)}><i className="fa fa-search"></i></div></div>
                  </div>

               
                 <div className='item'>
                     <select className='orderbyorder'  onChange={e => HandleFiltre(e.target.value,'order',e)}  ><option value=''>Trier par</option><option value='created_at'>Date</option><option value='sale_price'>Prix</option></select>
                </div>


            </div>

                    <form className="mb-5 mb5mob" >
                           
                            
                          <Row className="mb-3"  >

                          <div className='tavbleweb'>

                                <Table responsive  >
                          <thead>
                            <tr>
                                <th></th>
                                <th>Référence</th>
                                <th>Nom de l'article</th>
                                <th >Date création </th>
                                <th  >Prix </th>
                                <th  >Statut</th>
                                <th  >Actions</th>

                            </tr>
                          </thead>
                        <tbody>

                     {products?.data?.map((item) => (

                              <tr>
                               <td><img style={{width: '59px'}} src={item.image_url} /></td>
                              <td>{item.sku}</td>
                              <td>{item.product_name}</td>
                              <td>
                                 {moment(new Date(item.date_creation)).format("DD/MM/YYYY")}
                              </td>
                              <td>{parseFloat(item.sale_price).toFixed(2)} CFA</td>
                              <td>
                               {
                                 item.status == 1 ? 
                                 <span className='badge alert-success'>En ligne </span>
                                 :
                                  item.status == 0 ?
                                 <span className='badge alert-warning'>En cours de vérification </span>
                                 :
                                 <span className='badge alert-danger'>Désactivée</span>

                              }
                                 
                              </td>
                              <td>
                              <div className="list-contr" style={{display: 'flex',fontSize: '12px',    justifyContent: 'center',
    alignItems: 'center'}}>
                                 {
                                  item.status != 0 &&
                                   <a  href='javascript:void(0)' style={{minWidth: '85px'}} onClick={()=>gotoCmd(item.product_id,item)} className="color-bg tolt center view" data-microtip-position="left" data-tooltip="Modifier">Voir Offres <span className='sop009'>{item?.nboffres}</span> <i class="icon-edit"></i></a>
                                 }


                                  {
                                  item.status == 1 &&
                                   <a  href='javascript:void(0)' style={{    background: '#ce5151'}} onClick={()=>setstatuscommande(item.product_id,0)} className="color-bg tolt center view" data-microtip-position="left" data-tooltip="Modifier">Désactiver <i class="icon-edit"></i></a>
                                 }

                                    {
                                  item.status == 0 &&
                                   <a  href='javascript:void(0)' style={{    background: '#3c853c'}} onClick={()=>setstatuscommande(item.product_id,1)} className="color-bg tolt center view" data-microtip-position="left" data-tooltip="Modifier">Activer <i class="icon-edit"></i></a>
                                 }
                               
                               
                               </div>
                              </td>
                            </tr>


                          ))}
                          
                          

                          </tbody>
                        </Table>
                          </div>
                           
                      

                        <div className='tavblemob'>

                          {products?.data?.map((item) => (
                                
                                 <div className='items'>
                              <a  href='javascript:void(0)'  >

                                <div className='rowitmtop'>
                                   <span> {moment(new Date(item.date_creation)).format("DD MMM YYYY")}</span>
                                   <span>Réf : {item.sku}</span>
                                </div>

                                <div className='process-item'>
                                   <img style={{width: '59px'}} src={item.image_url} />
                                    <strong>{item.product_name}</strong>
                                    <div className='rowsitm'>
                                    
                                       <div><b>{parseFloat(item.sale_price).toFixed(2)} CFA</b></div>
                                       <div>

                              {
                                 item.status == 1 ? 
                                 <span className='badge alert-success'>En ligne </span>
                                 :
                                  item.status == 0 ?
                                 <span className='badge alert-warning'>En cours de vérification </span>
                                 :
                                 <span className='badge alert-danger'>Désactivée</span>

                              }


                                       </div>


        


                                    </div>

                      <div className="list-contr" style={{display: 'flex',fontSize: '12px',    justifyContent: 'center',
    alignItems: 'center'}}>
                                 {
                                  item.status != 0 &&
                                   <a  href='javascript:void(0)' style={{minWidth: '85px'}} onClick={()=>gotoCmd(item.product_id,item)} className="color-bg tolt center view" data-microtip-position="left" data-tooltip="Modifier">Voir Offres <span className='sop009'>{item?.nboffres}</span> <i class="icon-edit"></i></a>
                                 }


                                  {
                                  item.status == 1 &&
                                   <a  href='javascript:void(0)' style={{    background: '#ce5151'}} onClick={()=>setstatuscommande(item.product_id,2)} className="color-bg tolt center view" data-microtip-position="left" data-tooltip="Modifier">Désactiver <i class="icon-edit"></i></a>
                                 }

                                    {
                                  item.status == 2 &&
                                   <a  href='javascript:void(0)' style={{    background: '#3c853c'}} onClick={()=>setstatuscommande(item.product_id,1)} className="color-bg tolt center view" data-microtip-position="left" data-tooltip="Modifier">Activer <i class="icon-edit"></i></a>
                                 }
                               
                               
                               </div>

                                 </div>
                                </a>
                              </div>


                          ))}
                           

                            
                             
                        </div> 
 
                           

                          </Row>

                    </form>
                        
                         






   

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;