import React,{ useState,useEffect ,useRef} from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWalette} from "../../../actions/user";
import { SET_CURRENT_COMMANDE} from "../../../constants/actions";
import "react-datepicker/dist/react-datepicker.css";
import {  BiMap,BiShoppingBag,BiUserCircle,BiHistory,BiUser,BiLogInCircle,BiHeart,BiMoney } from 'react-icons/bi';
import { defaultimg } from "../../../assets/images";
import { Menu } from "../../../components";

import moment from 'moment'
import { LOGOUT_USER } from "../../../constants/actions";
import { changepicture,getNotification } from "../../../actions/mixedmode";


const Page = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { walette } = useSelector(state => state.restaurent)
     const {notification} = useSelector(state => state.mixedmode)
    const [startDate, setStartDate] = useState(new Date());
    const [filtres,setFiltres]=useState([]);
    const [order, setOrder] = useState('')
    const [rubrique, setRubrique] = useState(1)
    const [date, setDate] = useState('')
    const [type, setType] = useState(1)

    const [counter, setCounter] = useState('')
    const history = useHistory();
 
 const fileInput = useRef(null)
   
    useEffect(() => {
          
        if (!user.user_id || user.google_id!='A' ) {
            history.replace("/")
        }

             if(user.user_id){
 const param = {}
            param.user_id=user.user_id;
         param.token=user.api_token;
         param.api_token=user.api_token;
          dispatch(getNotification(param))

         }

    }, [user.user_id])

 
            const handleLogout = () =>{
       
        dispatch({type:LOGOUT_USER});
    }

      const handleClickfile = () => {
      fileInput.current.click()
  }

    const handleClick = (src) => {
         history.push(src);
    }

  const handleFileChange = event => {
       
      const formData =  new FormData();
      formData.append('image',event.target.files[0]);
      formData.append('token',user.api_token);
      formData.append('api_token',user.api_token);
    
      dispatch(changepicture(formData));
     
  }
    
   
  


    return (
        <div className="mescommandes dashboard " >
                         <Container>
                <Row>
            <Col  className="text-center mx-auto "  lg="3">

           
               
            </Col>
              
           <Col  className="text-center mx-auto "  >
            
           <div className='flxdash1'>
                 <h5>Bienvenu dans votre espace</h5>

                 <div>


                     {(user.picture=="" || !user.picture) && <Card.Img variant="top" style={{cursor:'pointer',width:100,height:100}}  onClick={() => handleClickfile()} src={defaultimg} />}
              {user.picture && <Card.Img onClick={() => handleClickfile()}  style={{cursor:'pointer',width:100,height:100,backgroundImage:`url(${"https://mixedmode.fr/gestion/public/uploads/"+user.picture})`,borderRadius:50,backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',alignSelf: 'center' ,marginTop:20}} />}
               <form style={{padding:'0px'}}>
               <input
               style={{display:'none'}}
                type="file"

                onChange={(e) => handleFileChange(e)}
                ref={fileInput} 
            />
            </form>


                 </div>
                 <h6>{user?.name}</h6>

         <Card>
                  <Card.Body>
                    
                    <Card.Text>
                
                    </Card.Text>
                  <Nav defaultActiveKey="/home" className="flex-column nav-style">
                  <Nav.Link   onClick={()=>handleClick('/commandes')}  ><BiShoppingBag  size={20} /> Mes commandes</Nav.Link>
                 {
                  /*

                    <Nav.Link style={{position:'relative'}}  onClick={()=>handleClick('/mes-annonces')}   ><BiShoppingBag  size={20} /> Mes annonces Ventout <span className='pos88909' style={{top: '15px'}}>{notification}</span> </Nav.Link>

                  */
                 }
                  <Nav.Link onClick={()=>handleClick('/profile')}  ><BiUserCircle   size={20} /> Mes coordonnées</Nav.Link>
                  <Nav.Link  onClick={()=>handleClick('/adresses')}  ><BiMap  size={20} /> Adresse de livraison</Nav.Link>
                 
                   <Nav.Link    onClick={()=>handleClick('/mes-articles-favoris')} ><BiHeart   size={20} /> Mes Articles favoris</Nav.Link>
                  <Nav.Link    onClick={()=>handleClick('/mes-boutiques-preferees')}  ><BiHeart   size={20} /> Mes Boutiques préférées</Nav.Link>
                   
                  <Nav.Link  onClick={()=>handleClick('/reclamations')}  ><BiUser   size={20} /> Laisser une réclamation</Nav.Link>
                </Nav>

          

                
                  </Card.Body>
                </Card>

              

               
 

           </div>
           <div className='menudash center'>
   <div className='logout'>
                     <button className="logout_btn color2-bg" onClick={handleLogout}>Déconnexion <BiLogInCircle color={"#FFF"} size={20} /></button>
                </div>
           </div>

             
 
                        

                    </Col>
                      <Col  className="text-center mx-auto my-5"  lg="3"></Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;