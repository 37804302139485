import React from "react";
import './styles.scss';
import { Button,Stack } from 'react-bootstrap';
import { useDispatch,useSelector } from "react-redux";
import { REMOVE_PRODUCT_BASKET,UPDATE_QTY,GET_TOTAL_CART } from "../../constants/actions";
import { BiXCircle,BiPlusCircle,BiMinusCircle } from 'react-icons/bi';

const Component = ({ item,changeInchild}) => {
    const dispatch = useDispatch()
    const { total } = useSelector(state => state.basket)
    const handleDelete = () => {
        dispatch({ type: REMOVE_PRODUCT_BASKET, payload: item.id })
        changeInchild();   
    }
    const addquantity = () => {
       
        dispatch({ type: UPDATE_QTY,payload: item.id,qty:item.qty+1 })
        changeInchild();      
    
    }
    const minusquantity = () => {
        if(item.qty>1){
          dispatch({ type: UPDATE_QTY,payload: item.id,qty:item.qty-1 })  
        }
        changeInchild();
    }
    return (
<tr>
                                    <td data-label="Product">
                                        <div className="ps-product--cart">
                                            <div className="ps-product__thumbnail"><a href="javascript:void(0)"><img src={item.product.img} alt=""/></a></div>
                                            <div className="ps-product__content"><a href="javascript:void(0)">{item.product.title}</a>
                                                <a className='actionmobile' href="javascript:void(0)" onClick={handleDelete}><i className="icon-cross"></i></a>
                                                <p class="variations">

                                            {item?.extrasdatas?.map((item) => (
                                                                
                                                                item.name == 'Couleur' ?
                                                               <div style={{    display: 'flex'}}>{item.name}:<strong style={{textTransfom:'uppercase',marginLeft:'10px'}}> <span className="_1xuQrbasquet" style={{backgroundColor: item.value}}></span></strong></div>       
                                                                :

                                                               <div style={{    display: 'flex'}}>{item.name}:<strong style={{textTransfom:'uppercase',marginLeft:'10px'}}> {item.value}</strong></div>       
                                                ))}

                                                
                                               
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="price" data-label="Price"><span>{item.product.price} CFA</span></td>
                                    <td data-label="Quantity">
                                        <div className="form-group--number">
                                            <button className="up" onClick={addquantity}>+</button>
                                            <button className="down" onClick={minusquantity}>-</button>
                                            <input className="form-control" type="text" placeholder="1" value={item.qty} />
                                        </div>
                                    </td>
                                    <td className='total' data-label="Total"><span>{item.product.price*item.qty} CFA</span></td>
                                    <td className='actions' data-label="Actions"><a href="javascript:void(0)" onClick={handleDelete}><i className="icon-cross"></i></a></td>

    {

        /*

          <div className="basket-item"  >
            <div style={{ backgroundImage: `url(€{item.product.img})` }} alt="" className="image" />
            <div className="info" >
                <h5>{item.product.title}<span>{item.product.price}€</span></h5>
                {item.extrasdatas?.map((item) => (
                    <div key={item.id}>{`* €{item.choice} €{item.price!=0 ? item.price+'€':""}`}</div>
                ))}
                

                <Stack direction="horizontal" gap={3}>
                <div className="bg-light "><Button variant="primary delete-btn" onClick={minusquantity} ><BiMinusCircle size={20} /></Button></div>
                <div className=" ">{item.qty}</div>
                <div className="bg-light "> <Button variant="primary delete-btn" onClick={addquantity} ><BiPlusCircle size={20} /></Button></div>
                </Stack>
                   
                

            </div>
            <Button variant="primary delete-btn" onClick={handleDelete} ><BiXCircle size={20} /></Button>
        </div>

        */
    }
</tr>

        
    );
}

export default Component;