import React, { useEffect,useState } from "react";
import './styles.scss';
import { logo } from "../../assets/images";
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../actions/user";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Page = () => {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    
    useEffect(() => {
        if (user.id) {
            history.replace("/")
        }
    }, [user.id])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        console.log("formDataObj", formDataObj);
  
        
        if (formDataObj.password === formDataObj.confirmPassword) {
            dispatch(registerUser(formDataObj,startDate));
        } else {
            alert("les deux mots de passe ne correspondent pas")
        }
    }
    return (
        <div className="register" >
        
            <Container>
                <Row>
                    <Col lg={{ span: 5 }} className="text-center mx-auto my-5" >
                        
                        <Form className="mb-5" onSubmit={handleSubmit} >
                            <h3 className="mb-4" >Créer un compte</h3>
                       
                         <br/><br/>
                            <Row className="mb-3">
                                <Form.Group as={Col} >
                                    <Form.Label>Prénom*</Form.Label>
                                    <Form.Control type="text" placeholder="Robert" name="firstname" />
                                </Form.Group>
                                <Form.Group as={Col} >
                                    <Form.Label>Nom*</Form.Label>
                                    <Form.Control type="text" placeholder="Aliox" name="lastname" />
                                </Form.Group>
                            </Row>
                             
                        
                            <Form.Group className="mb-3" >
                                <Form.Label>Téléphone*</Form.Label>
                                <Form.Control type="tel" placeholder="06*****" name="mobile_phone" />
                            </Form.Group>
                                <Form.Group className="mb-3" >
                                <Form.Label>Email*</Form.Label>
                                <Form.Control type="email" placeholder="mail@website.com" name="email" />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Mot de passe *</Form.Label>
                                <Form.Control type="password" placeholder="Min. 8 character" name="password" />
                            </Form.Group>
                            <Form.Group className="mb-4" >
                                <Form.Label>Confirmer mot de passe*</Form.Label>
                                <Form.Control type="password" placeholder="Min. 8 character" name="confirmPassword" />
                            </Form.Group>
                            <div className="mb-4"><div className="form-check"><input required="" type="checkbox" className="form-check-input"/>
                               <label title=""className="form-check-label">En créant un compte j’accepte les CGV</label>
                               </div></div>
                            <Button className="ps-btn ps-btn--fullwidth" type="submit">
                            S'inscrire
                            </Button>
                        </Form>
                        <p className="mb-1" >Déjà utilisateur ??</p>
                        <Link to="/login">Connexion</Link>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;