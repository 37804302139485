import React from "react";
import {
    BrowserRouter as Router,
    Switch,
} from "react-router-dom";
import { CustomRoute } from "../components";
import { 

    Home,
    Fashion,
    Stories,
    Listing,
    Afrocreation,
    ListingAfrocreation,
    Ventout,
    ListingVentout,
    Surcommande,
    ListingSurcommande,
    Enpromo,
    DevenirVendeur,
    DevenirVendeurRegister,

    Login,
    Register,
    Comptecree,
    ForgetPassword,
    Platformes,
    DetailProduct,
    Commentcamarche,
    Vosventesclient,
   
    Basket,
    Profile,
    Dashboardclient,
    Commandes,
    Reclamations,
    Commande,
    CommandeConfirmation,
    CommandeDetail,
    Favoris,
    Boutiquesfavoris,
    AnnoncesClient,
    OffresClient,
    DetailOffre,
    
    DashboardVendeur,
    BoutiquemenuVendeur,
    ProfileVendeur,
    AdressesVendeur,
    CommandesVendeur,
    LivraisonVendeur,
    DetailLivraisonVendeur,
    CommandeDetailVendeur,
    HistoriqueVendeur,
    ReclamationsVendeur,
    BoutiquesfavorisVendeur,
    AddproductVendeur,
    ListProductVendeur,
    ListproductpromoVendeur,
    updateproductVendeur,
    Liststories,
    Addstorie,
    Listcomments,
    Webcam,
    vosventes,

    DashboardLivreur,
    CommandesLivreur,
    CommandeDetailLivreur,
    ProfileLivreur,

    DashboardRamasseur,
    CommandesRamasseur,
    CommandeDetailRamasseur,
    ProfileRamasseur,

    DashboardAdmin,
    CommandesAdmin,
    CommandeDetailAdmin,
    ProfileAdmin,

    Historique,
    Apropos,
    Contact,
    Aide,
    Cgv,
    Cgu,
    Rgbd,
     Retour,
    Livraison,
    Adresses
 } from "../pages";

const MainNavigator =()=> {
    return (
        <Router >
            <Switch>
                <CustomRoute parts exact path="/" component={Home} />
                <CustomRoute parts  back={1} exact path="/stories" component={Stories} />
                <CustomRoute parts back={1} exact path="/fashion-style" component={Fashion} />
                <CustomRoute parts back={1} path="/fashion-style/produits/:slug" component={Listing} />
                <CustomRoute parts back={1} exact path="/afro-creation" component={Afrocreation} />
                <CustomRoute parts back={1} path="/afro-creation/produits/:slug" component={ListingAfrocreation} />
                <CustomRoute parts back={1} exact path="/ventout/produits/:slug" component={ListingVentout} />
                <CustomRoute parts back={1} exact path="/ventout" component={ListingVentout} />
                <CustomRoute parts back={1} path="/ventout/produit/:slug" component={Ventout} />
                <CustomRoute parts back={1} exact path="/sur-commande" component={Surcommande} />
                <CustomRoute parts  back={1} path="/sur-commande/produits/:slug" component={ListingSurcommande} />
                <CustomRoute parts  back={1} path="/en-promo/produits/:slug" component={Enpromo} />
                <CustomRoute parts   path="/finalisez-commande" component={Commande} />
                <CustomRoute parts back={1} path="/boutiques/:slug/:id/:categorie" component={Platformes} />
                <CustomRoute parts back={1} path="/boutique/produit/:slug2/:id" component={DetailProduct} />
                <CustomRoute parts back={1} path="/devenir-vendeur" component={DevenirVendeur} />
                <CustomRoute parts back={1} path="/vendeur/inscription/:slug" component={DevenirVendeurRegister} />
                <CustomRoute parts back={1} path="/login" component={Login}  />
                <CustomRoute parts back={1} path="/login/to/:id" component={Login}  />
                <CustomRoute parts back={1} path="/register" component={Register}  />
                <CustomRoute parts back={1} path="/compte-cree" component={Comptecree}  />
                <CustomRoute parts back={1} path="/password" component={ForgetPassword}  />
                <CustomRoute parts  back={1} path="/panier" component={Basket} />
                <CustomRoute parts  back={1} path="/profile" component={Profile} />
                <CustomRoute parts  path="/client/dashboard" component={Dashboardclient} />
                <CustomRoute parts  back={1} path="/client/vos-ventes" component={Vosventesclient} />
                 <CustomRoute parts  back={1} path="/mes-annonces" component={AnnoncesClient} />
                 <CustomRoute parts  back={1} path="/mes-offres-recus/:id" component={OffresClient} />
                 <CustomRoute parts   back={1} path="/detail-offre/:id" component={DetailOffre} />


                <CustomRoute parts  back={1} path="/adresses" component={Adresses} />
                <CustomRoute parts  back={1}  path="/commandes" component={Commandes} />
                <CustomRoute parts    path="/commande/confirmation/:id" component={CommandeConfirmation} />
                <CustomRoute parts  back={1} path="/commande/detail/:id" component={CommandeDetail} />
                <CustomRoute parts  back={1} path="/historique" component={Historique} />
                <CustomRoute parts back={1}  path="/reclamations/" component={Reclamations} />
                <CustomRoute parts back={1}  path="/mes-articles-favoris/" component={Favoris} />
                <CustomRoute parts back={1}  path="/mes-boutiques-preferees/" component={Boutiquesfavoris} />

                <CustomRoute parts  path="/vendeur/dashboard/" component={DashboardVendeur} />
                <CustomRoute parts  back={1}  path="/vendeur/menu/boutique" component={BoutiquemenuVendeur} />
                <CustomRoute parts  back={1}  path="/vendeur/commandes/" component={CommandesVendeur} />
                <CustomRoute parts  back={1}  path="/vendeur/commande/detail/:id" component={CommandeDetailVendeur} />
                <CustomRoute parts  back={1}  path="/vendeur/livraisons/" component={LivraisonVendeur} />
                <CustomRoute parts  back={1}  path="/vendeur/livraison/detail/:id" component={DetailLivraisonVendeur} />
                <CustomRoute parts back={1} path="/vendeur/profile" component={ProfileVendeur} />
                <CustomRoute parts  back={1} path="/vendeur/historique" component={HistoriqueVendeur} />
                <CustomRoute parts  back={1} path="/vendeur/ajouter-produit/" component={AddproductVendeur} />
                <CustomRoute parts  back={1} path="/vendeur/liste-produits/" component={ListProductVendeur} />
                <CustomRoute parts  back={1} path="/vendeur/promo/liste-produits/" component={ListproductpromoVendeur} />
                <CustomRoute parts  back={1} path="/vendeur/modifier-produit/:id" component={updateproductVendeur} />
                <CustomRoute parts  back={1} path="/vendeur/stories" component={Liststories} />
                <CustomRoute parts  back={1} path="/vendeur/ajouter-storie" component={Addstorie} />
                <CustomRoute parts  back={1} path="/vendeur/modifier-storie/:id" component={Addstorie} />
                <CustomRoute parts  back={1} path="/vendeur/commentaires" component={Listcomments} />
                <CustomRoute parts  back={1} path="/vendeur/reclamations" component={ReclamationsVendeur} />
                <CustomRoute   back={1} path="/vendeur/screen-video" component={Webcam} />
                <CustomRoute parts  back={1} path="/vendeur/vos-ventes" component={vosventes} />



                <CustomRoute parts  path="/livreur/dashboard/" component={DashboardLivreur} />
                 <CustomRoute parts back={1} path="/livreur/commandes/" component={CommandesLivreur} />
                  <CustomRoute parts back={1}  path="/livreur/commande/detail/:id" component={CommandeDetailLivreur} />
                   <CustomRoute parts back={1} path="/livreur/profile/" component={ProfileLivreur} />

                <CustomRoute parts  path="/ramasseurs/dashboard/" component={DashboardRamasseur} />
                 <CustomRoute parts back={1} path="/ramasseurs/commandes/" component={CommandesRamasseur} />
                  <CustomRoute parts back={1}  path="/ramasseurs/commande/detail/:id" component={CommandeDetailRamasseur} />
                   <CustomRoute parts back={1} path="/ramasseurs/profile/" component={ProfileRamasseur} />

                <CustomRoute parts  path="/admin/dashboard/" component={DashboardAdmin} />
                 <CustomRoute parts back={1} path="/admin/commandes/" component={CommandesAdmin} />
                  <CustomRoute parts back={1}  path="/admin/commande/detail/:id" component={CommandeDetailAdmin} />
                   <CustomRoute parts back={1} path="/admin/profile/" component={ProfileAdmin} />


                <CustomRoute parts  back={1} path="/apropos" component={Apropos} />
                <CustomRoute parts back={1}  path="/commentcamarche" component={Commentcamarche} />
                <CustomRoute parts  back={1} path="/aide" component={Aide} />
                <CustomRoute parts  back={1} path="/contact" component={Contact} />
                <CustomRoute parts  back={1} path="/cgv" component={Cgv} />
                <CustomRoute parts  back={1} path="/cgu" component={Cgu} />
                <CustomRoute parts  back={1} path="/rgbd" component={Rgbd} />
                 <CustomRoute parts back={1} path="/retour" component={Retour} />
                <CustomRoute parts back={1} path="/livraison" component={Livraison} />
            </Switch>
        </Router>
    );
}

export default MainNavigator;