import React, { useEffect,useState } from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button ,Modal,CloseButton } from "react-bootstrap"
import { ProductItem, RestaurentItem } from "../../components";
import { getAllRestaurents, getRestaurentById } from "../../actions/restaurent";
import { getProductById,addCommentaire } from "../../actions/mixedmode";
import { checkliked,addtofavorit,deltetofavorit} from "../../actions/user";
import { ADD_PRODUCT_BASKET,GET_TOTAL_CART } from "../../constants/actions";
import { getSettings} from "../../actions/mixedmode";
import { apiClient } from "../../actions/api"
import { useParams } from "react-router";
import { useHistory } from "react-router";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Carousel from 'react-bootstrap/Carousel'
import Slider from "react-slick";
import moment from 'moment'

import {
  FacebookShareCount ,
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  PinterestShareButton,
  PinterestIcon,

  EmailShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
 
  ViberShareButton,
  VKShareButton,
 
  WorkplaceShareButton
} from "react-share";







const Page = () => {
    const { id,slug2 } = useParams()
    const dispatch = useDispatch();
    const history = useHistory()
    const { currentRestau, products } = useSelector(state => state.restaurent)
    const { product,likedpro,comments,avgnote,settings } = useSelector(state => state.mixedmode);
    const { data,total,reduction } = useSelector(state => state.basket)
    const [quantity, setQuantity] = useState(1);
    const [tabselected,setTabselected] = useState(1);
    const [note,setNote] = useState(4);   
    const [notepour,setNotepour] = useState(0);   
    const [notepour3,setNotepour3] = useState(0);   
    const [notepour2,setNotepour2] = useState(0);   
    const [notepour4,setNotepour4] = useState(0);   
    const [notepour5,setNotepour5] = useState(0);
    const [tail,setTail] = useState(''); 
    const [color,setColor] = useState('');  
    const [colorval,setColorval] = useState('');    
    const { user } = useSelector(state => state.user)
    const [showmodal, setShowmodal] = useState(false);
    const [showmodalvideo, setShowmodalVideo] = useState(false);
    const [show,setShow]=useState(false);
    const [showtype,setShowType]=useState(0);
    const [value, setValue] = useState(0);
    const [allc, setAllc] = useState(0);
    const [errortail, setErrortail] = useState(false);
    const [errorcolor, setErrorcolor] = useState(false);
    const [errorQuantite, setErrorQuantite] = useState(false);
    const [showmodalP, setShowmodalP] = useState(false);
    const [numwatsap, setNumwatsap] = useState('919234567812');
    const [urlshare, setUrlshare] = useState('');
    const [showshare, setShowshare] = useState(false);

      const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const handleCloseAlert = () => setShowAlert(false);




  const handleClose = () => setShowmodal(false);
  const handleShow = () => setShowmodal(true);
  const handleShowVideo = () => setShowmodal(true);
  const handleClose2 = () => setShowmodalVideo(false);
  const setShowmodalPshow = () => setShowmodalP(true);
  const handleCloseshow = () => setShow(false);
 const handleCloseP = () => setShowmodalP(false);

  const handleShowAch = () => {
      setShowType(0)
      setShow(true);
    }
  const handleShowNeg = () => {
      setShowType(1)
      setShow(true);
    }




  const goToCart = () =>{
         history.push(`/panier`);
    }
      

      const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    const handleAddCart = (slug) => {
        history.push(`/panier`);
    }

     const handleGoTo= (slug) => {
        history.push(slug);
    }

     useEffect(() => {
      

        



        dispatch(getProductById(id,''));
          dispatch(getSettings())
        if(user?.api_token){
            dispatch(checkliked({user_id:user.user_id,token:user.api_token,api_token:user.api_token,id:id,type:'produit'}));
        }
        if(comments?.length){
         setNotepour(comments?.filter(item => item.note==1).length);
         setNotepour3(comments?.filter(item => item.note==3).length);
         setNotepour4(comments?.filter(item => item.note==4).length);
         setNotepour5(comments?.filter(item => item.note==5).length);
         setNotepour2(comments?.filter(item => item.note==2).length);           
        }

        setUrlshare(`https://mixedmode.fr/boutique/produit/${slug2}/${id}`)

    }, [])


       const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        formDataObj.note=note;
        formDataObj.user_id=user?.api_token? user?.user_id:0;
        formDataObj.product_id=product?.product_id ? product?.product_id:0;
        if(formDataObj.name=="" || formDataObj.email=="" || formDataObj.comment==""){
           setAlertMessage("Merci de remplir tous les champs.")
                   setShowAlert(true)
         
        }else{
            dispatch(addCommentaire(formDataObj));
            const firstNameInput = document.getElementById('comment1');
            firstNameInput.value = '';
             const firstNameInput2 = document.getElementById('comment2');
            firstNameInput2.value = '';
        }
    
    }

   const increment=()=> {
         setQuantity(quantity+1);
  }
 const changetab=(id)=> {
         setTabselected(id);
  }
    const golocation = (location) => {
                history.push(location);
          }

    const golocation2 = (product) => {

           let location='';
           if(product.categorie==1){
                 location='/fashion-style/produits/Homme';
           }
           else if(product.categorie==2){
                 location='/afro-creation/produits/Homme';
           }
            else if(product.categorie==3){
                  location='/ventout';
           }
            else if(product.categorie==4){
             location='/sur-commande/produits/1';
           }

            history.push(location);
               
    } 


   const decrement=()=>{
     setQuantity(quantity >1 ? quantity-1:1);
  }
  const   addtofavoritAdd=()=>{

        if(user?.api_token){
            dispatch(addtofavorit({user_id:user.user_id,token:user.api_token,api_token:user.api_token,id:id,type:'product'}));
        }else{
            history.push("/login");
        }
    }
    const   deltetofavoritun=()=>{
        if(user?.api_token){
            dispatch(deltetofavorit({user_id:user.user_id,token:user.api_token,api_token:user.api_token,id:id,type:'product'}));
        }
    }


     const handlecmdwathsap = () => {
        let hrefs=`https://mixedmode.fr/boutique/produit/${slug2}/${id}`
        let url =`https://api.whatsapp.com/send/?phone=${settings.site_fax}&text=${hrefs}&type=phone_number`
        window.open(url);

     }

   const handleSubmitVentout=(e)=>{
         e.preventDefault();

         if(!user.user_id){

           setAlertMessage("Veuillez-vous connecter pour envoyer un offre !")
                   setShowAlert(true)

         
          return false;

         }
         
           

         const formData = new FormData(e.target)
         const formDataObj = Object.fromEntries(formData.entries())

         if( formDataObj.type==1 && parseFloat(formDataObj.prix) >= parseFloat(product?.sale_price)){

        
            setAlertMessage("Veuillez proposer un prix plus bas que celui demandé par le vendeur !")
                   setShowAlert(true)

          return false;
        }



           const addressobjet={
            "street":formDataObj.adresse,
            "name":user?.first_name,
            "postal_code":'',
            "city":'',
            "state":"",
            "phone_number":formDataObj.numtel,
            "number":formDataObj.numwathsap,
            "surname":user?.last_name
            };

            // select type commande 
            let type_commande=3;
        

            // end

            // add product to cart 

            let options=[]


              if(product.color  ){
                       
                                options.push({'name':'Couleur','code':product.color,'value':product.color})
                             
                             
                         }

                          if(product.size  ){
                         
                                 
                                  options.push({'name':'Taille','code':product.size,'value':product.size})
                              
                         }



                let newprod={"title":product.product_name,"id":product.product_id,"price": product.is_off? product.off_price : product.sale_price,"img":product.image_url,"platform":product.platform,"rubrique":product.categorie, 'vendeur': product.vendeur};
                const payload = {
                            id: Math.floor(Math.random() * 1000000),
                            product: newprod,
                            product_id:product.product_id,
                            product_slug:product.product_slug,
                            product_price:product.is_off ? product.off_price : product.sale_price,
                            reso_id:product.product_id,
                            platform:product.platform,
                            product_name:product.product_name,
                            qty:quantity,
                            extrasdatas: options
                };
                let data =[]
                data.push(payload)




            // end add

       
            const params = {
                user_id: user.user_id,
                payement_inten_id:'',
                details: formDataObj?.informations,
                products: data,
                fraislivraison:0,
                total_price:formDataObj.type==1 ?  parseFloat(formDataObj.prix).toFixed(2) : parseFloat(product?.sale_price).toFixed(2) ,
                client_name:user.name,
                client_phone:formDataObj.numtel,
                client_email:user.email,
                datelivraison:'',
                api_token:user.api_token,
                address:addressobjet,
                token:user.api_token,
                type_paiement:1,
                type_commande:type_commande,
                gift_id:'',
                transaction_id:'',
                valuereduction:0
              };
             

           
              apiClient().post(`/submitorder`,params)
              .then((res) => {
                if(res.data.success=='ok'){

                    handleCloseshow()
                    setShowmodalPshow()
                   

                }
                else{
                   alert('Une erreur est survenue veuillez réessayer ultérieurement !')
                }
               
              })




     }

     /*
     const handleSubmitVentout=(e)=>{
         e.preventDefault();
         const formData = new FormData(e.target)
         const formDataObj = Object.fromEntries(formData.entries())
         
        formDataObj.user_id=user.user_id;
        formDataObj.token=user.api_token;
        formDataObj.api_token=user.api_token;
        formDataObj.product_id=product?.product_id;

        if( formDataObj.type==1 && parseFloat(formDataObj.prix) >= parseFloat(product?.sale_price)){

          alert('Veuillez proposer un prix plus bas que celui demandé par le vendeur !')

          return false;
        }
       

     

         apiClient().post(`/addnewproposition`,formDataObj)
              .then((res) => {

                if(res.data.success=='ok'){

                    handleCloseshow()
                    setShowmodalPshow()

 

                }
                else{
                alert('Erreur !')
                }
                 
        })




     }

*/
      const handleSelect = (e) => {
 
 e.preventDefault();
                         let options=[]
                         let ko=true;

                         // check if product sum section
 
                         for(let i=0; i < data.length; i++){
                          
                             if(parseInt(data[i].product.rubrique) != parseInt(product.categorie) ){
                                 
                                  setAlertMessage("Vous ne pouvez pas commander dans plusieurs catégories (Fashion, Afro-creation, ventout, Sur commande) en même temps !")
                                  setShowAlert(true)
                                 return false;
                             }
                         }



 

                         if(product.colors.length > 0 ){
                               if(color==''){
 
                                 setErrorcolor(true)
                                 ko=false
                                  
                               }
                               else{
                                options.push({'name':'Couleur','code':color,'value':colorval})
                             
                               }
                         }

                          if(product.tails.length > 0 ){
                               if(tail==''){

                                 setErrortail(true)
                                 ko=false
                                  
                               }
                               else{
                                 
                                  options.push({'name':'Taille','code':tail,'value':tail})
                               }
                         }


                       
                               if(parseInt(quantity) > parseInt(product?.quantity)){

                                 setErrorQuantite(true)
                                 ko=false
                                  
                               }
                               else{
                                 setErrorQuantite(false)
                               }
                               
           

                         if(ko==false){
                            return false;
                         }


                        let newprod={"title":product.product_name,"id":product.product_id,"price": product.is_off? product.off_price : product.sale_price,"img":product.image_url,"platform":product.platform,"rubrique":product.categorie,"arienne":product.arienne, "maritime":product.maritime, 'vendeur':product.vendeur};
                        const payload = {
                            id: Math.floor(Math.random() * 1000000),
                            product: newprod,
                            product_id:product.product_id,
                            product_slug:product.product_slug,
                            product_price:product.is_off ? product.off_price : product.sale_price,
                            reso_id:product.product_id,
                            platform:product.platform,
                            product_name:product.product_name,
                            qty:quantity,
                            extrasdatas: options
                        };

 
                  
                      dispatch({ type: ADD_PRODUCT_BASKET, payload })
                     dispatch({ type: GET_TOTAL_CART })
                     setShowmodal(true);

              
                 
    }  
    const countallcmt=()=>{
        let numbercmt=comments.filter(item => item.note==1);
        let numbercmt2=comments.filter(item => item.note==2);
        let numbercmt3=comments.filter(item => item.note==3);
        let numbercmt4=comments.filter(item => item.note==4);
        let numbercmt5=comments.filter(item => item.note==5);

        
    } 

    var settingsSlide = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide:0,
     responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
             infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            
            slidesToShow: 1,
            slidesToScroll: 1,
             infinite: true,
            dots: false
          }
        }
      ]
   };

    var settingsSmo = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide:0,
     responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
             infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
             infinite: true,
            dots: false
          }
        }
      ]
   };


    const handleFiltre = (slug,type,val,e) => {
       e.preventDefault();

          switch(type){
         

             case 'color':
             
               setColor(slug)
               setColorval(val)
               setErrorcolor(false)
                  
             break;

             case 'tail':
              
               setTail(slug)
               setErrortail(false)
                 
             break;
          }

      
    }


    const lanshVideo = () => {
       


        setShowmodalVideo(true)
       
       

    }
    const setShowshareaction = (e) => {
       
           e.preventDefault();

        setShowshare(!showshare)
       
       

    }

    return (

        <div className='detailproduct'>
           
            
            <div className="ps-breadcrumb">
             <Container>
                <div className="ps-container">
                    <ul className="breadcrumb">

                        <li><a href="#" onClick={()=>golocation("/")}>Accueil</a></li>
                        <li><a href="javascript:void(0)" onClick={()=>golocation2(product)}>{product.categorie==1 ?'Fashion' : product.categorie==2 ? 'Afro creation' : product.categorie==3 ? 'Ventout' : 'Sur commande'  }</a></li>
         
                        <li>{product.product_name}</li>
                    </ul>
               </div>
                </Container>
             </div>
           

                <Container>
                <Row className="g-5" >
                         <div className='slidemobile slikslideproduct' style={{position: 'relative'}}>

<div className='actionshare'>

                                                   
                                                   {!likedpro && <a href='javascript:void(0)' onClick={addtofavoritAdd} ><i  className="fa fa-heart-o"></i></a>}
                                                   {likedpro && <a href='javascript:void(0)' onClick={deltetofavoritun}><i style={{color:'#ff6565'}} className="fa fa-heart"></i></a>}

                                                   <a href='javascript:void(0)' onClick={(e)=>setShowshareaction(e)} ><img src='/images/iconshare.png' /></a>
                                                    
{
  showshare &&

     <div className='sharebyttons'>
    <FacebookShareButton
        url={urlshare}
        quote={"Mixed Mode"}
        hashtag={"#hashtag"}
        description={product?.description}
        className="Demo__some-network__share-button"
      >
        <FacebookIcon size={32} round />
  </FacebookShareButton>

 <TwitterShareButton
        title={"Mixed Mode"}
        url={urlshare}
        hashtags={["hashtag1", "hashtag2"]}
      >
        <TwitterIcon size={32} round />
    
  </TwitterShareButton>

   <WhatsappShareButton
       
        url={urlshare}
       
      >
        <WhatsappIcon size={32} round />
    
  </WhatsappShareButton>

    <PinterestShareButton
       
        url={urlshare}
       
      >
        <PinterestIcon size={32} round />
    
  </PinterestShareButton>


    </div>
}
                                                 

                                              </div>

                                     <Slider {...settingsSlide}>

                                        <div className='video'>
                                          <div className='actionshare' style={{marginTop:'77px'}}><a  href={product?.image_url} download target='_blank' ><img src='/images/icondownload.png' /></a></div>
                                         {
                                             (product.video &&  product.categorie==3 ) &&
                                               <div className='center'><a href='javascript:void(0)' onClick={()=>lanshVideo()}><img src='/images/play.png'/></a></div>
                                            }

                                        
                                                 <div className='backsliderimg' style={{backgroundImage:`url(${product?.image_url})`}}></div>
                                          </div>


                                             {product?.photosss?.map((item) => (

                                              
                                                item !=''  &&

                                                  <div className='video'>
                                                   <div className='actionshare' style={{marginTop:'77px'}}><a  href={item} download target='_blank' ><img src='/images/icondownload.png' /></a></div>
                                                       
                 
                                                      
                                                       <div className='backsliderimg' style={{backgroundImage:`url(${item})`}}></div>
                                                </div>
                                              

                                              
                                                 
                                                 ))}

                                             <div className='video'>
                                              <div className='actionshare' style={{marginTop:'77px'}}><a  href={product?.image_url} download target='_blank' ><img src='/images/icondownload.png' /></a></div>

                                            {
                                             (product.video && (product.categorie==1 || product.categorie==2) ) &&
                                               <div className='center'><a href='javascript:void(0)' onClick={()=>lanshVideo()}><img src='/images/play.png'/></a></div>
                                            }
                                                 
                                           
                                                
                                                 <div className='backsliderimg' style={{backgroundImage:`url(${product?.image_url})`}}></div>
                                          </div>
                                               

                                       </Slider>
                     

                         </div>
                    <Col md={12}  >

                        <div className="ps-product--detail ps-product--fullwidth slikslideproduct">
                        <div className="ps-product__header">
                         <div className="ps-product__thumbnail" data-vertical="true">
                                <figure>
                                    <div className="ps-wrapper">
                                            <div className='actionshare'>

                                                               {!likedpro && <a href='javascript:void(0)' onClick={addtofavoritAdd} ><i className="fa fa-heart-o"></i></a>}
                                                   {likedpro && <a href='javascript:void(0)' onClick={deltetofavoritun}><i style={{color:'#ff6565'}} className="fa fa-heart"></i></a>}

                                                   <a href='javascript:void(0)'  onClick={(e)=>setShowshareaction(e)}  ><img src='/images/iconshare.png' /></a>
                                            
                                                   {
  showshare &&

     <div className='sharebyttons'>
        <div> 


      <FacebookShareButton
        url={urlshare}
        quote={"Mixed Mode"}
        hashtag={"#hashtag"}
        description={product?.description}
        className="Demo__some-network__share-button"
      >
        <FacebookIcon size={32} round />
  </FacebookShareButton>

 <TwitterShareButton
        title={"Mixed Mode"}
        url={urlshare}
        hashtags={["hashtag1", "hashtag2"]}
      >
        <TwitterIcon size={32} round />
    
  </TwitterShareButton>

   <WhatsappShareButton
       
        url={urlshare}
       
      >
        <WhatsappIcon size={32} round />
    
  </WhatsappShareButton>

    <PinterestShareButton
       
        url={urlshare}
       
      >
        <PinterestIcon size={32} round />
    
  </PinterestShareButton>



        </div>
    </div>
}


                                              </div>

                                     <Slider {...settingsSlide}>

                                        <div className='video'>
                                         <div className='actionshare' style={{marginTop:'77px'}}><a  href={product?.image_url} download target='_blank' ><img src='/images/icondownload.png' /></a></div>
                                        
                                                 {
                                             (product.video &&  product.categorie==3 ) &&
                                               <div className='center'><a href='javascript:void(0)' onClick={()=>lanshVideo()}><img src='/images/play.png'/></a></div>
                                            }
                                                 <div className='backsliderimg' style={{backgroundImage:`url(${product?.image_url})`}}></div>
                                          </div>


                                               {product?.photosss?.map((item) => (
                                              
                                                item !=''  && 

                                                 <div className='video'>
                                                  <div className='actionshare' style={{marginTop:'77px'}}><a  href={item} download target='_blank' ><img src='/images/icondownload.png' /></a></div>
                                                 
           
                                                
                                                 <div className='backsliderimg' style={{backgroundImage:`url(${item})`}}></div>
                                          </div>
                                              
                                         
                                                 
                                                 ))}

                                                   <div className='video'>
                                                    <div className='actionshare' style={{marginTop:'77px'}}><a  href={product?.image_url} download target='_blank' ><img src='/images/icondownload.png' /></a></div>
                                         {
                                               (product.video && (product.categorie==1 || product.categorie==2) ) &&
                                                 <div className='center'><a href='javascript:void(0)' onClick={()=>lanshVideo()}><img src='/images/play.png'/></a></div>
                                          }
                                                
                                                 <div className='backsliderimg' style={{backgroundImage:`url(${product?.image_url})`}}></div>
                                          </div>
                                               

                                       </Slider>
                               
                                     
                               
                                            
                                         
                                    </div>
                                </figure>
                             
                            </div>
                            <div className="ps-product__info">
                                <h1 className='dettitleprweb'>{product.product_name}</h1>

                                {
                                              (parseInt(product?.categorie)===1 || parseInt(product?.categorie) ==2 || parseInt(product?.categorie)==4 ) &&

                                    <div className="ps-product__meta">
                                    
                                    <div className="ps-product__rating" >
                                        <div className="br-wrapper br-theme-fontawesome-stars"><select className="ps-rating" data-read-only="true" style={{display: 'none'}}>
                                            <option value="1">1</option>
                                            <option value="1">2</option>
                                            <option value="1">3</option>
                                            <option value="1">4</option>
                                            <option value="2">5</option>
                                        </select>
                                    <div className="br-widget br-readonly">
                                    <a href="#" data-rating-value="1" data-rating-text="1" className={(parseFloat(avgnote) > 0.99 ? 'br-selected br-current' : '')}></a>
                                    <a href="#" data-rating-value="1" data-rating-text="2" className={(parseFloat(avgnote) > 1.99 ? 'br-selected br-current' : '')}>
                                    </a>
                                    <a href="#" data-rating-value="1" data-rating-text="3" className={(parseFloat(avgnote) > 2.99 ? 'br-selected br-current' : '')}></a>
                                    <a href="#" data-rating-value="1" data-rating-text="4" className={(parseFloat(avgnote) > 3.99 ? 'br-selected br-current' : '')}>
                                    </a>
                                    <a href="#" data-rating-value="2" data-rating-text="5" className={(parseFloat(avgnote) > 4.99 ? 'br-selected br-current' : '')}></a>
                                    <div className="br-current-rating">1</div>
                                    </div>
                                    </div>
                                    <span>{comments.length } avis</span>
                                    </div> 
                                </div>


                                }
                             

                                {
                                  product?.is_off ?
                                  <h4 className="ps-product__price">{parseFloat(product.off_price).toFixed(2)} CFA -  <del className='prom99'>{parseFloat(product.sale_price).toFixed(2)} CFA</del></h4>
                                  :
                                  <h4 className="ps-product__price">{parseFloat(product.sale_price).toFixed(2)} CFA</h4>


                                }
                                
                                <div className='center'> <h1 className='dettitleprmobile'>{product.product_name}</h1></div>
                                
                                {
                                   product.categorie==3 ? 

                                      <div className='ventout'>
                                        <div className='addrs11'>
                                          <img src='/images/marker2.png' />
                                          {product?.lieu}
                                        </div>

                                


                                <div className='variations listing  ' style={{marginTop:'30px'}}>
                                
                                <Row className="g-5" >

                                  <Col md={6} className='col-6' >
                                     <strong>Catégorie:</strong>
                                     <p>            
                                     {product?.categories?.map((item) => (
           
                                                  item.category_name

                                          ))}</p>

                                     <strong>Etat:</strong>
                                     <p>{product?.etats} </p>
                                  </Col>
                                  <Col md={6} className='col-6' >
                                    <strong>Couleur:</strong>

                                     {product?.colors?.map((item) => (
                                        <p>{item.color_name} </p>
                                      ))}
                                     

                                     <strong>Taille:</strong>
                                          {product?.tails?.map((item) => (
                                        <p>{item.tail_id} </p>
                                      ))}
                                  </Col>

                                </Row>
                                </div>


                             <div className='variations listing  ' style={{marginTop:'10px'}}>
                                
                                <Row className="g-5" >

                                  <Col md={12} className='col-12' >
                                     <strong>Authencité:</strong>
                                     <p className={product?.authencite=='Marque ORIGINALE' ? 'authentique' :'Nonauthentique'}>{product?.authencite}</p>
                                  </Col>
                                   

                                </Row>
                                </div>

                                <div className='variations listing  ' style={{marginTop:'10px'}}>

                                {
                                  product?.vendeur != null  && 

                                  <Row className="g-5"  >

                                  <Col md={12} className='col-12' >
                                     <strong>A propos du vendeur:</strong>
                                     {
                                      product?.boutique &&

                                      <div>

                                        <p>{product?.boutique?.description}</p>
                                      <a className="ps-btn ps-btn--black" href="javascript:void(0)" onClick={()=>handleGoTo(`/boutiques/${product?.boutique?.nomboutique}/${product?.vendeur}/${product?.categorie}`)} >Visiter la boutique</a>

                                      </div>
                                     }
                                     
                                  </Col>
                                   

                                </Row>

                                }
                                
                                
                                </div>


                                
                                <div className="" style={{marginTop:'40px',marginBottom:'40px'}}>

                                 <div className='flx124'>
                  
                                     <a className="ps-btn ps-btn--black f1" href="javascript:void(0)" onClick={()=>handleShowAch()} >Acheter</a>
                                     <a className="ps-btn ps-btn--black f2" href="javascript:void(0)" onClick={()=>handleShowNeg()}>Négocier le prix</a>

                                  </div>
                               
                                
                                </div>




                                      </div>


                                      :

                                     <div className='fashionafrocreation'>

                                    
                                   <div className='variations listing '>

                                {
                                   product?.colors?.length > 0 && 
                                       <div> <strong>Couleur:</strong></div>
                                }
                                  
                                  
                                       <ul className='Xgz5B'>

                                         {product?.colors?.map((item) => (
           
              
                                           <li onClick={(event)=>handleFiltre(item.color_id,'color',item.color_code,event)} title={item.color_name}  >
                                              <label  className={color==(item.color_id) ? "_35LeV coloractive":"_35LeV"}><input  className="_3EX1c" type="checkbox"/><span className="_1xuQr" style={{backgroundColor: item.color_code}}></span></label>
                                           </li>

                                          ))}

                                          
                                      
                                      
                                    </ul>
                                

                                    {
                                      errorcolor==true && 
                                      <div className='alert alert-danger'>Sélectionnez Couleur</div>
                                    }

                                   
                                     {
                                     product?.tails?.length > 0 && 
                                        <div> <strong>Taille:</strong></div>
                                     }

                                      <ul className='Xg78B'>

                                           {product?.tails?.map((item) => (
           
              
                                           <li onClick={(event)=>handleFiltre(item.tail_id,'tail','',event)} title={item.tail_id}  >
                                              <label  className={tail==item.tail_id ? "_35LeV tailactive":"_35LeV"}><span >{item.tail_id}</span></label>
                                           </li>

                                          ))}
                                      </ul>

                                       {
                                      errortail==true && 
                                      <div className='alert alert-danger'>Sélectionnez Taille</div>
                                    }
                                

                                </div>
                                
                                <div className="ps-product__shopping">
                                {
                                  product?.quantity > 0 &&

                                   <figure style={{marginBottom:'0',marginLeft: '0px', width:'100%'}}>
                                        <figcaption>Quantité</figcaption>
                                        <div className="form-group--number">
                                            <button className="up" onClick={increment}><i className="fa fa-plus"></i></button>
                                            <button className="down" onClick={decrement}><i className="fa fa-minus"></i></button>
                                            <input className="form-control" readonly={true}   value={quantity} type="text" placeholder="1"/>
                                        </div>
                              {errorQuantite==true && 
                                      <div className='alert alert-danger'>Il ne reste que {product?.quantity} article(s) en stock</div>
                                    }

                                    </figure>
                                }
                                   
                                     <div className="ps-product__actions" style={{display:'none'}}><a href="#"><i className="icon-heart"></i></a></div>
                                  
                                  <div className='actioncart'>

                                     {
                                       (product?.quantity > 0 )  ?

                                       <a className="ps-btn ps-btn--black btnflexblack" href="javascript:void(0)" onClick={(event)=>handleSelect(event)} ><span>Ajouter au panier</span><img src='/images/cartblack.png' /></a>
                

                                       :

                                       <img style={{    width: '142px',marginRight: '29px'}} src='/images/rupture_de_stock.png' />
                


                                     }
                                     

                                   
                                     <a className="ps-btn ps-btn--black btnflexblack forwatsap" href="#"  onClick={()=>handlecmdwathsap()} ><span>Commander sur whatsApp</span><img src='/images/icowatshblack.png' /></a>
   
                                  </div>
                                   
                                </div>





                                     </div>

                                }

                              
                         

      





    
                                <div className="ps-product__specification">
                                {product.sku == '' ? '' : 
                                <p><strong>SKU:{product.sku}</strong></p>
                              }
                                   
                                    <p className="categories"><strong> Categories:</strong>
                                                 <a href="#">{product.categorie==1 ?'Fashion' : product.categorie==2 ? 'Afro creation' : product.categorie==3 ? 'Ventout' : 'Sur commande'  }</a> 
                                         {product?.categories?.map((item) => (
           
                                                  <a href="#">, {item.category_name}</a> 

                                          ))}

                                    

                                    </p>
                                    
                                </div>
                                <div className="ps-product__sharing" ><a className="facebook" href="#"><i className="fa fa-facebook"></i></a><a className="twitter" href="#"><i className="fa fa-twitter"></i></a><a className="google" href="#"><i className="fa fa-google-plus"></i></a><a className="linkedin" href="#"><i className="fa fa-linkedin"></i></a><a className="instagram" href="#"><i className="fa fa-instagram"></i></a></div>
                            </div>
                        </div>


                        <div className="ps-product__content ps-tab-root ps-tab-root-web" style={{ display:product.categorie==3 ? 'none' : 'block' }}  >
                              
                               <Box sx={{ width: '100%' }}>
                                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                      <Tab label="Description" {...a11yProps(0)} />
                                       <Tab label="Vendeur" {...a11yProps(1)} />
                                       <Tab label={"Commentaires ("+comments.length+")"} {...a11yProps(2)} /> 
                                     
                                     
                                    </Tabs>
                                  </Box>
                                  <TabPanel value={value} index={0}>
                                    <div className="ps-document">
                                        
                                           <div dangerouslySetInnerHTML={{ __html: product.description }}></div>
                                         
                                       
                                    </div>


                                  </TabPanel>
                                  <TabPanel value={value} index={1}>
                                   {
                                  product?.vendeur != null && 
                                  <div>
                                     <h4>{product?.boutique?.nomboutique}</h4>
                                    <p>{product?.boutique?.description}</p>
                                     <br/>
                                    <div className='ps-product__boutique'>

                                   
                                      <a className="ps-btn ps-btn--black" href="#" onClick={()=>handleGoTo(`/boutiques/${product?.boutique?.nomboutique}/${product?.vendeur}/${product?.categorie}`)} >Visiter la boutique</a>

                                    </div>
                                   </div>
                                 }
                                  </TabPanel>
                                  <TabPanel value={value} index={2}>

                                        <form className="ps-form--review" onSubmit={handleSubmit}   method="get">
                                                <h4>SOUMETTRE VOTRE AVIS</h4>
                                                <p>Votre adresse email ne sera pas publiée</p>
                                                <div className="form-group form-group__rating">
                                                    <label>Votre évaluation de ce produit</label>
                                                    <div className="br-wrapper br-theme-fontawesome-stars"><select className="ps-rating" data-read-only="false" style={{display: 'none'}}>
                                                        <option value="0">0</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                    </select>
                                                 <div className="br-widget">
                                                    <a onClick={()=>setNote(1)} data-rating-value="1" data-rating-text="1" className={(note>0 ? 'br-selected br-current' : '')}>
                                                    </a><a onClick={()=>setNote(2)} data-rating-value="2" data-rating-text="2" className={(note>1 ? 'br-selected br-current' : '')}>
                                                    </a><a onClick={()=>setNote(3)} data-rating-value="3" data-rating-text="3" className={(note>2 ? 'br-selected br-current' : '')}>
                                                    </a><a onClick={()=>setNote(4)} data-rating-value="4" data-rating-text="4" className={(note>3 ? 'br-selected br-current' : '')}>
                                                    </a><a onClick={()=>setNote(5)} data-rating-value="5" data-rating-text="5" className={(note>4 ? 'br-selected br-current' : '')}>
                                                    </a><div className="br-current-rating">
                                                    </div>

                                                    </div></div>
                                                </div>
                                                <div className="form-group">
                                                    <textarea name="comment" className="form-control" rows="4" placeholder="Donnez votre avis ici" id='comment1' ></textarea>
                                                </div>
                                                <div className="row" >
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12  ">
                                                        <div className="form-group">
                                                            <input defaultValue={user?.api_token ? user.name:""} name="nom" className="form-control" type="text" placeholder="Votre Nom" />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12  ">
                                                        <div className="form-group">
                                                            <input defaultValue={user?.api_token ? user.email:""} name="email"  className="form-control" type="email" placeholder="Votre Email" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group submit">
                                                    <button tye="submit" className="ps-btn">Soumettre un avis</button>
                                                </div>

                                {comments?.map((item,key) => (  

                                  
                                     key==0 && 
                                      <div className='listcomments'>


                                   <div className='itemimg'><img src='/images/photoprofil.png' /></div>
                                    <div className='itemmessage'>{item.comment}</div>
                                    <div className='itemaction'>

                                    
                                       <a href='#' style={{display:'none'}}> <img src='/images/jaime.png' /><span> 20</span></a>
                                       <a href='#' style={{display:'none'}}> <img src='/images/jaimepas.png' /><span> 6</span></a>

                                    
 
                                    </div>
 
                                  </div>

                                  ))}

                                  {
                                    allc==1 &&

                                    comments?.map((item,key) => (  

                                  
                                     key!=0 && 
                                      <div className='listcomments'>


                                   <div className='itemimg'><img src='/images/photoprofil.png' /></div>
                                    <div className='itemmessage'>{item.comment}</div>
                                    <div className='itemaction'>

                                    
                                       <a href='#' style={{display:'none'}}> <img src='/images/jaime.png' /><span> 20</span></a>
                                       <a href='#' style={{display:'none'}}> <img src='/images/jaimepas.png' /><span> 6</span></a>

                                    
 
                                    </div>
 
                                  </div>

                                  ))



                                  }


                                 {
                                    allc==0 &&
                                      <a href='javascript:void(0)' className='showallcomments' onClick={()=>setAllc(1)}  >Voir tous les commentaires</a>

                                  }

                               



                                  </form>
                                  </TabPanel>
                                </Box>


                        </div>
                       

                       <div className='ps-tab-root-mobile'  >

                            <div className='item '>
                                 <h6>Description de l'article</h6>
                                     <div dangerouslySetInnerHTML={{ __html: product.description }}></div>
                            </div>

                            <div className='item '>
                                 <h6>Commentaires ({comments?.length})</h6>
                                
                                <form className="ps-form--review" onSubmit={handleSubmit}   method="get">

                                  <div className="form-group form-group__rating">
                                                    <label>Votre évaluation de ce produit</label>
                                                    <div className="br-wrapper br-theme-fontawesome-stars"><select className="ps-rating" data-read-only="false" style={{display: 'none'}}>
                                                        <option value="0">0</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                    </select>
                                                 <div className="br-widget">
                                                    <a onClick={()=>setNote(1)} data-rating-value="1" data-rating-text="1" className={(note>0 ? 'br-selected br-current' : '')}>
                                                    </a><a onClick={()=>setNote(2)} data-rating-value="2" data-rating-text="2" className={(note>1 ? 'br-selected br-current' : '')}>
                                                    </a><a onClick={()=>setNote(3)} data-rating-value="3" data-rating-text="3" className={(note>2 ? 'br-selected br-current' : '')}>
                                                    </a><a onClick={()=>setNote(4)} data-rating-value="4" data-rating-text="4" className={(note>3 ? 'br-selected br-current' : '')}>
                                                    </a><a onClick={()=>setNote(5)} data-rating-value="5" data-rating-text="5" className={(note>4 ? 'br-selected br-current' : '')}>
                                                    </a><div className="br-current-rating">
                                                    </div>

                                                    </div></div>
                                                </div>
                                 <p>

                               



                                   <div className="row" >
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12  ">
                                                        <div className="form-group" style={{    marginBottom: '13px'}}>
                                                            <input style={{borderRadius: '39px',height: '37px'}} defaultValue={user?.api_token ? user.name:""} name="nom" className="form-control" type="text" placeholder="Votre Nom" />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12  ">
                                                        <div className="form-group" style={{    marginBottom: '13px'}}>
                                                            <input style={{borderRadius: '39px',height: '37px'}} defaultValue={user?.api_token ? user.email:""} name="email"  className="form-control" type="email" placeholder="Votre Email" />
                                                        </div>
                                                    </div>
                                  </div>
                                   <div className="form-group" style={{position:'relative'}}>
                                          <textarea id='comment2' name="comment" className="form-control" rows="1" placeholder="Écrivez Un Commentaire.." style={{borderRadius: '31px'}}></textarea>
                                          <div className='addcomment'><button type='submit'><i className='fa fa-send'></i></button></div>
                                  </div>
                                 </p>
                          
                          </form>
                                


                                  {comments?.map((item,key) => (  

                                    key==0 &&

                                    <div className='listcomments'>


                                   <div className='itemimg'><img src='/images/photoprofil.png' /></div>
                                    <div className='itemmessage'>{item.comment}</div>
                                    <div className='itemaction'>

                                    
                                       <a href='#' style={{display:'none'}}> <img src='/images/jaime.png' /><span> 20</span></a>
                                       <a href='#' style={{display:'none'}}> <img src='/images/jaimepas.png' /><span> 6</span></a>

                                    
 
                                    </div>
 
                                  </div>


                                  ))}

                                  {

                                    allc==1 &&

                                    comments?.map((item,key) => (  

                                    key!=0 &&
 
                                    <div className='listcomments'>


                                   <div className='itemimg'><img src='/images/photoprofil.png' /></div>
                                    <div className='itemmessage'>{item.comment}</div>
                                    <div className='itemaction'>

                                    
                                       <a href='#' style={{display:'none'}}> <img src='/images/jaime.png' /><span> 20</span></a>
                                       <a href='#' style={{display:'none'}}> <img src='/images/jaimepas.png' /><span> 6</span></a>

                                    
 
                                    </div>
 
                                  </div>


                                  ))

                                  }

                           

                                      {
                                        allc==0 && 
                                        <a href='javascript:void(0)' className='showallcomments' onClick={()=>setAllc(1)}  >Voir tous les commentaires</a>
                                      }
                              

                                 
                            </div>

                             {
                                  product?.vendeur != null  && 


                            <div className='item '>
                                    <div className='ps-product__boutique'>

                                      <span>{product?.boutique?.nomboutique}</span> 
                                      <a className="ps-btn ps-btn--black" href="javascript:void(0)" onClick={()=>handleGoTo(`/boutiques/${product?.boutique?.nomboutique}/${product?.vendeur}/${product?.categorie}`)} >Visiter la boutique</a>
                                      <span><img style={{width: '27px',marginLeft: '10px'}} src='/images/icoboutique.png' /></span>
                                    </div>
                            </div>
                          }


                       </div>







 
                    </div>
                       
                    </Col>
                </Row>
            </Container>


             <section className='section4'>
              <Container>
               <div className="section-title text-center mb-5">
                            <h2 className="border-left-right-btm">Articles similaires </h2>
                           </div>
                

                   <Row className="g-5" >

                    
                       <Col md={12} >
                          <Slider {...settingsSmo}>

                             {product?.related_products?.map((item,key) => (
                              <div><ProductItem item={item}  key={key} /></div> 

                             ))}
                            

                          </Slider>
                                 
                       </Col>
                      
                   </Row>


 <Modal show={showmodal} onHide={handleClose} centered>
        <Modal.Header closeButton>
           
        </Modal.Header>
        <Modal.Body>

        <div className='center'>
          <img style={{width: '96px'}} src='/images/valide.png' />
        </div>
        <br/><br/>
         <div className='center'> Votre produit a bien été ajouté au panier.</div>



        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button style={{color:'#FFF'}} className='ps-btn ps-btn--black'   onClick={goToCart}>
           Voir panier
          </Button>
        </Modal.Footer>
 </Modal>



    
    <Modal show={showmodalvideo} onHide={handleClose2} centered>
        <Modal.Header closeButton>
           
        </Modal.Header>
        <Modal.Body>

        <div style={{height:'100%', width:'100%'}}>

                <video style={{width:'100%',height:'100%'}} autoPlay={true} controls={true} ><source src={product.video} type="video/mp4"/></video>

        </div>


    

        </Modal.Body>
     
    </Modal>
       



       <Modal show={show} onHide={handleCloseshow} className='modalpublish'>
        <Modal.Header   >
           <span className='mop1'>
            {
              showtype==0 ? 'Acheter le produit' : 'Négocier le prix'
            }
           </span>
          <CloseButton variant="black" onClick={handleCloseshow} />
        </Modal.Header>
        <Modal.Body>

        {
          showtype==1 &&

           <div className='marg5'>
           <h5 className='mop1'>Votre offre</h5>
           <p style={{fontSize:'13px',    lineHeight: '16px'}} className='mop2'>Vous pouvez négocier le prix en proposant un prix  plus bas que celui demandé par le vendeur. Attention, si le vendeur accepte votre offre, vous ne pourrez plus annuler.</p>
         </div>

        }
         
        
        <form  onSubmit={handleSubmitVentout}  >
          

          <div style={{display:'none'}}> <input type="text" className="form-control" placeholder='' name='type' value={showtype} /></div>
          <div className='marg6'>
           <label><strong>Identité</strong></label>
           <input type="text" className="form-control" placeholder='' name='identite' required defaultValue={user?.name} />
         </div>
         {

           showtype==1 &&

          <div className='marg6'>
           <label><strong>Proposer un prix</strong></label>
           <input type="number" className="form-control" placeholder='' name='prix' />
         </div>


         }

         <div className='marg6'>
           <label><strong>Adresse de livraison</strong></label>
           <input type="text" className="form-control" placeholder='Rentrez ici l'adresse name='adresse' required />
         </div>

          <div className='marg6'>
           <label><strong>Informations complémentaires</strong></label>
           <input type="text" className="form-control" placeholder='Ex: à coté de la pharmacie..' name='informations' />
         </div>

          <div className='marg6'>
           <label><strong>Numéro de téléphone</strong></label>
           <input type="text" className="form-control" placeholder='N° de téléphone' name='numtel' required />
         </div>
          <div className='marg6'>
           <label><strong>Numéro Whatsapp</strong></label>
           <input type="text" className="form-control" placeholder='N° de téléphone (Whatsapp)' name='numwathsap' required />
         </div>

          <div className='marg6'>
  
           <div className='photosblock'>
               <div className='item it889'>
                          <p className='psr'>Service livraison et paiement assurés par Mixed Mode.</p>
               </div>

           </div>
         </div>
 <div className='marg6'>

 {
  showtype==0 ?

    <button type='submit' className="ps-btn ps-btn--black btn88900"    > Confirmer</button>

  :

   <button type='submit'  className="ps-btn ps-btn--black btn88900"   > Négocier
            </button>

 }
</div>


  </form>
 


        </Modal.Body>
        <Modal.Footer>


        
        
        </Modal.Footer>
      </Modal>



      <Modal show={showmodalP} onHide={handleCloseP} centered>
        <Modal.Header closeButton>
           
        </Modal.Header>
        <Modal.Body>

        <p style={{textAlign:'center',marginBottom: '20px'}}> <img style={{width: '100px'}} src='/images/valide.png' /></p>
       
        <p style={{textAlign:'center'}}> Votre demande  a bien été enregistrée, elle sera traitée dans les meilleurs délais.</p>

         </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseP}>
            Close
          </Button>
          <Button style={{color:'#FFF'}} className='ps-btn ps-btn--black'   onClick={()=>history.push(`/commandes`)}>
           Mon profil
          </Button>
        </Modal.Footer>
     </Modal>


         <Modal show={showAlert} onHide={handleCloseAlert} centered>
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
          {alertMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAlert} style={{background: '#000'}}>
            Fermer
          </Button>
          
        </Modal.Footer>
</Modal>

                 
              </Container>
            </section>

        </div>
    
     
  
     
    );
}


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default Page;