import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Alert, Button,Nav,ListGroup,ListGroupItem,Card,Table} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { ProductItem, RestaurentItem } from "../../../components";
import { updateInfoUser,getOneCommandeById,UpdateUserAddresse,UpdateUserAddresseInf} from "../../../actions/user";
import "react-datepicker/dist/react-datepicker.css";
import {  getRestaurentById } from "../../../actions/restaurent";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { apiClient } from "../../../actions/api"
import { markerHome,defaultimg } from "../../../assets/images";
import { MenuVendeur } from "../../../components";
import { SET_CURRENT_COMMANDE} from "../../../constants/actions";
import moment from 'moment'
import GoogleMapReact from 'google-map-react';
import Slider from "react-slick";


const Page = () => {
    const { id } = useParams()
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { commandeinfo,currentRestau } = useSelector(state => state.restaurent)
    const [latitudeliv,setLatitudeliv]=useState(47.7198437);
    const [langitudelivreur,setLangitudelivreur]=useState(-1.3211272);
     const [detailcommande,setDetailcommande]=useState([]);



    useEffect(() => {

       let paramsd={
              datelivraison:id,
              user_id:user.user_id,
              token:user.api_token,
              api_token:user.api_token,
              type:'vendeursuivilivraison'
           };
           
           apiClient().post(`/getdetailcommande`,paramsd)
           .then((res) => {
            console.log('ahmed')
         console.log(res.data)
             setDetailcommande(res.data.commande)
             //dispatch({type:SET_CURRENT_COMMANDE,payload:res.data.commande})
           
           }) 

 
    }, []);
 
   
const setstatuscommande =  (status) => {
    
        let paramsd={
              id_order:id,
              user_id:user.user_id,
              token:user.api_token,
              api_token:user.api_token,
              status:status,
           };
           
           apiClient().post(`/setstatusorder`,paramsd)
           .then((res) => {
         
             setDetailcommande(res.data.commande)
             dispatch({type:SET_CURRENT_COMMANDE,payload:res.data.commande})
           
           })

}

const getRetours =  (detail) => {
      let retour=0;
       for(let i=0; i < detail.length; i++){

           if(detail[i]?.retour==1){
              retour=retour+1;
           }

      }

      return retour;

}

const getLivre =  (detail) => {
      let retour=0;
       for(let i=0; i < detail.length; i++){

              retour= retour + (detail[i]?.qterecupere - detail[i]?.qteretour);

      }

      return retour;

}


const getValeurs =  (detail) => {
      let valeur=0;
       for(let i=0; i < detail.length; i++){

          
              valeur=valeur+(parseInt(detail[i].qterecupere - detail[i].qterecupere) * parseFloat(detail[i].product_price) );
           

      }

      return valeur;

}


    var settingsSlide = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide:0,
     responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
             infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            
            slidesToShow: 1,
            slidesToScroll: 1,
             infinite: true,
            dots: false
          }
        }
      ]
   };

    var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide:0,
     responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
             infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
             infinite: true,
            dots: false
          }
        }
      ]
   };



    return (
        <div className="register detailcmd suivilivraison" >
            <Container>
                <Row>
            <Col  className="text-center mx-auto my-5 froweb98"  lg="3">

                  <MenuVendeur slug='suivilivraison' />
               
                </Col>
              
                    <Col  className="text-center mx-auto my-5"  lg='8' >
                        
                  <form className="mb-5">
                     
 
                    
<br/>
<Row className="g-5" >
                 
     <Col md={12}  >

 
 <Row>
 
 <Col md={12}>

  <div className='detcommande'>
     
        <div className='items'>
                            
                              <div className='rowitmtop'>
                                   <span> {moment(new Date(commandeinfo.datelivraison)).format("DD MMM YYYY")}</span>
                                     
                                </div>

                                <div className='process-item'>

                                   
                                     
                                  <div className='rowsitm'>
                                      <div> <span>- Colis récupérées: </span></div>
                                       <div><b>{parseInt(commandeinfo.nbcolirec)}</b></div>
                                       
                                    </div>
                                     <div className='rowsitm'>
                                      <div> <span>- Valeurs des colis: </span></div>
                                       <div><b>{parseFloat(commandeinfo.total).toFixed(2)} CFA</b></div>
                                       
                                    </div>
                                    <div className='rowsitm'>
                                      <div> <span>- Colis retour: </span></div>
                                       <div><b>{parseInt(commandeinfo.nbcoliretour)}</b></div>
                                       
                                    </div>

                                 </div>
                             
       </div>

 </div>

  

<div class="col-md-12"><h4>Détails </h4><div><img src="/images/fleshtobtm.png" style={{width: '30px'}} /></div></div>
  
    <Slider {...settingsSlide}>
          
             {detailcommande?.map((item) => (

    
<div className='tabsitems'>

   <div className='items '>
                            
                                <div className='rowitmtop'>
                                   <span>Catégorie</span>
                                    <div>{
                                 item.type_commande=='1' ? 'Fashion Style' : item.type_commande=='2' ? 'Afro Création' :item.type_commande=='3' ? 'Ventout' : 'Sur commande' 
                              }</div>
                                </div>



           <Table striped bordered hover >
            
              <tbody>
            <tr>
            
            <td className='left' style={{fontWeight:'bold'}}>
               Numéro de commande
            </td>
             <td>
              
                   Réf: {item?.track_code}
                       
            </td>
            </tr> 
            <tr>
            
            <td className='left' style={{fontWeight:'bold'}}>
               Date livraison
            </td>
             <td>
              
              
                    {moment(new Date(item?.datelivraison)).format("DD MMM YYYY")}
                       
            </td>
            </tr>

            <tr>

            <td className='left' style={{fontWeight:'bold'}}>
               Nombre d'articles
            </td>
             <td>
              
                   {item?.details_order?.length}
                       
            </td>
            </tr>



   <tr>
            <td className='left' style={{fontWeight:'bold'}}>
               Valeur
            </td>
             <td>
              
                      {getValeurs(item?.details_order).toFixed(2) } CFA
            </td>
    </tr>
   <tr>
            <td className='left' style={{fontWeight:'bold'}}>
  Statut
            </td>
             <td >
              
                           {item.order_status==0 && <div className="badge  alert-warning"  >
                              En attente
                            </div>}
                         {item.order_status==1 && <div className="badge alert-success"  >
                               Traité
                            </div>}
                        {item.order_status==2 && <div className="badge alert-danger"  > Annulé</div>}
                        {item.order_status==3 && <div className="badge alert-success"  > Livrée</div>}
                        {item.order_status==4 && <div className="badge alert-success"  > En cours de livraison</div>}
                        {item.order_status==5 && <div className="badge alert-success"  > Validée</div>}
                        {item.order_status==6 && <div className="badge alert-danger"  > Incomplet</div>}
                        {item.order_status==7 && <div className="badge alert-danger"  > Refusée</div>}
            </td>
    </tr>

 <tr>

 


<td className='left' style={{fontWeight:'bold'}}>
Retour lors de la livraison
            </td>
             <td>
               {getRetours(item?.details_order)}
            </td>
</tr>

<tr>
<td className='left' style={{fontWeight:'bold'}}>
Nombre de produits livrés
            </td>
             <td>
               {getLivre(item?.details_order)}
            </td>
</tr>



   <tr>
            <td className='left' style={{fontWeight:'bold'}}>
 Votre client a payé 
            </td>
             <td>

              {
                item.order_status==3 ? (parseFloat(item.total_price) - parseFloat(item.fraislivraison)).toFixed(2) + 'CFA' :  ' 0 CFA'
              }
              
                   
            </td>
  </tr>

     <tr>
            <td colspan="2">
                 <h5>Mixed mode express a récupéré </h5>

                  {item?.details_order?.map((item1) => ( 

                   item1.recupere==1 &&
                        <span style={{paddingRight:'10px'}}>{item1?.qterecupere} {item1?.pro_name}</span>


                  ))}
                
            </td>
       
    </tr>

         <tr>
            <td colspan="2">
                 <h5 className='red'>Mixed mode express a retourné </h5>
                 {item?.details_order?.map((item1) => ( 

                   item1.livre==0 &&
                        <span style={{paddingRight:'10px'}}>{item1?.qterecupere} {item1?.pro_name}</span>


                  ))}
            </td>
       
    </tr>

      <tr>
            <td colspan="2">
                 <h5  className='red'>Motif</h5>
                 <p>
                    {item?.motif}
                 </p>
            </td>
       
    </tr>



         </tbody>
</Table> 

         
                             
   </div>
</div>

))}               
                            

    </Slider>


 
  </Col>


</Row>
                   
  </Col>
                  
</Row>

<Row className="mb-3"  >
 <Col>
 
 </Col>

</Row>

 

 


 {
  /*

  <Row className="mb-3"  >
                           
                           <Col>
                           {commandeinfo.order?.state_id==10  && <Button className="w-100" variant="primary" type="submit">
                               En cours
                            </Button>}
                          
                            {commandeinfo.order?.state_id==20  && <Button className="w-100" variant="success" type="submit">
                              Validée
                            </Button>}
                             {commandeinfo.order?.state_id==40  && <Button className="w-100" variant="danger" type="submit">
                             Annulée
                            </Button>}
                         {commandeinfo.order?.state_id==30  && <Button className="w-100" variant="primary" type="submit">
                              Livrée
                            </Button>}
                          
                           </Col>
                        
                          </Row>
<Row className="g-5" >
                    <Col md={6}  >
                        <RestaurentItem item={currentRestau.establishment} />
                    </Col>
                  <Col md={6}  >
                  <h3>Menu</h3>
                         <Table striped bordered hover variant="dark">
                          <thead>
                            <tr>
                                <th>Désignation</th>
                                <th key="0">Prix</th>
                            
                             
                            </tr>
                          </thead>
              <tbody>
                      {commandeinfo.order?.products?.map((paniers) => (
                       <tr>
                        <td>{paniers._joinData.quantity}x{paniers.title}</td> <td>€{paniers._joinData.price.toFixed(2)}</td>
                         </tr>
                      
                    
                        

                        ))}

            <tr>
            <td>
 Prix livraison
            </td>
             <td>
              
                    {
                      commandeinfo?.order?.delivery_price==0 ? 'Gratuit'
                      :
                      '€'+commandeinfo?.order?.delivery_price.toFixed(2)
                    }
            </td>
            </tr>

   <tr>
            <td>
 Total
            </td>
             <td>
              
                      €{commandeinfo.order?.total.toFixed(2)}
            </td>
            </tr>

 <tr>
            <td>
 Mode de paiement
            </td>
             <td>
              
                Paiement par carte bancaire
            </td>
            </tr>

         </tbody>
</Table>
 
                   
                
  
                   

                    </Col>
                  
</Row>

    <Row className="mb-3"  >
    <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key:"AIzaSyBkcUpl985AUArtxvhkoOZokGN5ZdKDykE"}}
          defaultCenter={center}
          defaultZoom={11}
        >
          <AnyReactComponent
            lat={latitudeliv}
            lng={langitudelivreur}
            text="My Marker"
          />
        
        </GoogleMapReact>
        </div>
    
</Row>

  */
 }                       

</form>  
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;