import React, { useState, useEffect} from "react";
import './styles.scss';
import { Container, Row, Col } from "react-bootstrap";
import { getFaq } from "../../actions/mixedmode";
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router";

const Page = () => {

   const dispatch = useDispatch();
   const history = useHistory();
  const { faqs } = useSelector(state => state.mixedmode)
  useEffect(() => {
     dispatch(getFaq())
   }, [])



    return (
    <div>
            <div className="ps-breadcrumb"><div className="container"><div className="ps-container"><ul className="breadcrumb"><li><a href="#" onClick={()=> history.push('/')}>Accueil</a></li><li>FAQ </li></ul></div></div></div>
        <div className="apropos" >

            <Container>
                <Row>
                    <Col className="mx-auto my-5" lg="12">

                       <h1 className="text-center mb-5"> FAQ </h1>
                        <span className="section-separator"></span>
<br/><br/>

 <div className="ps-section__content">
                    <div className="table-responsive">
      <table className="table ps-table--faqs" style={{background: 'none'}}>
      <tbody>
         {faqs?.map((item) => {
                           
                                return (
                                       <tr key={item.id}>
                                         <td className="heading" ><h4>{item.question.fr}</h4></td>
                                         <td> <div dangerouslySetInnerHTML={{ __html:item.reponse.fr }}></div></td>
                                         </tr>
                                );
                            
                        })}
      
      </tbody></table>
                    </div>
                </div>

 
                    </Col>
                </Row>
            </Container>
        </div>
 
    </div>
    );
}

export default Page;