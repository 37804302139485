import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Alert, Button,Nav,ListGroup,ListGroupItem,Card,Table} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { ProductItem, RestaurentItem } from "../../../components";
import { updateInfoUser,getOneCommandeById,UpdateUserAddresse,UpdateUserAddresseInf} from "../../../actions/user";
import "react-datepicker/dist/react-datepicker.css";
import {  getRestaurentById } from "../../../actions/restaurent";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { apiClient } from "../../../actions/api"
import { markerHome,defaultimg } from "../../../assets/images";
import { MenuVendeur } from "../../../components";
import { SET_CURRENT_COMMANDE} from "../../../constants/actions";
import moment from 'moment'
import GoogleMapReact from 'google-map-react';


const Page = () => {
       const { id } = useParams()
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { commandeinfo,currentRestau } = useSelector(state => state.restaurent)
    const [latitudeliv,setLatitudeliv]=useState(47.7198437);
    const [langitudelivreur,setLangitudelivreur]=useState(-1.3211272);
    const [detailcommande,setDetailcommande]=useState({});
    const [motifrefus,setMotifrefus]=useState('');
    const [displaymotif,setDisplaymotif]=useState(false);



    useEffect(() => {

       let paramsd={
              id_order:id,
              user_id:user.user_id,
              token:user.api_token,
              api_token:user.api_token,
              type:'vendeur'
           };
           
           apiClient().post(`/getdetailcommande`,paramsd)
           .then((res) => {
         
             setDetailcommande(res.data.commande)
             dispatch({type:SET_CURRENT_COMMANDE,payload:res.data.commande})
           
           }) 

 
    }, []);
 
   
const setstatuscommande =  (status) => {

  if(status==7){
     if(motifrefus==''){
       alert('Veuillez ajouter le motif du refus !')
       return false;
     }
  }

    
        let paramsd={
              id_order:id,
              user_id:user.user_id,
              token:user.api_token,
              api_token:user.api_token,
              status:status,
              motifrefus:motifrefus
           };
           
           apiClient().post(`/setstatusorder`,paramsd)
           .then((res) => {
         
             setDetailcommande(res.data.commande)
             dispatch({type:SET_CURRENT_COMMANDE,payload:res.data.commande})
           
           })

}

const getRetours =  (detail) => {
      let retour=0;
       for(let i=0; i < detail.length; i++){

           if(detail[i]?.retour==1){
              retour=retour+1;
           }

      }

      return retour;

}

const getLivre =  (detail) => {
      let retour=0;
       for(let i=0; i < detail.length; i++){

           
              retour= retour + (detail[i]?.qterecupere - detail[i]?.qteretour);
           

      }

      return retour;

}


const getValeurs =  (detail) => {
      let valeur=0;
       for(let i=0; i < detail.length; i++){

           
              valeur=valeur+(parseInt(detail[i].qterecupere - detail[i].qteretour) * parseFloat(detail[i].product_price) );
           
           

      }

      return parseFloat(valeur).toFixed(0);

}

const getCommission =  (detail) => {
      let valeur=0;
       for(let i=0; i < detail.length; i++){

           
              valeur=valeur+(parseInt(detail[i].qterecupere - detail[i].qteretour) * parseFloat(detail[i].product_price) );
        
           

      }

      let price= parseFloat(valeur).toFixed(0);
      let percent=0
      let commission= commandeinfo.type_commande =='3' ? 10 : 10;
     if(price>0){
         percent=price - (price * commission / 100) 
        let service=price - percent;
     }

    
     return  parseFloat(percent).toFixed(0);

        

}


 


    return (
        <div className="register detailcmd" >
            <Container>
                <Row>
            <Col  className="text-center mx-auto my-5 froweb98"  lg="3">

                  <MenuVendeur slug='commandes' />
               
                </Col>
              
                    <Col  className="text-center mx-auto my-5"  >
                        
                  <form className="mb-5">
                    <h3 className="mb-4" > Détail Commande Ref:  #{commandeinfo?.track_code}</h3>
                     
    <Row className="mb-3"  >
                           
                           <Col>

                            <Col md={12}  >
                         <h4 style={{color:'#004ab0'}}>
                            <strong>  {
                                 commandeinfo.type_commande=='1' ? 'Fashion Style' : commandeinfo.type_commande=='2' ? 'Afro Création' :commandeinfo.type_commande=='3' ? 'Ventout' : 'Sur commande' 
                              }</strong>
                         </h4>
                  </Col>
                         
                          
                
                          
                           </Col>
                        
                          </Row>

                          {
                             commandeinfo.order_status==0 ?

                           <Row>
                           <Col className='flx990'>
                              <a  href="javascript:void(0)" onClick={()=>setDisplaymotif(!displaymotif)} className="btnrmv btn-danger" data-microtip-position="left" data-tooltip="Modifier">Refuser la commande <i class="icon-remove"></i></a>
                              
                              <a  href="javascript:void(0)"  onClick={()=>setstatuscommande(5)}  className="btnrmv btn-success" data-microtip-position="left" data-tooltip="Modifier">Accepter la commande <i class="icon-remove"></i></a>
                             
                       

                           </Col>

                           {
                            displaymotif && 

                                 <div className='col-md-12'>
                                <p>Veuillez ajouter le motif du refus:</p>
                                 <textarea className='form-control' name='motifrefus' onChange={evt => setMotifrefus(evt.target.value)}>



                                 </textarea>
                                 <br/>
                                  <a  href="javascript:void(0)" onClick={()=>setstatuscommande(7)} className="btnrmv btn-danger" data-microtip-position="left" data-tooltip="Modifier">Envoyer <i class="icon-remove"></i></a>


                             </div>
                           }

                            
                        
                          </Row>

                          :


                          <Row>
                           <Col className='flx990'>


                         {commandeinfo.order_status==1 && <div className="badge alert-success"  >
                               Traité
                            </div>}
                       {commandeinfo.order_status==2 && <div className="badge alert-danger"  > Annulé</div>}
                        {commandeinfo.order_status==3 && <div className="badge alert-info"  > Livrée</div>}
                        {commandeinfo.order_status==4 && <div className="badge alert-secondary"  > En cours de livraison</div>}
                        {commandeinfo.order_status==5 && <div className="badge alert-valider"  > Validée</div>}
                        {commandeinfo.order_status==6 && <div className="badge alert-primary"  > Incomplet</div>}
                        {commandeinfo.order_status==7 && <div className="badge alert-danger2"  > Refusée</div>}
                             
                           </Col>
                        
                          </Row>




                          }
                    
<br/>
<Row className="g-5" >
                 
                  <Col md={12}  >

 
                 
                       


<div className='detcmdmobile' style={{display:'block'}}>


 <div className='item'>

   {detailcommande?.details_order?.map((item) => ( 
       
        <div className='itemrow flswebYYU8'>
      <div className='img'>
         <img src={item?.products?.image_url} title={item.pro_name}/>
      </div>
      <div className='data'>
          <table>
          {
            item.size && 
              <tr><td><span>Taille: </span></td><td><div>{item.size} </div></td></tr>
          }
          {
            item.color && 
              <tr><td><span>Couleur: </span></td><td>
           <div className="_1xuQr" style={{backgroundColor: item.color}}></div>
            </td></tr>
          }
          
           
             <tr><td><span>Quantité: </span></td><td><div>{item.quantity}</div></td></tr>

          </table>
       <strong>{parseFloat(item.product_price).toFixed(2)} CFA</strong>
      </div>
   </div>


  ))}

   




 </div>


</div>


 <br/> <br/>
 <Row>
 
 <Col md={12}>

 
  



<div class="col-md-12"><h4>Détails </h4><div><img src="/images/fleshtobtm.png" style={{width: '30px'}} /></div></div>

<div className='tabsitems'>

   <div className='items '>
                            
                                <div className='rowitmtop'>
                                   <span>Catégorie</span>
                                    <div>{
                                 commandeinfo.type_commande=='1' ? 'Fashion Style' : commandeinfo.type_commande=='2' ? 'Afro Création' :commandeinfo.type_commande=='3' ? 'Ventout' : 'Sur commande' 
                              }</div>
                                </div>



           <Table striped bordered hover >
            
              <tbody>
            <tr>
            
            <td className='left' style={{fontWeight:'bold'}}>
               Numéro de commande
            </td>
             <td>
              
                   Réf: {commandeinfo?.track_code}
                       
            </td>
            </tr> 
            <tr>
            
            <td className='left' style={{fontWeight:'bold'}}>
               Date livraison
            </td>
             <td>
              
              
                    {moment(new Date(commandeinfo?.datelivraison)).format("DD MMM YYYY")}
                       
            </td>
            </tr>

            <tr>

            <td className='left' style={{fontWeight:'bold'}}>
               Nombre d'articles
            </td>
             <td>
              
                   {commandeinfo?.details_order?.length}
                       
            </td>
            </tr>



   <tr>
            <td className='left' style={{fontWeight:'bold'}}>
               Valeur

            </td>
             <td>
              
                      {(parseFloat(commandeinfo.total_price) - parseFloat(commandeinfo.fraislivraison)).toFixed(2) } CFA
            </td>
    </tr>

   <tr>
            <td className='left' style={{fontWeight:'bold'}}>
  Statut
            </td>
             <td >
              
                           {commandeinfo.order_status==0 && <div className="badge  alert-warning"  >
                              En attente
                            </div>}
                         {commandeinfo.order_status==1 && <div className="badge alert-success"  >
                               Traité
                            </div>}
                        {commandeinfo.order_status==2 && <div className="badge alert-danger"  > Annulé</div>}
                        {commandeinfo.order_status==3 && <div className="badge alert-success"  > Livrée</div>}
                        {commandeinfo.order_status==4 && <div className="badge alert-success"  > En cours de livraison</div>}
                        {commandeinfo.order_status==5 && <div className="badge alert-success"  > Validée</div>}
                        {commandeinfo.order_status==6 && <div className="badge alert-danger"  > Incomplet</div>}
                        {commandeinfo.order_status==7 && <div className="badge alert-danger"  > Refusée</div>}
            </td>
    </tr>

 <tr>

 


<td className='left' style={{fontWeight:'bold'}}>
Retour lors de la livraison
            </td>
             <td>
               {getRetours(commandeinfo?.details_order)}
            </td>
            </tr>

  <tr>
<td className='left' style={{fontWeight:'bold'}}>
Nombre de produits livrés
            </td>
             <td>
               {getLivre(commandeinfo?.details_order)}
            </td>
</tr>


   <tr>
            <td className='left' style={{fontWeight:'bold'}}>
 Votre client a payé 
            </td>
             <td>

              {
                   commandeinfo.order_status==1 ? 

            
                      getValeurs(commandeinfo?.details_order) +'CFA'

                    :

                    '-'
              }
              
                   
            </td>
    </tr>

   <tr>
            <td className='left' style={{fontWeight:'bold'}}>
Frais de commission Mixed Mode : {commandeinfo.type_commande=='3'? '-10%' : '-10%' } 
            </td>
             <td>

              {
                   getCommission(commandeinfo?.details_order) +'CFA'
              }
              
                   
            </td>
    </tr>
     <tr>
            <td colspan="2">
                 <h5>Mixed mode express a récupéré </h5>

                  {detailcommande?.details_order?.map((item) => ( 

                   item.recupere==1 &&
                        <span style={{paddingRight:'10px'}}>{item?.qterecupere} {item?.pro_name}</span>


                  ))}
                
            </td>
       
    </tr>

         <tr>
            <td colspan="2">
                 <h5 className='red'>Mixed mode express a retourné </h5>
                 {detailcommande?.details_order?.map((item) => ( 

                   item.retour==1 &&
                        <span style={{paddingRight:'10px'}}>{item?.qteretour} {item?.pro_name}</span>


                  ))}
            </td>
       
    </tr>

      <tr>
            <td colspan="2">
                 <h5  className='red'>Motif</h5>
                 <p>
                    {detailcommande?.motif}
                 </p>
            </td>
       
    </tr>



         </tbody>
</Table> 

         
                             
   </div>
</div>

 
  </Col>


</Row>
                   
  </Col>
                  
</Row>

<Row className="mb-3"  >
 <Col>
 
 </Col>

</Row>

 

 


 {
  /*

  <Row className="mb-3"  >
                           
                           <Col>
                           {commandeinfo.order?.state_id==10  && <Button className="w-100" variant="primary" type="submit">
                               En cours
                            </Button>}
                          
                            {commandeinfo.order?.state_id==20  && <Button className="w-100" variant="success" type="submit">
                              Validée
                            </Button>}
                             {commandeinfo.order?.state_id==40  && <Button className="w-100" variant="danger" type="submit">
                             Annulée
                            </Button>}
                         {commandeinfo.order?.state_id==30  && <Button className="w-100" variant="primary" type="submit">
                              Livrée
                            </Button>}
                          
                           </Col>
                        
                          </Row>
<Row className="g-5" >
                    <Col md={6}  >
                        <RestaurentItem item={currentRestau.establishment} />
                    </Col>
                  <Col md={6}  >
                  <h3>Menu</h3>
                         <Table striped bordered hover variant="dark">
                          <thead>
                            <tr>
                                <th>Désignation</th>
                                <th key="0">Prix</th>
                            
                             
                            </tr>
                          </thead>
              <tbody>
                      {commandeinfo.order?.products?.map((paniers) => (
                       <tr>
                        <td>{paniers._joinData.quantity}x{paniers.title}</td> <td>€{paniers._joinData.price.toFixed(2)}</td>
                         </tr>
                      
                    
                        

                        ))}

            <tr>
            <td>
 Prix livraison
            </td>
             <td>
              
                    {
                      commandeinfo?.order?.delivery_price==0 ? 'Gratuit'
                      :
                      '€'+commandeinfo?.order?.delivery_price.toFixed(2)
                    }
            </td>
            </tr>

   <tr>
            <td>
 Total
            </td>
             <td>
              
                      €{commandeinfo.order?.total.toFixed(2)}
            </td>
            </tr>

 <tr>
            <td>
 Mode de paiement
            </td>
             <td>
              
               
               {
                parseInt(commandeinfo.type_paiement) == 1 ? 'Paiement à la livraison' :  parseInt(commandeinfo.type_paiement) == 2 ? 'Orange Money, Moov Money,MTN Money': parseInt(commandeinfo.type_paiement) == 3 ? 'Wave' : 'Paiement par carte bancaire'
               }
            </td>
            </tr>

         </tbody>
</Table>
 
                   
                
  
                   

                    </Col>
                  
</Row>

    <Row className="mb-3"  >
    <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key:"AIzaSyBkcUpl985AUArtxvhkoOZokGN5ZdKDykE"}}
          defaultCenter={center}
          defaultZoom={11}
        >
          <AnyReactComponent
            lat={latitudeliv}
            lng={langitudelivreur}
            text="My Marker"
          />
        
        </GoogleMapReact>
        </div>
    
</Row>

  */
 }                       

</form>  
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;