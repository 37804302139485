import React,{useEffect} from "react";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { ProductItem } from "../../components";
import { useHistory } from "react-router";
import Carousel from 'react-bootstrap/Carousel'
import Slider from "react-slick";

const Page = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide:0,
     responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
             infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
             infinite: true,
            dots: false
          }
        }
      ]
   };
   

    useEffect(()=>{
     
    },[])

    const handleClick = (slug) => {
        history.push(`/fashion-style/produits/${slug}#`);
    }

    const handleGoTo = (slug) => {
        history.push(slug);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
 
        
    }

    return (
        <div className="categories" >

         <section>
          <div className="ps-breadcrumb"><div className="container"><div className="ps-container"><ul className="breadcrumb"><li><a href="#">Accueil</a></li><li>Fashion Style</li></ul></div></div></div>
         </section>

         <section className='section2'>

           <div className="container">

            <div className=" bigcategories">
                       
                      <div className='item '>
                        <a href='javascript:void(0)' onClick={()=>handleGoTo('/fashion-style')} className='fashion active' ><img src='/images/fashion3.png' /></a>
                        <label>Fashion</label>
                       </div>

                       <div className='item '>
                        <a href='javascript:void(0)'  onClick={()=>handleGoTo('/afro-creation')} className='afrocreation' ><img src='/images/afrecacreation.png' /></a>
                        <label>Afro Creation</label>
                       </div>
                        <div className='item '>
                        <a href='javascript:void(0)' onClick={()=>handleGoTo('/ventout')} className='ventout' ><img src='/images/vendtout.png' /></a>
                         <label>Ventout</label>
                       </div>
                        <div className='item '>
                        <a href='javascript:void(0)' onClick={()=>handleGoTo('/sur-commande/produits/1#')} className='surcommande' ><img src='/images/surcommande.png' /></a>
                         <label>Sur commande</label>
                       </div>
                         
            </div>

           <div className="row">
                        <div className="col-lg-12 cllgsect66">
                           <div className="section-title text-center mb-5">
                            <h2 className="border-left-right-btm">Nos catégories </h2>
                           </div>
                        </div>
            </div>
                    <div className="row">
                        <div className="col-lg-12 cllgsect66">
                            <div className="single-banner fix">
                                <div className="banner-photo padding-0 float-start col-md-6 bgimg6" style={{backgroundImage:"url('/images/fashion1.png')"}}>
                                    <a href="#" onClick={()=>handleClick('Homme')}>

                                      <h2 className='opatitle'> <span> Femme </span></h2>

                                    </a>
                                </div>
                                <div className="banner-brief banner-brief-right col-md-6 bgimg6" style={{backgroundImage:"url('/images/istockphoto-1295801745-612x612.jpg')"}}>
                                   <a href="#"> <h2 className='opatitle'> <span> Enfant </span></h2></a>
                                     
                                     
                                </div>
                            </div>

                            <div className="single-banner fix">
                                <div className="banner-brief banner-brief-left col-md-6 bgimg6" style={{backgroundImage:"url('/images/fashionkids.jpg')"}}>
                                    <a href="#"> <h2 className='opatitle'> <span> Enfant </span></h2></a>
                                </div>
                                <div className="banner-photo padding-0 float-end col-md-6  bgimg6" style={{backgroundImage:"url('/images/fashion2.png')"}}>
                                     <a href="#"> <h2 className='opatitle'> <span> Homme </span></h2></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

         </section>
             
        </div>
    );
}

export default Page;