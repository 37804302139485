import React, { useEffect,useState } from "react";
import './styles.scss';
import { logo } from "../../assets/images";
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../actions/user";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Page = () => {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    
    useEffect(() => {
        if (user.id) {
            history.replace("/")
        }
    }, [user.id])

    const handleSubmit = (slug) => {
         history.push(slug)
    }
    return (
        <section  className=" devenirvendeur">
        <div >
      


                        <Container>

             <div className="row">
                        <div className="col-lg-12">
                           <div className="section-title text-center mb-5">
                            <h2 className="border-left-right-btm">Devenir Vendeur </h2>
                           </div>
                        </div>
            </div>

             <div className="row">
                        <div className='col-lg-3'></div>
                        <div className="col-lg-6">
                          
                            <h2 className="border-left-right-btm center" style={{border:'none', fontSize:'17px'}}>Dans quel secteur souhaitez-vous vendre ?</h2>
                          
                        </div>
                        <div className='col-lg-3'></div>
              </div>


                <Row>

                  <Col lg={{ span: 5 }} className="text-center mx-auto my-3" >
                       <Form className="mb-5"  >

                      

                    <div className='item '>
                        <a href='#' className='fashion '><img src='/images/fashionrond.jpg' /></a>
                     
                       </div>

                    
                            <h3 className="mb-4" >FASHION</h3>
                       
                         <br/><br/>
                         <Row className="mb-3">
                         <p>
                          Fashion Style est dédiée aux boutiques de mode chic et tendance. Découverez les nouveaux styles tendances et trouvez votre style en un clic !
                         </p>
                         </Row>
                         <a href='javascript:void(0)' onClick={()=>handleSubmit('/vendeur/inscription/fashion')} className="ps-btn ps-btn--act"  >
                              Inscrivez votre boutique ici
                            </a>

                        </Form>
                  </Col>

                    <Col lg={{ span: 5 }} className="text-center mx-auto my-3" >
                        
                        <Form className="mb-5"  >

                               <div className='item '>
                                <a href='javascript:void(0)' className='afrocreation' ><img src='/images/afrocreationrond.jpg' /></a>
                                 
                               </div>
                            <h3 className="mb-4" >AFRO CREATION</h3>
                       
                             <br/><br/>
                            <Row className="mb-3">
                                <p>
                                   AfroCréation est dédiée aux créateurs Africains.Parcourez les boutiques des meilleurs créateurs africains et distinguez-vous en un clic ! 


                                </p>
                            </Row>
                             
                        
                          
                       
                            <a href='javascript:void(0)' className="ps-btn ps-btn--act " onClick={()=>handleSubmit('/vendeur/inscription/afrocreation')}  >
                              Inscrivez votre boutique ici
                            </a>
                        </Form>
                         
                    </Col>
                </Row>
            </Container>


      
        

        </div>
          </section>
    );
}

export default Page;