import React, { useState } from "react";
import './styles.scss';
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
const Page = () => {
 const { id } = useParams()
  const history = useHistory();

    return (
    <div>
        <div className="ps-breadcrumb"><div className="container"><div className="ps-container"><ul className="breadcrumb"><li><a href="#">Accueil</a></li><li>Confirmation </li></ul></div></div></div>
        <div className="quisommesnous" >

            <Container>
                <Row>
                    <Col className="mx-auto my-5" lg="12">

                        <p className='center'> <img src='/images/validation-150x150.png' /></p>

                        <h1 className="text-center mb-5">Merci pour votre commande sur Mixed Mode ! </h1>
                        <br/>
                         <p className='center'> Votre commande n° {id} sera traitée dans les meilleurs délais. </p>
                        <span className="section-separator"></span>
                        <br/><br/>

                        <div className="form-group submit center"><button onClick={()=> history.push("/client/dashboard")} className="ps-btn">Voir mes commandes</button></div>

                           

 
                    </Col>
                </Row>




            </Container>
        </div>
        <div className="ps-contact-form" style={{paddingTop:'50px',paddingBottom:'50px'}}>
            <div className="container">


           
            </div>
        </div>
<br/><br/>
   
    </div>
    );
}

export default Page;