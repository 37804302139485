import { AUTHENTICATE_USER ,SET_COUNT,SET_LIST_OFFRES,SET_LIST_WALETTE,SET_LIST_STORIES,PRODUCT_LIKED,PRODUCT_FAVORIS,IS_SUBMITED,SET_LIST_RECLAMATIONS,SET_LIST_COMMENTS,SET_CURRENT_RESTAURENT,SET_USER,SET_LIST_ADDRESS,SET_LIST_COMMANDES,SET_CURRENT_COMMANDE,SET_LIST_PRODUCT} from "../constants/actions"
import { apiClient } from "./api"
 


export const registerUser = (params,type) => dispatch => {
 
    const data = {
        ...params,
        type:type
    }

   

    return apiClient().post(`/register`, data)
        .then((res) => {
         
            if(res.data.success=="no"){
                alert("Ce email déja existe !")
            }else{
              dispatch({ type: IS_SUBMITED, payload: true })
                
            }
        })
}


export const updateInfoUser = (params,iduser,token,type) => dispatch => {
    const data = {
        ...params,
        api_token:token,
        token:token,
        user_id:iduser,
        type:type,
    }
    return apiClient().post(`/updateUser`, data)
        .then((res) => {
          
            if(res.data.result?.error){
                alert(res.data.result?.message)
            }else{

                dispatch({ type: AUTHENTICATE_USER, payload: res.data.customer })
                alert("Modification effectuée");
                //window.location="/login"
            }
        })
}
export const loginUser = (params,type) => dispatch => {

       const data = {
        ...params,
         type:type
    }

    return apiClient().post(`/login`, data)
        .then((res) => {
            
               if(res.data.success=="no"){
                alert("Email ou mot de passe incorrect.")
            }else{
                dispatch({ type: AUTHENTICATE_USER, payload: res.data.user })
            }
        })
}
export const getInfoUserById = (id) => dispatch => {
    return apiClient().get(`/getUser/${id}`)
        .then((res) => {
            dispatch({type:SET_LIST_ADDRESS,payload:res.data})
        })
}

export const getInfoAdresseById = (params) => dispatch => {
    return apiClient().post(`/getaddress`,params)
        .then((res) => {
            dispatch({type:SET_LIST_ADDRESS,payload:res.data.address})
        })
}


export const UpdateUserAddresse = (params) => dispatch => {
  const data = {params}
    return apiClient().post(`/addresses`, params)
        .then((res) => {
            console.log("registerUser res", res.data)
            if(res.data.result?.error){
                alert(res.data.result?.message)
            }else{
                 dispatch(getInfoAdresseById(params));
                 alert("Modification effectuée");
            }
        })
}
export const UpdateUserAddresseInf = (params) => dispatch => {
    const data = {params}
    return apiClient().post(`/updateAdresse`, params)
        .then((res) => {
            console.log("registerUser res", res.data)
            if(res.data.result?.error){
                alert(res.data.result?.message)
            }else{
                 dispatch(getInfoUserById(params.customer_id));
                alert("Modification effectuée");
            }
        })
}

export const getCommandeById = (id,token,type,data) => dispatch => {
   const params = {
        ...data,
        user_id:id,
        token:token,
        api_token:token,
        type:type
        };
    return apiClient().post(`/getcommande`,params)
        .then((res) => {
            dispatch({type:SET_LIST_COMMANDES,payload:res.data.commandes})
        })
}

export const getcommentsvendeur = (id,token,data) => dispatch => {
   const params = {
        ...data,
        user_id:id,
        token:token,
        api_token:token
        };
    return apiClient().post(`/listcommentsvendeur`,params)
        .then((res) => {
            dispatch({type:SET_LIST_COMMENTS,payload:res.data.comments})
        })
}

export const getOneCommandeById = (id) => dispatch => {
   const params = {
        id_order:id
        };
    return apiClient().post(`/getcommandebyid`, params)
        .then((res) => {
             dispatch({type:SET_CURRENT_COMMANDE,payload:res.data})
            dispatch(getRestaurentById(res.data.order?.establishment_id))
          
        })
}
export const getRestaurentById = (id) => dispatch => {
    return apiClient().get(`/products/${id}`)
        .then((res) => {
            console.log("res", res.data)
            dispatch({type:SET_CURRENT_RESTAURENT,payload:res.data})
        })
}

export const checkliked = (data) => dispatch => {
    return apiClient().post(`/checkfavorit`, data)
        .then((res) => {
            console.log("registerUser res", res.data)
                dispatch({ type: PRODUCT_LIKED, payload: res.data.success })
        })
}
export const addtofavorit = (data) => dispatch => {
    return apiClient().post(`/favoritePost`, data)
        .then((res) => {
                dispatch({ type: PRODUCT_LIKED, payload: true })
        })
}
export const deltetofavorit = (data) => dispatch => {
    return apiClient().post(`/unFavoritePost`, data)
        .then((res) => {
                dispatch({ type: PRODUCT_LIKED, payload: false })
        })
}

export const getmesfavoris = (data) => dispatch => {
    return apiClient().post(`/listfavoris`, data)
        .then((res) => {
                dispatch({ type: PRODUCT_FAVORIS, payload: res.data.myFavorites })
        })
}

export const getReclamationsById = (id,token,type) => dispatch => {
   const params = {
        user_id:id,
        token:token,
        api_token:token,
        type:type,
        };

    return apiClient().post(`/getreclamations`,params)
        .then((res) => {
            dispatch({type:SET_LIST_RECLAMATIONS,payload:res.data.reclamations})
        })
}

export const addreclamation = (params) => dispatch => {
    return apiClient().post(`/addreclamation`, params)
        .then((res) => {
            dispatch(getReclamationsById(params.user_id,params.api_token,params.type))
            
        })
}
export const deletereclamation = (params) => dispatch => {
    return apiClient().post(`/deletereclamation`, params)
        .then((res) => {
            dispatch(getReclamationsById(params.user_id,params.api_token,params.type))
            
        })
}

export const getlistproducts = (id,token,type,data,currentPage=1) => dispatch => {

   const params = {
        ...data,
        user_id:id,
        token:token,
        api_token:token,
        type:type
        };
    return apiClient().post(`/getlistproduits?page=`+currentPage,params)
        .then((res) => {
            dispatch({type:SET_LIST_PRODUCT,payload:res.data.products})
            dispatch({type:SET_COUNT,payload:res.data.products.last_page})
        })
}

export const getlistOffres = (id,token,type,data) => dispatch => {
 
   const params = {
        ...data,
        user_id:id,
        token:token,
        api_token:token,
        type:type
        };
    return apiClient().post(`/getlistoffres`,params)
        .then((res) => {
            dispatch({type:SET_LIST_OFFRES,payload:res.data.commandes})
        })
}



export const getWalette = (id,token,type,data) => dispatch => {
   const params = {
        ...data,
        user_id:id,
        token:token,
        api_token:token,
        type:type
        };
    return apiClient().post(`/walettes`,params)
        .then((res) => {
            dispatch({type:SET_LIST_WALETTE,payload:res.data.walette})
        })
}
export const getStories = (id,token,type,data) => dispatch => {
   const params = {
        ...data,
        user_id:id,
        token:token,
        api_token:token,
        type:type
        };
    return apiClient().post(`/mystories`,params)
        .then((res) => {
            dispatch({type:SET_LIST_STORIES,payload:res.data.stories})
        })
}

export const forgetPassword = (params) => dispatch => {
    return apiClient().post(`/forgetpassword`, params)
        .then((res) => {
            if(res.data.success=="no"){
                alert("Merci de verifier votre email.")
            }else{
                 alert("Un email a été envoyé avec le nouveau mot de passe");
            } 
        })
}


