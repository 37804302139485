import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateInfoUser,getInfoUserById,UpdateUserAddresse,UpdateUserAddresseInf,getInfoAdresseById} from "../../../actions/user";
import "react-datepicker/dist/react-datepicker.css";
import { geocodeByAddress,getLatLng }  from 'react-google-places-autocomplete';

import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import PlacesAutocomplete from 'react-places-autocomplete';
import { defaultimg } from "../../../assets/images";
import { Menu } from "../../../components";
const Page = () => {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { addresseslist } = useSelector(state => state.restaurent)
    const [nouvelleadd,setNouvelleadd]=useState("no");
    const [nouvelleadd1,setNouvelleadd1]=useState("no");
    const [address,setAddress]=useState("");
    const [city,setCity]=useState("");
    const [residence,setResidence]=useState("");
    const [floor,setFloor]=useState("");
    const [value, setValue] = useState(null);
    const [codep,setCodep]=useState("");
    const [coded,setCoded]=useState("");
    const [idadd,setIdadd]=useState("");
    const [building,setBuilding]=useState("");
    const [appartement,setAppartement]=useState("");
    const [lat,setLat]=useState("");
    const [lng,setLng]=useState("");
    const [startDate, setStartDate] = useState(new Date(user.date_naissance))
    useEffect(() => {
        dispatch(getInfoAdresseById({user_id:user.user_id,token:user.api_token,api_token:user.api_token}));
        navigator.geolocation.getCurrentPosition(function(position) {
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);
          });
    }, []);
   const handleClick = () => setNouvelleadd("yes")
   const handleClick1 = () => setNouvelleadd1("yes")
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        formDataObj.user_id=user.user_id;
        formDataObj.api_token=user.api_token;
        formDataObj.token=user.api_token;
        formDataObj.type='client';
        dispatch(UpdateUserAddresse(formDataObj));
    }
    const handleSubmit1 = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        formDataObj.customer_id=user.id;
        formDataObj.type=2;
        formDataObj.id=idadd;
        formDataObj.active=true;
        if(idadd==""){
        formDataObj.title='Adresse #'+ (addresseslist.addresses.length + 1);
        }
        formDataObj.full_name=user.lastname+' '+user.firstname;
         const infoUser = {
        customer_id: user.id,
        postal_code:44110,
        address:formDataObj
        };
            setNouvelleadd1("no");
            setNouvelleadd("no");
            if(idadd==""){
            dispatch(UpdateUserAddresse(infoUser));
            }else{
             const infoUser = {
                    id:idadd,
                    postal_code:44110,
                    address:formDataObj
                    };
            dispatch(UpdateUserAddresseInf(infoUser));
             setIdadd("");
            }


    }
    function alertClicked(item) {
      console.log(item);
      setAddress(item.address);
      setLat(item.lat);
      setLng(item.lng);
  
      setCity(item.city);
      setResidence(item.residence);
      setFloor(item.floor);
      setCodep(item.code_portal);
      setCoded(item.code_doorbell);
      setIdadd(item.id);
      setNouvelleadd("yes");
     }
    function alertClicked2(item) {
      console.log(item);
      setAddress(item.address);
   
      setLat(item.lat);
      setLng(item.lng);
      setCity(item.city);
      setResidence(item.residence);
      setFloor(item.floor);
      setCodep(item.code_portal);
      setCoded(item.code_doorbell);
      setIdadd(item.id);
      setBuilding(item.building);
      setAppartement(item.apartment);
      setNouvelleadd1("yes");
     }
     const handleChange = address => {
        setAddress(address);
      };
     const selectValuemap=(value)=>{
         console.log(value)
        setAddress(value);
        setValue(value);
         geocodeByAddress(value)
         .then(results => getLatLng(results[0]))
         .then(({ lat, lng }) =>{
            setLat(lat);
            setLng(lng);
         }
         );

     }
     const onChangeaddress = (text) => {
       
     }
     const renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions,loading }) => (
        <div className="autocomplete-root">
          <input {...getInputProps({
              placeholder: 'Adresse ...',
              className: 'form-control',
          })} />
          <div className="autocomplete-dropdown-container my-2">
            {loading && <div>Loading...</div>}
            {suggestions.map(suggestion => (
            
              <div className=" mb-2  listitem" {...getSuggestionItemProps(suggestion)}>
                <span >{suggestion.description}</span>
              </div>
            ))}
          </div>
        </div>
      );
    return (
        <div className="register adresses" >
            <Container>
                <Row>
            <Col  className="mx-auto my-5"  lg="3">

              <Menu slug="adresses" />
               
                </Col>
              
                    <Col  className=" mx-auto my-5"  >
                        
                       <Form className="mb-5" onSubmit={handleSubmit} >
                      <h3 className="mb-4" ><BiMap color={"#000"} size={20} /> Adresse de livraison</h3>
                      <br/><br/>
                        <Row className="mb-3">
                               
                                  <Row >

                       <Col md={6} >
                            <div className="form-group">
                                        <label>Prénom<sup>*</sup>
                                        </label>
                                        <div className="form-group__content">
                                            <input
                                              defaultValue={addresseslist?.name}
                                             className="form-control" name="name" type="text" required/>
                                        </div>
                                    </div>
                       </Col>

                       <Col md={6} >
                            <div className="form-group">
                                        <label>Nom<sup>*</sup>
                                        </label>
                                        <div className="form-group__content">
                                            <input
                                              defaultValue={addresseslist?.surname}
                                             className="form-control" name="surname" type="text" required/>
                                        </div>
                                    </div>
                       </Col>

                   </Row>

                    <Row >

                       <Col md={6} >
                              <div className="form-group">
                                        <label>Ville<sup>*</sup>
                                        </label>
                                        <div className="form-group__content">
                                            <input
                                              defaultValue={addresseslist?.city}
                                             name="city"
                                             className="form-control" type="text" required/>
                                        </div>
                                    </div>
                       </Col>

                   
                        <Col md={6} >
                            <div className="form-group">
                                        <label>Pays<sup>*</sup>
                                        </label>
                                        <div className="form-group__content">
                                            <input 
                                              defaultValue={addresseslist?.state}
                                              name="state"
                                            className="form-control" type="text" required/>
                                        </div>
                                    </div>
                       </Col>
                   </Row>

                    <Row >

               



                       <Col md={6} >
                          <div className="form-group">
                                        <label>Téléphone<sup>*</sup>
                                        </label>
                                        <div className="form-group__content">
                                            <input
                                              defaultValue={addresseslist?.phone_number}
                                            name="phone_number"
                                             className="form-control" type="text" required/>
                                        </div>
                                    </div>
                       </Col>

                           <Col md={6} >
                             <div className="form-group">
                                        <label>Numéro WhatsApp (Pour envoyer votre facture)<sup>*</sup>
                                        </label>
                                        <div className="form-group__content">
                                            <input
                                             name="number"
                                              defaultValue={addresseslist?.number}
                                             className="form-control" type="text" required/>
                                        </div>
                                    </div>
                       </Col>

                   </Row>

                    <Row >

                 

                       <Col md={9} >
                               <div className="form-group">
                                        <label>Adresse<sup>*</sup>
                                        </label>
                                        <div className="form-group__content">
                                            <input 
                                             name="street"
                                              defaultValue={addresseslist?.street}
                                            className="form-control" type="text" required/>
                                        </div>
                                    </div>
                       </Col>
                        <Col md={3} >
                             <div className="form-group">
                                        <label>Code postal<sup>*</sup>
                                        </label>
                                        <div className="form-group__content">
                                            <input
                                             name="postal_code"
                                              defaultValue={addresseslist?.postal_code}
                                             className="form-control" type="text" required/>
                                        </div>
                                    </div>
                       </Col>

                   </Row>
  <Button className="ps-btn ps-btn--fullwidth ps-btn-dark"  type="submit">
                                Modifier
                            </Button>

                        </Row>

                      </Form>


 

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;