import React, { useState, useEffect } from "react";
import {  GET_DATA_CART,GET_TOTAL_CART,OBJECT_REDUCTION,VIDER_PANIER,ADD_EXPIDITION,ADD_MODEEXPIDITION  } from "../../constants/actions";
import './styles.scss';
import { useSelector,useDispatch } from "react-redux"
import { Container, Row, Col, Form, Table, Button, Stack ,Modal,CloseButton } from "react-bootstrap"
import { BasketItem } from "../../components";
import { getInfoUserById,getInfoAdresseById} from "../../actions/user";
import { getRestaurentHoraires} from "../../actions/restaurent";
import { BiPlus } from 'react-icons/bi';
import { apiClient } from "../../actions/api"
import { useHistory } from "react-router-dom";
import moment from 'moment'
import { getSettings} from "../../actions/mixedmode";
import 'moment/locale/fr'


 
const Page = () => {
     
    const { data,total,reduction,expidition,modeexpidition } = useSelector(state => state.basket)
      const { settings } = useSelector(state => state.mixedmode)
    const[state, setState]=useState('');
    const [soustotal, setSoustotal] = useState(0);
    const [bonreduction, setBonreduction] = useState("");
    const [heurelivraison, setHeurelivraison] = useState("");
    const [comment, setComment] = useState("");
    const [asap, setAsap] = useState(false);
     const [fraislivraison, setFraislivraison] = useState(0);
    const [selectadresse, setSelectadresse] = useState(0);
    const [modepaiement, setModepaiement] = useState(0);
    const [transaction_id, setTransaction_id] = useState(0);
    const [listenerpayment, setListenerpayment] = useState(false);
    
    const [valuereduction, setValuereduction] = useState(0);
    const [totalwithoutreduction, setTotalwithoutreduction] = useState(0);
    const [idresto, setIdresto] = useState(0);
    const [heures, setHeures] = useState([]);
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
  
    const { addresseslist } = useSelector(state => state.restaurent)
    const { horaires } = useSelector(state => state.restaurent)
    const [name, setName] = useState(addresseslist?.name);
    const [surname, setSurname] = useState(addresseslist?.surname);
    const [city, setCity] = useState(addresseslist?.city);
    const [pays, setPays] = useState(addresseslist?.state);
    const [codep, setCodep] = useState(addresseslist?.postal_code);
    const [phone, setPhone] = useState(addresseslist?.phone_number);
    const [phone_wathsap, setPhonewathsap] = useState(addresseslist?.number);
    const [street, setStreet] = useState(addresseslist?.street);
    const [plusde, setPlusde] = useState('');
    const [datelivraison, setDatelivraison] = useState('');
    const [datelivraison1, setDatelivraison1] = useState('');
    const [datelivraison2, setDatelivraison2] = useState('');
    const [iscondition, setIscondition] = useState(false);
    const [channels, setChannels] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const handleCloseAlert = () => setShowAlert(false);


    
 

    const history = useHistory();
    useEffect(() => {

       dispatch(getSettings())
       if (!user.user_id || !total || total<=0  ) {
            history.replace("/")
        }

        if (user.google_id != 'A'  ) {
           alert('Veuillez utiliser un compte client !')
            history.replace("/")
        }


        dispatch(getInfoAdresseById({user_id:user.user_id,token:user.api_token,api_token:user.api_token}));
        setFraislivraison(expidition)

        setValuereduction(reduction.discount);
        setBonreduction(reduction.voucher);
        setTotalwithoutreduction(total)
        setTransaction_id(Math.floor(Math.random() * 100000000).toString())

         let today=new Date()
         
       
        if(modeexpidition){
             let datelivraison1=''
             let datelivraison2=''
           if(modeexpidition=='Aérienne'){
               datelivraison1=moment().add(5, 'days').format('DD MMM');
              datelivraison2=moment().add(10, 'days').format('DD MMM');

           }

           if(modeexpidition=='Maritime'){
                 datelivraison1=moment().add(30, 'days').format('DD MMM');
                 datelivraison2=moment().add(60, 'days').format('DD MMM');
           }
         
           setDatelivraison1(datelivraison1)
           setDatelivraison2(datelivraison2)
         
         
          // setDatelivraison()
        }



       // dispatch(getInfoUserById(user.id));
        //dispatch({ type: GET_TOTAL_CART })
      
       // setValuereduction(reduction.discount);
      //  setBonreduction(reduction.voucher);
      /*
        if(reduction.discount!=0){
            let totalinitial=0;
            if(total>50){
                totalinitial=total+4.80;
            }else{
                totalinitial=total+3.20;
            }
            setTotalwithoutreduction(totalinitial);
        }
        let totalprice = 0;
        if (typeof data[0]?.reso_id !== 'undefined') {
            setIdresto(data[0]?.reso_id);
           // dispatch(getRestaurentHoraires(data[0].reso_id));  
           const paramsd = {
            id:data[0]?.reso_id
           };   
            apiClient().post(`/getspatterns/`,paramsd)
            .then((res) => {
                setDate(res.data.data)
            })
        }
        */
    }, [user.user_id]);


    useEffect(
    () => {
      if (!listenerpayment) return;
       const interval = setInterval(() => {

            // append function

              
              var element = document.getElementById("cp-close");
              element.onclick = function(event) {
                   setListenerpayment(false)

                   
                   //var element = document.getElementsByTagName("style");
                   //var index=element.length - 1;
                   //element[index].remove()
                   window.location.reload();
 

              }

            //
            let state=document.getElementById('statut_paiement');
            if(state.value==1){
                setListenerpayment(false)
                savecommandePay()

            }
            else if(state.value==-1){
               setListenerpayment(false)
               //window.location.reload();
            }


           
       }, 1000);
     return () => clearInterval(interval);
    },
    [listenerpayment]
  );


    const changeInchild = function() {  
        dispatch({ type: GET_TOTAL_CART })      
    }



    const setDate = function(newhoraires) {    
        let date=new Date();
        let numberday = new Date(date).getDay();
        if (numberday == 0) {
          numberday = 7
        }
        let obj = []
        let ok = false
        newhoraires?.forEach(timming => {
            console.log(timming);
          if (timming.raw) {
            timming.raw.forEach(raw => {
              if (raw.week_day === numberday) {
                obj = timming
                ok = true
               // alert('yes');
              }
            })    
          }
        })
        if (ok === false) {
            alert("notre service est fermé pour l’instant.");
            return false
        }
        if (obj.is_opened == true) {
          let nspat = []
          nspat.push({ label: "Choisir heure de livraison", value: "" })    
          obj.availabilities.map((avail) => {
            avail.map((timming) => {
                let date1=new Date(timming);
                let startDate = moment(timming,'YYYY-MM-DD HH:mm').toDate();
               // nspat.push({ label: "" + new Date(timming).DateTimeFormat('hi') + "", value: "" + new Date(timming).DateTimeFormat('hi') + "" })
                nspat.push({ label: "" +moment(startDate).format('HH:mm')+ "", value: "" + moment(startDate).format('HH:mm') + "" })
            })
          })
          setHeures(nspat);
        }
      };
      const viderpanier = () => {
        dispatch({ type: VIDER_PANIER })
        dispatch({ type: GET_TOTAL_CART })  
      };
      const ajouterAddresse = () => {
        history.push("/adresses");
      };

      const setModeDepaiement = (e,t) => {
        
         setModepaiement(t)
 
           switch(t){
         
             case 2:

               setChannels('MOBILE_MONEY')

             break;

              case 3:

               setChannels('WALLET')

             break;
              case 4:

               setChannels('CREDIT_CARD')

             break;


          }
      };


         const appliquerReduction = () => {

        if(bonreduction==''){
           return false;
        }

          const paramsd = {
            gift_code: bonreduction,
            total: total,
           
           };   
            apiClient().post(`/codeReduction`,paramsd)
            .then((res) => {
              
               if (res.data.success == 'ko') {
                   alert("Le code promotionnel spécifié est inconnu ou n'est plus disponible !");
               }else if(res.data.success == 'ok'){

                let percent=res.data.reduction;
                percent=soustotal - (soustotal * parseInt(percent) / 100) 

                setValuereduction(percent);
                let totalinitial=total;
                 
                setTotalwithoutreduction(totalinitial);
                const datareductionstore = {
                    voucher: res.data.gift_id,
                    discount:res.data.reduction
                   };   
                dispatch({ type: OBJECT_REDUCTION,payload:datareductionstore })
                alert("votre code promo est bien été appliqué !");
               }
            })
           }
         const   updateInputValue=(evt)=>{
              setBonreduction(evt.target.value)
          }
         const   updateAsap=(evt)=>{
            setAsap(evt.target.checked)
         }
         const   updateHeure=(evt)=>{
            setHeurelivraison(evt.target.value)
         }
         const   updateindexaddresse=(evt)=>{
            setSelectadresse(evt.target.value)
         }
         const   updatecomment=(evt)=>{
            setComment(evt.target.value)
         }


         
          const lanshpayment=(e)=>{

            e.preventDefault();

            let new_street=addresseslist?.street;
            if(street!==undefined){
                new_street=street;
            }
            let new_name=addresseslist?.name;
            if(name!==undefined){
                new_name=name;
            }
            let new_codep=addresseslist?.postal_code;
            if(codep!==undefined){
                new_codep=codep;
            }
            let new_city=addresseslist?.city;
            if(codep!==undefined){
                new_city=city;
            }
            let new_pays=addresseslist?.state;
            if(pays!==undefined){
                new_pays=pays;
            }
            let new_phone=addresseslist?.phone_number;
            if(phone!==undefined){
                new_phone=phone;
            }
           let new_phone_wathsap=addresseslist?.number;
            if(phone_wathsap!==undefined){
                new_phone_wathsap=phone_wathsap;
            }
            let new_surname=addresseslist?.surname;
            if(surname!==undefined){
                new_surname=surname;
            }

         


           if(modeexpidition){

             if(new_street==undefined || new_name==undefined || new_codep==undefined ||
                 new_city==undefined || new_phone==undefined || new_surname==undefined || new_phone_wathsap==undefined ){
              
              setAlertMessage("Veuillez completer votre adresse de livraison !")
                   setShowAlert(true)
                 return false;
            }

           }
           else{
             if(new_street==undefined || new_name==undefined || new_codep==undefined ||
                 new_city==undefined || new_phone==undefined || new_surname==undefined || new_phone_wathsap==undefined || datelivraison==''){
                
               setAlertMessage("Veuillez completer votre adresse de livraison !")
               setShowAlert(true)
                 return false;
            }
              if(datelivraison < moment().format('YYYY-MM-DD')){
              
                setAlertMessage("Votre date de livraison est incorrecte !")
               setShowAlert(true)
                 return false;
            }

           }

         


           


            if(!iscondition){
               
                    setAlertMessage("Vous devez accepter nos conditions générales !")
               setShowAlert(true)
                 return false;
            }

          
              window.checkout()
              setListenerpayment(true)

      

          }


         const savecommandePay=()=>{


          let new_street=addresseslist?.street;
            if(street!==undefined){
                new_street=street;
            }
            let new_name=addresseslist?.name;
            if(name!==undefined){
                new_name=name;
            }
            let new_codep=addresseslist?.postal_code;
            if(codep!==undefined){
                new_codep=codep;
            }
            let new_city=addresseslist?.city;
            if(codep!==undefined){
                new_city=city;
            }
            let new_pays=addresseslist?.state;
            if(pays!==undefined){
                new_pays=pays;
            }
            let new_phone=addresseslist?.phone_number;
            if(phone!==undefined){
                new_phone=phone;
            }
           let new_phone_wathsap=addresseslist?.number;
            if(phone_wathsap!==undefined){
                new_phone_wathsap=phone_wathsap;
            }
            let new_surname=addresseslist?.surname;
            if(surname!==undefined){
                new_surname=surname;
            }
 


            
            const addressobjet={
            "street":new_street,
            "name":new_name,
            "postal_code":new_codep,
            "city":new_city,
            "state":"Côte d'Ivoire",
            "phone_number":new_phone,
            "number":phone_wathsap,
            "surname":new_surname
            };

            // select type commande 
            let type_commande=1;
            if(data.length){
                type_commande=data[0]?.product?.rubrique;
            }

            // end
       
            const params = {
                user_id: user.user_id,
                payement_inten_id:'',
                details: comment,
                products: data,
                fraislivraison:fraislivraison,
                total_price:parseFloat(parseFloat(parseFloat(total)+ parseFloat(fraislivraison)) - parseFloat(valuereduction)).toFixed(2),
                client_name:user.name,
                client_phone:user.phone,
                client_email:user.email,
                datelivraison:datelivraison,
                api_token:user.api_token,
                address:addressobjet,
                token:user.api_token,
                type_paiement:modepaiement,
                type_commande:type_commande,
                gift_id:bonreduction,
                transaction_id:transaction_id,
                valuereduction:valuereduction,
                modeexpidition:modeexpidition ? modeexpidition : '' ,
              };
             

           
              apiClient().post(`/submitorder`,params)
              .then((res) => {
                if(res.data.success=='ok'){

                    dispatch({ type: VIDER_PANIER })
                    dispatch({ type: GET_TOTAL_CART }) 
                    dispatch({ type: ADD_EXPIDITION,payload:0 })
                    dispatch({ type: ADD_MODEEXPIDITION,payload:'' })
                    localStorage.setItem('citie', ''); 
                    
                    window.location="/commande/confirmation/"+res.data.track_code
                   

                }
                else{
               
                      setAlertMessage("Une erreur est survenue veuillez réessayer ultérieurement !")
               setShowAlert(true)
                }
               
              })


        }
         

        const savecommande=(e)=>{
         
           e.preventDefault();

        


            let new_street=addresseslist?.street;
            if(street!==undefined){
                new_street=street;
            }
            let new_name=addresseslist?.name;
            if(name!==undefined){
                new_name=name;
            }
            let new_codep=addresseslist?.postal_code;
            if(codep!==undefined){
                new_codep=codep;
            }
            let new_city=addresseslist?.city;
            if(city!==undefined){
                new_city=city;
            }
            let new_pays=addresseslist?.state;
            if(pays!==undefined){
                new_pays=pays;
            }
            let new_phone=addresseslist?.phone_number;
            if(phone!==undefined){
                new_phone=phone;
            }

           let new_phone_wathsap=addresseslist?.number;
            if(phone_wathsap!==undefined){
                new_phone_wathsap=phone_wathsap;
            }

            let new_surname=addresseslist?.surname;
            if(surname!==undefined){
                new_surname=surname;
            }

    
             if(modeexpidition){

              if(new_street==undefined || new_name==undefined || new_codep==undefined ||
                 new_city==undefined || new_phone==undefined || new_surname==undefined || new_phone_wathsap==undefined ){
              
               setAlertMessage("Veuillez completer votre adresse de livraison !")
               setShowAlert(true)
                 return false;
            }

             }
             else{

              if(new_street==undefined || new_name==undefined || new_codep==undefined ||
                 new_city==undefined || new_phone==undefined || new_surname==undefined || new_phone_wathsap==undefined || datelivraison==''){
                
               setAlertMessage("Veuillez completer votre adresse de livraison !")
               setShowAlert(true)
                 return false;
              }
              if(datelivraison < moment().format('YYYY-MM-DD')){
              
                setAlertMessage("Votre date de livraison est incorrecte !")
               setShowAlert(true)
                 return false;
            }




             }
            



            if(modepaiement==0){
               setAlertMessage("Veuillez choisir le mode de paiement !")
               setShowAlert(true)
                  
                 return false;
            }

            if(!iscondition){
               
                  setAlertMessage("Vous devez accepter nos conditions générales !")
               setShowAlert(true)
                 return false;
            }

           


            const addressobjet={
            "street":new_street,
            "name":new_name,
            "postal_code":new_codep,
            "city":new_city,
            "state":"Côte d'Ivoire",
            "phone_number":new_phone,
            "number":phone_wathsap,
            "surname":new_surname
            };

            // select type commande 
            let type_commande=1;
            if(data.length){
                type_commande=data[0]?.product?.rubrique;
            }

            // end
       
            const params = {
                user_id: user.user_id,
                payement_inten_id:'',
                details: comment,
                products: data,
                fraislivraison:fraislivraison,
                total_price:parseFloat(parseFloat(parseFloat(total)+parseFloat(fraislivraison)) - parseFloat(valuereduction)).toFixed(2),
                client_name:user.name,
                client_phone:user.phone,
                client_email:user.email,
                datelivraison:datelivraison,
                api_token:user.api_token,
                address:addressobjet,
                token:user.api_token,
                type_paiement:modepaiement,
                type_commande:type_commande,
                gift_id:bonreduction,
                valuereduction:valuereduction,
                 modeexpidition:modeexpidition ? modeexpidition : '',
             
              };
             

           
              apiClient().post(`/submitorder`,params)
              .then((res) => {
                if(res.data.success=='ok'){

                    dispatch({ type: VIDER_PANIER })
                    dispatch({ type: GET_TOTAL_CART }) 
                    dispatch({ type: ADD_EXPIDITION,payload:0 })
                    dispatch({ type: ADD_MODEEXPIDITION,payload:'' })
                    localStorage.setItem('citie', ''); 
                    history.push("/commande/confirmation/"+res.data.track_code);

                }
                else{
                    setAlertMessage("Une erreur est survenue veuillez réessayer ultérieurement !")
               setShowAlert(true)
                  
                }
               
              })
          
        }


    return (
        <div className="panier commande" >
         <div className="ps-breadcrumb">
             <Container>
                <div className="ps-container">
                    <ul className="breadcrumb">
                        <li><a href="#" onClick={()=>history.push('/')}>Accueil</a></li>
                        <li><a href="javascript:void(0)" onClick={()=>history.push('/panier')}>Panier</a></li>
                        <li>Commande </li>
                    </ul>
               </div>
                </Container>
             </div>
            <Container>
            <br/>


            <div className="section-title text-center mb-5"><h2 className="border-left-right-btm">Validation de la commande </h2></div>
             
            <Row className="g-5 se1mobile " >
                                <Col md={4} sm={12} className=" mx-auto my-5 " >

                            <div className="ps-block--shopping-total">

                              <h3 className="ps-form__heading">Votre commande</h3>

                                <div  className='table-responsive'>
                                  <table className="table ps-table--shopping-cart ps-table--responsive">
                                       
         
                                     {data.map((item,index) => (


                                      <tr>
                                                  <td data-label="Product" className='psproducttd'>
                                                      <div className="ps-product--cart">
                                                          <div className="ps-product__thumbnail"><a href="#"><img src={item.product.img} alt=""/></a></div>
                                                          <div className="ps-product__content"><a href="#">{item.product.title} </a>
                                                               
                                                              <p>
                                                              <div>
                                                                  <a className='plusdetail' href='javascript:void(0)' onClick={()=>setPlusde(index)} >{plusde===index ? '-' :'+'}Plus de détail</a>
                                                                  {
                                                                     plusde===index &&

                                                                     <p className="variations">
 
                                                                                        {item?.extrasdatas?.map((item) => (
                                                                                                          
                                                                                                            item.name == 'Couleur' ?
                                                                                                           <div style={{    display: 'flex'}}>   {item.name}:<strong style={{textTransfom:'uppercase',marginLeft:'10px'}}> <span className="_1xuQrbasquet" style={{backgroundColor: item.value}}></span></strong></div>       
                                                                                                            :

                                                                                                           <div style={{    display: 'flex'}}>{item.name}:<strong style={{textTransfom:'uppercase',marginLeft:'10px'}}> {item.value}</strong></div>       
                                                                                            ))}

                                                                                            
                                                                                           
                                                                                 </p>
                                                                  }
                                                                                



                                                              </div>
                                                               
                                                              </p>

                                                          </div>

                                                          
                                                      </div>
                                                       <div className='ps-product-total'><span>{item.qty} x {item.product.price} CFA</span></div>
                                                  </td>
       
                                      </tr>  
                                         
                                     ))}  
                                       

                                      
                                </table>
                                </div>


                                <div className="ps-block__header">
                                    <p>Sous total <span> { parseFloat(total).toFixed(2)} CFA</span></p>
                                </div>

                                  {valuereduction > 0 &&
                                    <div className="ps-block__header">
                                    <p style={{    marginBottom: 0}}>Bon de réduction <span> -{valuereduction} CFA</span></p>
                                    
                                    </div>
                                 }


                                <div className="ps-block__header">
                                  {
                                     parseFloat(fraislivraison) >0 ?
                                    <p>Expédition <span> { parseFloat(fraislivraison).toFixed(2)} CFA</span></p>
                                    :
                                    <p>Expédition <span> GRATUIT</span></p>

                                  }
                                </div>
                                <div className="ps-block__content">
                               
                                    
                                  {valuereduction > 0  ?

                                  <h3>Total <span className={"prix-barre"} style={{paddingLeft:'5px'}}>{(totalwithoutreduction+ parseFloat(fraislivraison)).toFixed(2)} CFA </span> <span> { ((parseFloat(total)-parseInt(valuereduction)) + parseFloat(fraislivraison)).toFixed(2)} CFA</span></h3>
                                :
                                 <h3>Total<span>{ (parseFloat(total) + parseFloat(fraislivraison)).toFixed(2)} CFA</span></h3>

                                }
                                </div>

                               


                            </div>

                
                 

                           

                </Col>
            </Row>

            <Row className="g-5 " >
                <Col md={8} sm={6} className=" mx-auto my-5  " >


               

                    <div className="ps-form__billing-info ps-block--shopping-total">
                                    <h3 className="ps-form__heading">Détails d'expédition</h3>
                                    <br/>

                  <Row >

                       <Col md={6} >
                            <div className="form-group">
                                        <label>Nom <sup>*</sup>
                                        </label>
                                        <div className="form-group__content">
                                            <input required name="surname" className="form-control" type="text"  onChange={evt => setSurname(evt.target.value)}
                                             defaultValue={addresseslist?.surname}/>
                                        </div>
                                        <input type='hidden' name='email' value={user?.email} />
                              {valuereduction > 0  ?
                                  <input type='hidden' name='amount_id' value={ ((parseFloat(total)-parseInt(valuereduction)) + parseFloat(fraislivraison)).toFixed(2)} />

                                 :
                                  
                                   <input type='hidden' name='amount_id' value={ (parseFloat(total) + parseFloat(fraislivraison)).toFixed(2)} />
                                }

                                <input type='hidden' name='channels' value={channels} />

                                <input type='hidden' name='transaction_id' value={transaction_id} />
                                        
                                    </div>
                       </Col>

                      <Col md={6} >
                            <div className="form-group">
                                        <label>Prénom<sup>*</sup>
                                        </label>
                                        <div className="form-group__content">
                                            <input required name="name"  className="form-control" type="text"  onChange={evt => setName(evt.target.value)}
                                             defaultValue={addresseslist?.name}/>
                                        </div>
                                    </div>
                       </Col>




               

                   </Row>

                    <Row >

                       <Col md={6} >
                              <div className="form-group">
                                        <label>Lieu de livraison<sup>*</sup>
                                        </label>
                                        <div className="form-group__content">
                                            <input required className="form-control" type="text" defaultValue={addresseslist?.city}
                                             name="city"
                                             onChange={evt => setCity(evt.target.value)} />
                                        </div>
                                    </div>
                       </Col>

                         <Col md={6} >
                              <div className="form-group">
                                        <label>Date de livraison<sup>*</sup>
                                        </label>
                                        <div className="form-group__content">
                                        {
                                          modeexpidition ?
                                               <div>
                                          <p style={{color:'#ff3300'}}>Livraison prévu entre le  {datelivraison1} au  {datelivraison2} </p>

                                            


                                          </div>
                                            :
                                             <input required className="form-control" min={'23/07/2022'} type="date" value={datelivraison}
                                             name="datelivraison"
                                             onChange={evt => setDatelivraison(evt.target.value)} />

                                      

                                        }
                                            
                                        </div>
                                    </div>
                       </Col>

                   
                       
                   </Row>

                    <Row >



                       <Col md={6} >
                          <div className="form-group">
                                        <label>Téléphone<sup>*</sup>
                                        </label>
                                        <div className="form-group__content">
                                            <input required className="form-control" type="text" onChange={evt => setPhone(evt.target.value)}
                                           defaultValue={addresseslist?.phone_number}
                                            name="phone_number"/>
                                        </div>
                                    </div>
                       </Col>
                              <Col md={6} >
                          <div className="form-group">
                                        <label>Numéro WhatsApp <span style={{fontSize:'10px',letterSpacing: '-0.5px'}}>(Pour envoyer votre facture)</span><sup>*</sup>
                                        </label>
                                        <div className="form-group__content">
                                            <input required className="form-control" type="text" onChange={evt => setPhonewathsap(evt.target.value)}
                                           defaultValue={addresseslist?.number}
                                            name="phone_wathsap"/>
                                        </div>
                                    </div>
                       </Col>

                   </Row>

                    <Row >

                 

                       <Col md={9} >
                               <div className="form-group">
                                        <label>Adresse<sup>*</sup>
                                        </label>
                                        <div className="form-group__content">
                                            <input required className="form-control" type="text"  name="street"
                                               onChange={evt => setStreet(evt.target.value)}
                                              defaultValue={addresseslist?.street}/>
                                        </div>
                                    </div>
                       </Col>

                          <Col md={3} >
                               <div className="form-group">
                                        <label>Code postal<sup>*</sup>
                                        </label>
                                        <div className="form-group__content">
                                            <input required className="form-control" type="text"  name="postal_code"
                                              onChange={evt => setCodep(evt.target.value)}
                                              defaultValue={addresseslist?.postal_code}/>
                                        </div>
                                    </div>
                       </Col>

                   </Row>
                                   
                                   
                  </div>
                  <div className='ps-form__billing-info ps-block--shopping-total'>
                       
                           <h3 className="ps-form__heading">Informations supplémentaires</h3>
                           <br/>
                                    <div className="form-group">
                                     
                                        <div className="form-group__content">
                                            <textarea onChange={evt => updatecomment(evt)} className="form-control" rows="2" placeholder="Remarques concernant votre commande, par ex. notes spéciales pour la livraison."></textarea>
                                        </div>
                     </div>

                  </div>

                </Col>


                <Col md={4} sm={12} className=" mx-auto my-5 " >

                            <div className="ps-block--shopping-total order-1">

                              <h3 className="ps-form__heading">Votre commande</h3>

                                <div  className='table-responsive'>
                                  <table className="table ps-table--shopping-cart ps-table--responsive">

                                     {data.map((item,index) => (


                                      <tr>
                                                  <td data-label="Product" className='psproducttd'>
                                                      <div className="ps-product--cart">
                                                          <div className="ps-product__thumbnail"><a href="#"><img src={item.product.img} alt=""/></a></div>
                                                          <div className="ps-product__content"><a href="#">{item.product.title} </a>
                                                               
                                                              <p>
                                                              <div>
                                                                  <a className='plusdetail' href='javascript:void(0)' onClick={()=>setPlusde(index)} >{plusde===index ? '-' :'+'}Plus de détail</a>
                                                                  {
                                                                     plusde===index &&

                                                                     <p className="variations">
 
                                                                                        {item?.extrasdatas?.map((item) => (
                                                                                                          
                                                                                                            item.name == 'Couleur' ?
                                                                                                           <div style={{    display: 'flex'}}>   {item.name}:<strong style={{textTransfom:'uppercase',marginLeft:'10px'}}> <span className="_1xuQrbasquet" style={{backgroundColor: item.value}}></span></strong></div>       
                                                                                                            :

                                                                                                           <div style={{    display: 'flex'}}>{item.name}:<strong style={{textTransfom:'uppercase',marginLeft:'10px'}}> {item.value}</strong></div>       
                                                                                            ))}

                                                                                            
                                                                                           
                                                                                 </p>
                                                                  }
                                                                                



                                                              </div>
                                                               
                                                              </p>

                                                          </div>

                                                          
                                                      </div>
                                                       <div className='ps-product-total'><span>{item.qty} x {item.product.price} CFA</span></div>
                                                  </td>
       
                                      </tr>  
                                         
                                     ))}
                                       
                          
                                       

                                      
                                </table>
                                </div>


                                <div className="ps-block__header">
                                    <p>Sous total <span> { parseFloat(total).toFixed(2)} CFA</span></p>
                                </div>
                                 {valuereduction > 0 &&
                                    <div className="ps-block__header">
                                    <p style={{    marginBottom: 0}}>Bon de réduction <span> -{valuereduction} CFA</span></p>
                                    
                                    </div>
                                 }
                                <div className="ps-block__header">
                                    <p>Expédition <span> { parseFloat(fraislivraison).toFixed(2)} CFA</span></p>
                                </div>
                                <div className="ps-block__content">
                               
                                        {valuereduction > 0  ?

                                  <h3>Total <span className={"prix-barre"} style={{paddingLeft:'5px'}}>{(totalwithoutreduction + parseFloat(fraislivraison)).toFixed(2)} CFA </span> <span> { ((parseFloat(total)-parseInt(valuereduction)) + parseFloat(fraislivraison)).toFixed(2)} CFA</span></h3>
                                :
                                 <h3>Total<span>{ (parseFloat(total) + parseFloat(fraislivraison)).toFixed(2)} CFA</span></h3>

                                }
                                </div>

                               


                            </div>

                            <div className="ps-block--shopping-total">

                              <h3 className="ps-form__heading">Méthode de paiement</h3>

                              <div className="checkout__address--content__header">
                                     {
                                        modeexpidition ?
                                        ''
                                        :
                                           <div className="shipping__contact--box__list">
                                            <div className="shipping__radio--input">
                                                <input className="shipping__radio--input__field" id="radiobox2" name="checkmethod" type="radio" onClick={(e)=>setModeDepaiement(e,1)}/>
                                            </div>
                                            <label className="shipping__radio--label" for="radiobox2">
                                                <span className="shipping__radio--label__primary">

                                                 
                                                    Paiement à la livraison
                                               

                                                 {
                                                  modeexpidition && modepaiement== 1 ?
                                                   <div className='center'>
                                                    <p> <div>Localisation</div> <div><strong dangerouslySetInnerHTML={{ __html: settings.site_address }}></strong></div></p>
                                                    <p> <div>Téléphone</div><div> <strong dangerouslySetInnerHTML={{ __html: settings.site_phone }}></strong></div></p>
                                                  </div>

                                                  :
                                                  ''
                                                 }

                                                </span>
                                            </label>
                                        </div>

                                     }
                                     


                                        <div className="shipping__contact--box__list">
                                            <div className="shipping__radio--input">
                                                <input className="shipping__radio--input__field" id="radiobox" name="checkmethod" type="radio" onClick={(e)=>setModeDepaiement(e,2)}/>
                                            </div>
                                            <label className="shipping__radio--label labelpay66" for="radiobox">
                                                <span className="shipping__radio--label__primary">Orange Money, Moov Money,MTN Money</span>
                                                <div className='pppay78'> <img  style={{width: '134px'}} src='/images/pay11.png' /></div>
                                            </label>

                                        </div>
                                        <div className="shipping__contact--box__list">
                                            <div className="shipping__radio--input">
                                                <input className="shipping__radio--input__field" id="radiobox" name="checkmethod" type="radio"  onClick={(e)=>setModeDepaiement(e,3)}/>
                                            </div>
                                            <label className="shipping__radio--label labelpay67" for="radiobox">
                                                <span className="shipping__radio--label__primary">Wave</span>
                                                <div className='pppay78'> <img  style={{width: '34px'}} src='/images/pay22.png' /></div>
                                            </label>
                                        </div>
                                        <div className="shipping__contact--box__list ">
                                            <div className="shipping__radio--input">
                                                <input className="shipping__radio--input__field" id="radiobox" name="checkmethod" type="radio" onClick={(e)=>setModeDepaiement(e,4)}/>
                                            </div>
                                            <label className="shipping__radio--label labelpay66" for="radiobox">
                                                <span className="shipping__radio--label__primary">Cartes de crédit/débit</span>
                                                 <div className='pppay78'><img  style={{width: '96px'}} src='/images/pay33.png' /></div>
                                            </label>
                                        </div>


                               </div>




                            </div>
                            
                            <div style={{marginTop: '13px',marginBottom:'20px'}}>
                             <input type='checkbox' onClick={()=>setIscondition(!iscondition)} />
                             <span style={{paddingLeft: '8px'}}>J'ai lu et j'accepte les conditions générales *</span>

                            </div>


                        
                            {
                              modepaiement == 2 ||  modepaiement == 3 || modepaiement == 4 ?
                                <a className="ps-btn ps-btn--black ps-btn--black-w" href="javascript:void(0)" onClick={(e)=>lanshpayment(e)}>PAYER</a>
                                :
                                <a className="ps-btn ps-btn--black ps-btn--black-w" href="javascript:void(0)" onClick={(e)=>savecommande(e)}>PAYER</a>

                            }

                            
                             



                            <br/>
                            <div style={{    marginTop: '20px'}}>
                              <h4 className='center'><i className='fa fa-lock'></i> Paiement sécurisé</h4>
                            </div>

                            <input type='hidden' name='statut_paiement' id='statut_paiement' value={0} /> 

                </Col>

                </Row>



      <Modal show={showAlert} onHide={handleCloseAlert} centered>
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
          {alertMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAlert} style={{background: '#000'}}>
            Fermer
          </Button>
          
        </Modal.Footer>
</Modal>
 

            </Container>
        </div>

    );
}

export default Page;