import { AUTHENTICATE_USER,SET_USER,LOGOUT_USER } from "../constants/actions";

const INITIAL_STATE = {
    user: {},
};

function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AUTHENTICATE_USER :
            return {...state,user:action.payload}
        case SET_USER :
            return {...state,user:{...state.user,...action.payload}}
        case LOGOUT_USER :
           return {...state,user:INITIAL_STATE.user}
        default:
            return state;
    }
}
export default reducer;