import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,ListGroup,ListGroupItem,Card,Table} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getStories} from "../../../actions/user";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { defaultimg } from "../../../assets/images";
import { MenuVendeur } from "../../../components";
import moment from 'moment'


const Page = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { stories } = useSelector(state => state.restaurent)
    const [date, setDate] = useState('')
     const [counter, setCounter] = useState('')
    const history = useHistory();

  useEffect(() => {
        dispatch(getStories(user.user_id,user.api_token,'vendeur',{}));
    }, []);

   useEffect(() => {
   if(counter){
      setFormFiltre()
   }
    
}, [counter]);



    function gotoCmd(item) {
       history.push(`/vendeur/modifier-storie/${item}`);
     }

    function removestorie(item) {
       
     }

        const HandleFiltre =  (slug,type,e) => {
      
       e.preventDefault();
          switch(type){
             case 'date':

                setDate(slug)
                setCounter(1)


             break;

             case 'rubrique':

               
                
             break;
            

          }

        
            
 
    }


    const  setFormFiltre = async () => {
      let params ={filtre:'1'}

      if(date){
         
         params.date=date
      }
  
  
     dispatch(getStories(user.user_id,user.api_token,'vendeur',params));
     setCounter('')

    }

    
    return (
        <div className="mescommandes register liststories " >
            <Container>
                <Row>
            <Col  className="text-center mx-auto my-5 froweb98"  lg="3">

               <MenuVendeur slug='stories' />
               
            </Col>
              
              <Col  className="text-center mx-auto my-5"  >

     
                  <h3 className="mb-4" ><img src="/images/orderico.png" style={{width: '38px'}} /> Gérer mes stories</h3>
                             <br/> 


          
            <div className='filtreorder'>
               <div className='item'>
                    
                      <div className='colitem'>
                      <div >
                        Date
                      </div>

              
                      <div  className='iiin'>
                         
                         <input type="date" className='form-control' onChange={(event)=>HandleFiltre(event.target.value,'date',event)} />
                        
                     </div>


                    </div>
                </div>
                <div className='item'>
                    <button style={{    padding: '10px',fontSize: '11px'}} onClick={()=> history.push(`/vendeur/ajouter-storie/`)} type="button" className="ps-btn ps-btn--fullwidth ps-btn-dark btn btn-primary">AJOUTER STORIE</button>
                </div>
                


            </div>


                    <form className="mb-5">
                      
                          <Row className="mb-3"  >

                          <div className='tavbleweb'>

                            <Table responsive >
                          <thead>
                            <tr>
                                <th>Référence</th>
                                <th>Titre</th>
                                <th >Date création </th>
                              
                                <th  >Actions</th>

                             
                            </tr>
                          </thead>
                        <tbody>

                         {stories?.map((item) => (


                            <tr>
                              <td>{item?.ref}</td>
                              <td>{item?.titre}</td>
                              <td>
                                 {moment(new Date(item.created_at)).format("DD/MM/YYYY")}
                              </td>
                         
                            
                              <td>
                              <div className="list-contr ps-flex-center">

                               <a  href='javascript:void(0)' onClick={()=>gotoCmd(item?.ref)} className="color-bg tolt center view" data-microtip-position="left" data-tooltip="Modifier">Modifier <i class="icon-edit"></i></a>
                               <a style={{background: '#f63446'}} href='javascript:void(0)'  onClick={()=>removestorie(item?.ref)}  className="color-bg-red tolt center view" data-microtip-position="left" data-tooltip="Modifier">Supprimer <i class="icon-remove"></i></a>
                               </div>
                              </td>
                            </tr>


                          ))}
                     
                               
                               
                          
                          

                          </tbody>
                        </Table>

 
                          </div>
                           
                          


                        <div className='tavblemob'>
                           {stories?.map((item) => (


                          <div className='items'>
                              <a  href='javascript:void(0)'   >

                                <div className='rowitmtop'>
                                   <span> {moment(new Date(item.created_at)).format("DD MMM YYYY")}</span>
                                   <span>Réf : {item?.ref}</span>
                                </div>

                                <div className='process-item'>

                                    <strong>{item?.titre}</strong>
                                
                              <div className="list-contr ps-flex-center" >

                               <a  href='javascript:void(0)' onClick={()=>gotoCmd(item?.ref)}  className="color-bg tolt center view" data-microtip-position="left" data-tooltip="Modifier">Modifier <i class="icon-edit"></i></a>
                               <a  style={{background: '#f63446'}} href='javascript:void(0)' onClick={()=>removestorie(item?.id)}  className="color-bg-red tolt center view" data-microtip-position="left" data-tooltip="Modifier">Supprimer <i class="icon-remove"></i></a>
                               


                               </div>

                                 </div>
                                </a>
                              </div>




                                    ))}
                             

                  


                             
  
                        </div> 

                        <br/> <br/>
 
                           

                          </Row>

                    </form>
                        
                         

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;