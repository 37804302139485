import React,{useState,useEffect} from "react";
import { logo,google_play,app_store,logof,linkedin,face,insta } from "../../assets/images";
import './styles.scss';
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux"
import { addNewsletter } from "../../actions/mixedmode";
import { getSettings} from "../../actions/mixedmode";
import { REMOVE_PRODUCT_BASKET, GET_TOTAL_CART } from "../../constants/actions";

const Component = ({ surcmd }) => {

    const dispatch = useDispatch();
    const history = useHistory()
    const { user } = useSelector(state => state.user)
   
   const { data,total } = useSelector(state => state.basket)
    const [showmenu,setShowMenu]=useState(false);
    const [value,setValue]=useState('');
     const { settings } = useSelector(state => state.mixedmode)

        const goToCart = () =>{
         history.push(`/panier`);
     }

    const goToPage = (src) =>{
         history.push(src);
    }
    const goToLoginOrAcount = () =>{
         history.push(`/login`);
    }
    const handlerMenu = () => {
        
          setShowMenu(!showmenu)

    }
            
  
     useEffect(() => {
         dispatch(getSettings())

          window.addEventListener("scroll", isSticky);
        return () => {
          window.removeEventListener("scroll", isSticky);
        };

    }, []);

            
  
  
      const handleSubmitnewsletter = () => {
        const formDataObj ={email:value};
        if(formDataObj.email=="" ){
           alert("Merci de indiquez votre adresse email.");
        }else{
             dispatch(addNewsletter(formDataObj));
        }
    
    }


      const gototop = () => {
        window.scrollTo(0,0);
    
    }

    const isSticky = (e) => {
    const header = document.querySelector(".toTop");
    const scrollTop = window.scrollY;
    scrollTop >= 250
      ? header.classList.add("is-top")
      : header.classList.remove("is-top");
    };



    return (
        <div className='footercontent' style={{position:'relative'}}>

        <a href="javascript:void(0)" onClick={()=>gototop()} id="toTop" className='toTop' ><span id="toTopHover" ></span>To Top</a>

        <footer className="footer-element">
            <Container className="footer-container">
               <div className="ps-footer__widgets">
                <aside className="widget widget_footer widget_contact-us">

                <a className="ps-logo" href="#"><img src="/images/logo.png" alt=""/></a>
                <br/><br/>
                  
                    <div className="widget_content">
                        <p>Nous contacter 24/7</p>
                      
                         <h3 dangerouslySetInnerHTML={{ __html: settings.site_phone }}></h3>
                         
                        <a href="#"><h3>{settings.site_email}</h3></a>
                        
                        <p dangerouslySetInnerHTML={{ __html: settings.site_address }}></p>
                      
                    </div>
                </aside>
                <aside className="widget widget_footer">
                    <h4 className="widget-title">Entreprise</h4>
                    <ul className="ps-list--link">
                        <li><a href="javascript:void(0)" onClick={()=>goToPage('/apropos')} >Qui sommes nous</a></li>
                        <li><a href="javascript:void(0)" onClick={()=>goToPage('/contact')}>Nous contacter</a></li>
                        <li><a href="javascript:void(0)" onClick={()=>goToPage('/retour')} >Conditions de retour</a></li>
                        <li><a href="javascript:void(0)" onClick={()=>goToPage('/livraison')} >Conditions de livraison</a></li>
                       
                        
                    </ul>
                </aside>
                <aside className="widget widget_footer">
                    <h4 className="widget-title">Aide </h4>
                    <ul className="ps-list--link">
                        
                        <li><a href="javascript:void(0)" onClick={()=>goToPage('/rgbd')}>RGPD</a></li>
                        <li><a href="javascript:void(0)" onClick={()=>goToPage('/cgv')}>CGV</a></li>
                        <li><a href="javascript:void(0)" onClick={()=>goToPage('/cgu')}>CGU</a></li>
                        <li><a href="javascript:void(0)" onClick={()=>goToPage('/aide')}>FAQ</a></li>
                        <li><a href="javascript:void(0)" onClick={()=>goToPage('/login')}>Mon compte</a></li>
                        <li><a href="javascript:void(0)" onClick={()=>goToPage('/login?livreur=1')}>Livreur</a></li>
                        
                    </ul>
                </aside>
                <aside className="widget widget_footer">
                    <h4 className="widget-title">Newsletter</h4>
                    <div className="form-group--nest">
                                    

                                     <input className="form-control" onChange={e => setValue(e.target.value)}  name="email" type="text" placeholder="Email.."/>
                                     <button onClick={handleSubmitnewsletter} className="ps-btn">S'abonner</button>

                    </div>

                    <br/>
                    <h4 className="widget-title">Téléchargez l'appli</h4>
                    <div className="form-group--nest">
                    <a href="https://play.google.com/store/apps/details?id=app.dev.mixedmod.shop&hl=fr&gl=US" target='_blank'><img src="/images/google_play.png" /></a>
                    <br/>
                    <a href='https://apps.apple.com/tt/app/mixed-mode/id1542605982?l=fr' target='_blank' > <img src="/images/app_store.png" /></a>
                                    
                    </div>
                     
                </aside>
            </div>

            <hr className="mt-5 op-5" style={{    background: "grey"}}/>
            <div className="row">
            <div className="col-lg-4"><p>© 2022 <a href="" className="phrasefooter" >MIXED MODE </a>All Rights Reserved</p></div>
            <div className="col-lg-8">
<ul className="ps-list--social" style={{float: 'right'}}><li><a className="facebook" href="javascript:void(0)"><i className="fa fa-facebook"></i></a></li><li><a className="twitter" href="#"><i className="fa fa-twitter"></i></a></li><li><a className="google-plus" href="#"><i className="fa fa-google-plus"></i></a></li><li><a className="instagram" href="#"><i className="fa fa-instagram"></i></a></li></ul>
           </div>
            </div>
            </Container>
        </footer>


<div className="navigation--list nav-mobile-778">
        <div className="navigation__content overscroll">
          <a onClick={()=>goToPage('/#')} className="navigation__item ps-toggle--sidebar iwico" href="javascript:void(0)"><img src='/images/icofhome.png' /><span> Accueil</span></a>
          <a onClick={()=>goToPage('/en-promo/produits/2#')} className="navigation__item ps-toggle--sidebar iwico" href="javascript:void(0)"><img src='/images/icopromo.png' /><span>Promo</span></a>

          <a onClick={goToCart} className="navigation__item ps-toggle--sidebar iwico" href="#cart-mobile"><img src='/images/icofpanier.png' /><span> Panier <span className='icobags'><i>{data?.length}</i></span> </span></a>
           
          <a onClick={()=>goToPage('/stories')} className="navigation__item ps-toggle--sidebar iwico" href="javascript:void(0)"><img src='/images/icostories.png' /><span> Stories</span></a>
                    <a onClick={()=>goToPage('/login')} className="navigation__item ps-toggle--sidebar iwico" href="javascript:void(0)"><img src='/images/icoprofil.png' /><span> Compte</span></a>
          </div>
       </div>






        <div className="ps-panel--sidebar" id="cart-mobile">
        <div className="ps-panel__header">
            <h3>Shopping Cart</h3>
        </div>
        <div className="navigation__content">
            <div className="ps-cart--mobile">
                <div className="ps-cart__content">
                    <div className="ps-product--cart-mobile">
                        <div className="ps-product__thumbnail"><a href="javascript:void(0)"><img src="/images/template/clothing/7.jpg" alt=""/></a></div>
                        <div className="ps-product__content"><a className="ps-product__remove" href="javascript:void(0)"><i className="icon-cross"></i></a><a href="#">MVMTH Classical Leather Watch In Black</a>
                            <p><strong>amazon.fr</strong> </p><small>1 x $59.99</small>
                        </div>
                    </div>
                </div>
                <div className="ps-cart__footer">
                    <h3>Sous Total:<strong>$59.99</strong></h3>
                    <figure><a className="ps-btn" href="shopping-cart.html">Voir panier</a></figure>
                </div>
            </div>
        </div>
     </div>









<div id="loader-wrapper" style={{display:'none'}}>
        <div className="loader-section section-left"></div>
        <div className="loader-section section-right"></div>
    </div>











        </div>
    );
}

export default Component;