import React,{ useEffect,useState,useRef }  from "react";
import './styles.scss';

import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux"
import { defaultimg } from "../../assets/images";
import { Nav,Card} from "react-bootstrap";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory,BiUser,BiLogInCircle,BiHeart,BiBarChartSquare,BiCog,BiCar,BiWebcam,BiCommentDetail,BiMoney } from 'react-icons/bi';
import { LOGOUT_USER } from "../../constants/actions";
import { changepicture } from "../../actions/mixedmode";

const Component = ({ slug }) => {
    const history = useHistory();
    const {user} = useSelector(state => state.user)
    const dispatch = useDispatch();
    const [value, setValue] = useState(0);
    const fileInput = useRef(null)
   
    useEffect(() => {
          
        if (!user.user_id || user.google_id!='V' ) {
            history.replace("/")
        }
    }, [user.user_id])


    
    const handleClick = (src) => {
         history.push(src);
    }
        const handleLogout = () =>{
       
        dispatch({type:LOGOUT_USER});
    }

      const handleClickfile = () => {
      fileInput.current.click()
  }

  const handleFileChange = event => {
       
      const formData =  new FormData();
      formData.append('image',event.target.files[0]);
      formData.append('token',user.api_token);
      formData.append('api_token',user.api_token);
      console.log("formData",formData)
      dispatch(changepicture(formData));
     
  }


    return (
             <Card className='menudash' >
              
              {(user.picture=="" || !user.picture) && <Card.Img variant="top" style={{cursor:'pointer'}} onClick={() => handleClickfile()} src={defaultimg} />}
              {user.picture && <Card.Img onClick={() => handleClickfile()}  style={{cursor:'pointer',width:100,height:100,backgroundImage:`url(${"https://mixedmode.fr/gestion/public/uploads/"+user.picture})`,borderRadius:50,backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',alignSelf: 'center' ,marginTop:20}} />}
               <form style={{padding:'0px'}}>
               <input
               style={{display:'none'}}
                type="file"

                onChange={(e) => handleFileChange(e)}
                ref={fileInput} 
            />
            </form>


                  <Card.Body>
                    <Card.Title className='text-center'>{user.first_name} {user.last_name}</Card.Title>
                    <Card.Text>
                    {user.adresse}
                    </Card.Text>
                  <Nav defaultActiveKey="/home" className="flex-column nav-style">
                  <Nav.Link   onClick={()=>handleClick('/vendeur/dashboard/')} className={slug=='dashboard' && "active"} ><BiBarChartSquare  size={20} /> Dashboard</Nav.Link>
                  <Nav.Link   onClick={()=>handleClick('/vendeur/menu/boutique')} className={slug=='addproduct' && "active"} ><BiCog  size={20} /> Gérer ma boutique</Nav.Link>
                  <Nav.Link   onClick={()=>handleClick('/vendeur/commandes/')} className={slug=='commandes' && "active"} ><BiShoppingBag  size={20} /> Mes commandes</Nav.Link>
                  <Nav.Link   onClick={()=>handleClick('/vendeur/livraisons/')} className={slug=='suivilivraison' && "active"} ><BiCar  size={20} /> Suivi de livraison</Nav.Link>
                  <Nav.Link   onClick={()=>handleClick('/vendeur/stories')} className={slug=='stories' && "active"} ><BiWebcam  size={20} /> Gérer mes stories</Nav.Link>
                  <Nav.Link onClick={()=>handleClick('/vendeur/profile')}  className={slug=='profile' && "active"}><BiUserCircle   size={20} /> Mon profile</Nav.Link>
                   <Nav.Link onClick={()=>handleClick('/vendeur/vos-ventes')}  className={slug=='vos-ventes' && "active"}><BiMoney   size={20} /> Le point de vos ventes</Nav.Link>
                  <Nav.Link    onClick={()=>handleClick('/vendeur/commentaires')} className={slug=='commentaires' && "active"} ><BiCommentDetail   size={20} /> Avis et commentaires</Nav.Link>
                  <Nav.Link  onClick={()=>handleClick('/vendeur/reclamations')} className={slug=='reclamations' && "active"}  ><BiUser   size={20} /> Laisser une réclamation</Nav.Link>
                </Nav>

                <div className='logout'>
                     <button className="logout_btn color2-bg" onClick={handleLogout}>Déconnexion <BiLogInCircle color={"#FFF"} size={20} /></button>
                </div>

                
                  </Card.Body>
                </Card>
    );
}

export default Component;