import React, { useEffect,useState } from "react";
import './styles.scss';
import { logo } from "../../../assets/images";
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom";
import {IS_SUBMITED} from "../../../constants/actions"
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../../actions/user";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useParams } from "react-router";


const Page = () => {
    
    const { slug } = useParams()
    const dispatch = useDispatch();
    const { issubmited } = useSelector(state => state.global)
    const { user } = useSelector(state => state.user)
    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    const [phone, setPhone] = useState('')
    const [phonewath, setPhonewath] = useState('')

    const [error, setError] = useState(false)
    
    useEffect(() => {

         if(issubmited){
            dispatch({ type: IS_SUBMITED, payload: false })
            history.push("/compte-cree");
        }

        if (user.id) {
            history.replace("/")
        }
    }, [user.id,issubmited])

  

     const handleSubmit = async (e) => {
        e.preventDefault();
        setError(false)
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())

          if(localStorage.getItem('token')){
            if(localStorage.getItem('typepush')=='ios'){
                formDataObj.push_ios=localStorage.getItem('token');
            }
            else{
                formDataObj.push_android=localStorage.getItem('token');
            }

        }
/*
        if(formDataObj.whatsap=='' || formDataObj.phone=='' ){
            setError(true)
             return false;
        }
*/
       
        
      
        dispatch(registerUser(formDataObj,'V'));
        
    }
    const handleClick = () => {
         history.push('/vendeur/dashboard/')
    }

     const gotocgv = () => {
         window.open('/rgbd')
    }

    return (
        <section  className=" devenirvendeur">
        <div >
      


                        <Container>

             <div className="row">
                        <div className="col-lg-12">
                           <div className="section-title text-center mb-5">
                            <h2 className="border-left-right-btm">Devenir Vendeur </h2>
                           </div>
                        </div>
            </div>

             <div className="row">
                        <div className='col-lg-3'></div>
                        <div className="col-lg-6">
                          
                            <h2 className="border-left-right-btm center" style={{border:'none', fontSize:'17px'}}>Formulaire d'inscription de votre boutique</h2>
                          
                        </div>
                        <div className='col-lg-3'></div>
              </div>


                <Row>

                  <Col lg={{ span: 8 }} className="text-center mx-auto my-3" >
                       <Form className="mb-5" onSubmit={handleSubmit}  >

                        <div className='item '>
                        <a href='#' className='fashion active' ><img src={slug=='fashion' ?'/images/fashionrond.jpg' : '/images/afrocreationrond.jpg' } /></a>
                        
                        </div>

                    
                        <h3 className="mb-4" >{slug=='fashion' ? 'FASHION' : 'AFRO CREATION'}</h3>
                       
                       
                         <Row className="mb-3">
                         <p>
                           Remplissez le formulaire et vendez gratuitement !
                         </p>
                         </Row>

                          <Row className="mb-3">
                                <Form.Group as={Col} >
                                    <Form.Label>Votre Nom et Prénom*</Form.Label>
                                    <Form.Control type="text" placeholder="ex:Aliox Robert" name="first_name" required />
                                    <input type='hidden' name='last_name' />
                                     <input type='hidden' name='typeboutique' value={slug}/>

                                </Form.Group>
                                <Form.Group as={Col} >
                                    <Form.Label>Le Nom de votre boutique*</Form.Label>
                                    <Form.Control type="text" placeholder="ex:Chahda" name="nomboutique" required />
                                </Form.Group>
                          </Row>
                          <Row className="mb-3">
                                <Form.Group as={Col} >
                                    <Form.Label>Pays de résidence*</Form.Label>
                                    <Form.Control type="text" placeholder="" name="pays" required/>
                                </Form.Group>
                                <Form.Group as={Col} >
                                    <Form.Label>Ville ou commune*</Form.Label>
                                    <Form.Control type="text" placeholder="" name="ville" required />
                                </Form.Group>
                          </Row>

                             <Row className="mb-3">
                                <Form.Group as={Col} >
                                    <Form.Label>Numéro de téléphone</Form.Label>
                                    <PhoneInput
                                          country={'ci'}
                                          value={phone}
                                          name={"phone"}
                                          enableLongNumbers={true}
                                          onChange={phone => setPhone(phone)}
                                          
                                        />
                                    <input type='hidden' name="phone" value={phone} />
                                </Form.Group>
                                <Form.Group as={Col} >
                                    <Form.Label>Numéro wathsApp</Form.Label>
                                     <PhoneInput
                                          country={'ci'}
                                           enableLongNumbers={true}
                                          value={phonewath}
                                          name={"whatsap"}
                                          onChange={phone => setPhonewath(phone)}
                                          
                                        />
                                         <input type='hidden' name="whatsap" value={phonewath} />
                                </Form.Group>
                          </Row>
                             <Row className="mb-3">
                                <Form.Group as={Col} >
                                    <Form.Label>Avez-vous une boutique physique</Form.Label>
                                    <Form.Control type="text" placeholder="" name="boutiquephisique" />
                                </Form.Group>
                                <Form.Group as={Col} >
                                    <Form.Label>Lieu où nos livreur récupèreront les colis</Form.Label>
                                    <Form.Control type="text" placeholder="" name="lieu" />
                                </Form.Group>
                                
                          </Row>
                           <Row className="mb-3">
                               <Form.Group as={Col} >
                                    <Form.Label>Parlez brièvement de votre boutique</Form.Label>
                                    <Form.Control type="text" placeholder="Ex :  Nous vendons des articles de qualité  etc.." name="description" />
                                </Form.Group>
                           </Row>


                             <Row className="mb-3">
                                <Form.Group as={Col} >
                                    <Form.Label>Email*</Form.Label>
                                    <Form.Control type="email" placeholder="" name="email" required />
                                </Form.Group>
                                <Form.Group as={Col} >
                                    <Form.Label>Mot de passe*</Form.Label>
                                    <Form.Control type="password" placeholder="" name="password" required />
                                </Form.Group>
                              
                          </Row>




                          <div className="mb-4"><div className="form-check"><input required="" type="checkbox" className="form-check-input"/>
                               <label title=""className="form-check-label">Lu et approuver <b className='link' onClick={()=>gotocgv()}>les conditions générales de ventes.</b></label>
                               </div></div>

                              {
                                error &&
                                 <div className="mb-4">
                                   <div className='alert alert-danger'>
                                     Veuillez remplir les champs obligatoires numéro de téléphone et wathsApp
                                   </div>

                                 </div>
                              }
                            <Button className="ps-btn " type="submit"  >
                              Validez vos informations
                            </Button>




                         

                        </Form>
                  </Col>

       
                </Row>
            </Container>


      
        

        </div>
          </section>
    );
}

export default Page;