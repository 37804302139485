import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Alert, Button,Nav,ListGroup,ListGroupItem,Card,Table,Form,Modal,CloseButton} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { ProductItem, RestaurentItem } from "../../../components";
import { updateInfoUser,getOneCommandeById,UpdateUserAddresse,UpdateUserAddresseInf} from "../../../actions/user";
import "react-datepicker/dist/react-datepicker.css";
import {  getRestaurentById } from "../../../actions/restaurent";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { apiClient } from "../../../actions/api"
import { markerHome,defaultimg } from "../../../assets/images";
import { MenuVendeur } from "../../../components";
import { SET_CURRENT_COMMANDE} from "../../../constants/actions";
import moment from 'moment'
import GoogleMapReact from 'google-map-react';
import Slider from "react-slick";

const Page = () => {
    const { id } = useParams()
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { commandeinfo,currentRestau } = useSelector(state => state.restaurent)
    const [latitudeliv,setLatitudeliv]=useState(47.7198437);
    const [langitudelivreur,setLangitudelivreur]=useState(-1.3211272);
    const [detailcommande,setDetailcommande]=useState([]);
    const [commandeinfoadresse,setCommandeinfoadresse]=useState({});
     const [fairelepont,setFairelepont]=useState(0);
     const [checkdtype,setCheckdtype]=useState('');


      const [showmodal, setShowmodal] = useState(false);
      const handleClose = () => setShowmodal(false);


    useEffect(() => {

       let paramsd={
              datelivraison:id,
              user_id:user.user_id,
              token:user.api_token,
              api_token:user.api_token,
              type:'livreursuivilivraison'
           };
           
           apiClient().post(`/getdetailcommande`,paramsd)
           .then((res) => {
         
             setDetailcommande(res.data.commande)
             console.log(res.data.commande)
             //dispatch({type:SET_CURRENT_COMMANDE,payload:res.data.commande})
           
           }) 

 
    }, []);


const setstatuscommande =  (status) => {
    
        let paramsd={
              id_order:id,
              user_id:user.user_id,
              token:user.api_token,
              api_token:user.api_token,
              status:status,
           };
           
           apiClient().post(`/setstatusorder`,paramsd)
           .then((res) => {
         
             setDetailcommande(res.data.commande)
             dispatch({type:SET_CURRENT_COMMANDE,payload:res.data.commande})
           
           })

}

 const handleChange = async (e,item,key) => {
    
      //  setCheckdtype(item.order_id+'_'+e.target.value)

         let newcommandes=[...detailcommande];
         newcommandes[key].statuslivraison=e.target.value;
         setDetailcommande(newcommandes)
             
  
    }


     const setAdresse = async (e,item) => {
    
        setCommandeinfoadresse(item)
        setShowmodal(true)
        console.log(item)
            
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
        formDataObj.user_id=user.user_id
        formDataObj.token=user.api_token
        formDataObj.api_token=user.api_token
        formDataObj.datelivraison=id
        formDataObj.typereq='livreur'
        if(fairelepont){
          formDataObj.order_status=3
        }

     
           
        apiClient().post(`/updateorder`,formDataObj)
           .then((res) => {
         
             setDetailcommande(res.data.commande)
               alert('Votre demande a bién été enregistrée!')
             
           
           })

    }


const getRetours =  (detail) => {
      let retour=0;
       for(let i=0; i < detail.length; i++){

           if(detail[i]?.retour==1){
              retour=retour+1;
           }

      }

      return retour;

}


const getNbArticles =  (detail) => {
      let nbarticles=0;
       for(let i=0; i < detail.length; i++){

          
              nbarticles=nbarticles+detail[i].qterecupere;
           

      }

      return nbarticles;

}


const getValeurs =  (detail) => {
      let valeur=0;
       for(let i=0; i < detail.length; i++){

          
              valeur=valeur+(parseInt(detail[i].qterecupere - detail[i].qteretour ) * parseFloat(detail[i].product_price) );
           

      }

      return valeur;

}

const gotomaps =  () => {
     
        var options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          };
          
              if(navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function(position) {
                        
                        let lat = position.coords.latitude
                        let lon = position.coords.longitude
                        window.open("https://www.google.com/maps/dir/?api=1&origin="+lat+","+lon+"&destination="+commandeinfoadresse?.lat+","+commandeinfoadresse?.ln);

                         
                    });
                } else {
                    alert("Sorry, your browser does not support geolocation.");
                }
    }




    var settingsSlide = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide:0,
     responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
             infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            
            slidesToShow: 1,
            slidesToScroll: 1,
             infinite: true,
            dots: false
          }
        }
      ]
   };





   const { zoom } = useState(11);
   const [center,setCenter]=useState({lat:47.7198437,lng:-1.3211272});
   const AnyReactComponent = ({ text }) => <Row><img src={markerHome} alt="" className={"imgliv"}  /></Row>;
    return (
        <div className="register detailcmd suivilivraison " >
            <Container>
                <Row>
          
              
                    <Col  className="text-center mx-auto my-5"  >
                        
                 
                    
                     
    <Row className="mb-3"  >
            {
              fairelepont==0 &&
                <div> <a href='javascript:void(0)' onClick={()=>setFairelepont(!fairelepont)} className='coldash'><img src='/images/icofairepoint.png' /> <label className='lab778 lab778blue'>Faire le point</label></a></div>              
            }

            <div className='badge alert-primary'>Faire le point afin de marquer que la commande est bien livrée</div>
           
               
                        
     </Row>
         
<br/>
<Row className="g-5" >
                 
                  <Col md={12}  >

<div className='detcommande'>
     
        <div className='items'>
                            
                              <div className='rowitmtop'>
                                   <span> {moment(new Date(commandeinfo.datelivraison)).format("DD MMM YYYY")}</span>
                                     
                                </div>

                                <div className='process-item'>

                                   
                                     
                                  <div className='rowsitm'>
                                      <div> <span>- Colis récupérées: </span></div>
                                       <div><b>{parseInt(commandeinfo.nbcolirec)}</b></div>
                                       
                                    </div>
                                     <div className='rowsitm'>
                                      <div> <span>- Valeurs des colis: </span></div>
                                       <div><b>{parseFloat(commandeinfo.total).toFixed(2)} CFA</b></div>
                                       
                                    </div>
                                    <div className='rowsitm'>
                                       <div> <span>- Total articles: </span></div>
                                       <div><b>{parseInt(commandeinfo?.totalarticles)}</b></div>
                                       
                                    </div>

                                 </div>
                             
       </div>

 </div>
                 
                       


 
 
 <Row>
 
 <Col md={12}>

 


<div class="col-md-12"><h4>Détails </h4><div><img src="/images/fleshtobtm.png" style={{width: '30px'}} /></div></div>



    <Slider {...settingsSlide}>
          
             {detailcommande?.map((item,key) => (


 <form className="mb-5" onSubmit={handleSubmit} >

<div className='tabsitems'>

   <div className='items '>
                            
                                <div className='rowitmtop'>
                                   <span>Client</span> 
                                    <div><a href='javascript:void(0)' onClick={(e)=>setAdresse(e,item)} style={{color: '#004abf',fontWeight: 'bold',lineHeight: '0px'}} >{item?.client_name}</a></div>
                                </div>



           <Table striped bordered hover >
            
              <tbody>
            <tr>
            
            <td className='left' style={{fontWeight:'bold'}}>
               Numéro de commande
            </td>
             <td>
              
                  Réf: {item?.track_code}
                  <input type='text' value={item?.order_id} name='order_id' style={{display:'none'}} />
                       
            </td>
            </tr> 


        

              <tr>
            <td colspan="2">
                 <h5>Je confirme avoir récupéré </h5>
                 <span>

                 {item?.details_order?.map((item1) => ( 

                   item1.recupere==1 &&
                        <span style={{paddingRight:'10px'}}>{item1?.qterecupere} {item1?.pro_name}</span>
                  ))}


                 </span>
            </td>
       
    </tr>

    <tr>
            
            <td className='left' style={{fontWeight:'bold'}}>
               Date livraison
            </td>
             <td>
              
              
                    {moment(new Date(item?.datelivraison)).format("DD MMM YYYY")}
                       
            </td>
    </tr>



            <tr>

            <td className='left' style={{fontWeight:'bold'}}>
  Nombre d'articles
            </td>
             <td>
              
                  
                   {getNbArticles(item?.details_order)}
                       
            </td>
            </tr>



   <tr>
            <td className='left' style={{fontWeight:'bold'}}>
               Valeur
            </td>
             <td>
              
                      {getValeurs(item?.details_order).toFixed(2) }  CFA
            </td>
    </tr>

    <tr>
            <td className='left' style={{fontWeight:'bold'}}>
               Mode de paiement
            </td>
             <td>
              
                      
               {
                parseInt(item.type_paiement) == 1 ? 'Paiement à la livraison' :  parseInt(item.type_paiement) == 2 ? 'Orange Money, Moov Money,MTN Money': parseInt(item.type_paiement) == 3 ? 'Wave' : 'Paiement par carte bancaire'
               }
            </td>
    </tr>


{
  fairelepont==1 &&

    <tr>
            <td className='left' style={{fontWeight:'bold'}}>
               Montant reçu
            </td>
             <td>
                      {
                        parseInt(item.type_paiement) == 1 ?
                          <input type='number' name='montantrecu' defaultValue={item.montantrecu}/> 
                        :
                          <input type='number' name='montantrecu' disabled defaultValue={parseFloat(item.total_price).toFixed(2) }/> 


                      }
                      CFA
                     
            </td>
    </tr>
}
  
{
  fairelepont==1 &&

   <tr>
              {
                        parseInt(item.type_paiement) == 1 &&
         
             <td colspan='2' >
              
                  <div style={{display:'flex'}}>
                   <span style={{fontWeight:'bold'}}>  Frais de livraison</span> <div class="form-check" style={{    overflow: 'hidden',width: '121px'}}><label title="" fore='fraislivreur' class="form-check-label">Oui {item?.fraislivraison} CFA <input type="radio" name='fraislivreur' class="form-check-input" value='1' style={{marginLeft: '0.5em'}}/> </label> </div>
                    <div class="form-check" style={{marginLeft:'10px'}}><label title="" fore='fraislivreur' class="form-check-label">Non <input  type="radio" class="form-check-input" name='fraislivreur' value='0' style={{marginLeft: '0.5em'}}/> </label> </div>
                  </div>
            </td>
          }
    </tr>
}

 {getNbArticles(item?.details_order) > 0 &&
 <tr>

             <td colspan='2'>

             
                   <div class="form-check" style={{display:'flex',paddingLeft: '0px',marginBottom:'15px'}} ><label title="" class="form-check-label" style={{minWidth: '153px',color: 'green',fontWeight: 'bold'}}>J'ai livré tous les articles</label> <div style={{    textAlign: 'center',width: '10px',margin: '0 auto'}}><input style={{marginLeft: '-0.5em'}} type="radio" name='statuslivraison' value={1} class="form-check-input"  checked={item.statuslivraison == '1' }  onChange={(e)=>handleChange(e,item,key)} /> </div></div>
                   <div class="form-check" style={{display:'flex',paddingLeft: '0px',marginBottom:'15px'}} ><label title="" class="form-check-label" style={{minWidth: '153px',color: 'blue',fontWeight: 'bold'}}>J'ai livré quelques uns</label> <div style={{    textAlign: 'center',width: '10px',margin: '0 auto'}}><input style={{marginLeft: '-0.5em'}} type="radio" name='statuslivraison' value={2} class="form-check-input" checked={item.statuslivraison =='2'} onChange={(e)=>handleChange(e,item,key)} /></div></div>
                   <div class="form-check" style={{display:'flex',paddingLeft: '0px',marginBottom:'15px'}} ><label title="" class="form-check-label" style={{minWidth: '153px',color: 'red',fontWeight: 'bold'}}>Je n'ai pas pu livrer</label> <div style={{    textAlign: 'center',width: '10px',margin: '0 auto'}}><input style={{marginLeft: '-0.5em'}} type="radio" name='statuslivraison' value={3} class="form-check-input" checked={item.statuslivraison == '3'} onChange={(e)=>handleChange(e,item,key)}  /></div></div>
              

            </td>
     </tr>
 }

 
 {
  getNbArticles(item?.details_order) > 0 &&

     <tr>
            <td colspan="2">
                 <h5  style={{color:'#000'}}>Détail et motif du retour</h5>
                 <p>

                     <Form.Group   >
                                    
                                    <Form.Control type="text" placeholder=".." name="motif" defaultValue={item?.motif} />
                    </Form.Group>
<br/>
                       <Button className="ps-btn ps-btn--fullwidth ps-btn-dark"  type="submit">
                                Valider
                            </Button>

                 </p>
            </td>
       
    </tr>

 }
 

   



         </tbody>
</Table> 

         
                             
   </div>
</div>
</form>
 ))}               
                            

    </Slider>
  </Col>


</Row>
                   
  </Col>
                  
</Row>

<Row className="mb-3"  >
 <Col>
 
 </Col>

</Row>

 

 <Modal show={showmodal} onHide={handleClose} centered>
        <Modal.Header closeButton>
           Adresse de livraison
        </Modal.Header>
        <Modal.Body>


    <div class="ps-block--shopping-total cardlivraison">
       <h4>Adresse de livraison</h4>
       <br/>
       <label style={{display:'block'}}><strong>Nom:</strong> {commandeinfoadresse.address?.name} {commandeinfo.address?.surname} </label>
       <label style={{display:'block'}}><strong>Lieu de livraison:</strong> {commandeinfoadresse.address?.street},{commandeinfo.address?.postal_code}</label>
       <label style={{display:'block'}}><strong>Ville:</strong> {commandeinfoadresse.address?.city},{commandeinfo.address?.state}</label>
       <label style={{display:'block'}}><strong>Téléphone: </strong>{commandeinfoadresse.address?.phone_number}</label>
       <label style={{display:'block'}}><strong>Numéro WhatsApp: </strong>{commandeinfoadresse.address?.number}</label>
    </div>

    <br/>

    <div>
    {
      commandeinfoadresse?.lat &&
         <a href="javascript:void(0)" className='btn btn-primary' onClick={()=>gotomaps()}>Localisation du client <i className='fa fa-map'></i></a>

    }

 
    </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
           
        </Modal.Footer>
 </Modal>



 


 {
  /*

  <Row className="mb-3"  >
                           
                           <Col>
                           {commandeinfo.order?.state_id==10  && <Button className="w-100" variant="primary" type="submit">
                               En cours
                            </Button>}
                          
                            {commandeinfo.order?.state_id==20  && <Button className="w-100" variant="success" type="submit">
                              Validée
                            </Button>}
                             {commandeinfo.order?.state_id==40  && <Button className="w-100" variant="danger" type="submit">
                             Annulée
                            </Button>}
                         {commandeinfo.order?.state_id==30  && <Button className="w-100" variant="primary" type="submit">
                              Livrée
                            </Button>}
                          
                           </Col>
                        
                          </Row>
<Row className="g-5" >
                    <Col md={6}  >
                        <RestaurentItem item={currentRestau.establishment} />
                    </Col>
                  <Col md={6}  >
                  <h3>Menu</h3>
                         <Table striped bordered hover variant="dark">
                          <thead>
                            <tr>
                                <th>Désignation</th>
                                <th key="0">Prix</th>
                            
                             
                            </tr>
                          </thead>
              <tbody>
                      {commandeinfo.order?.products?.map((paniers) => (
                       <tr>
                        <td>{paniers._joinData.quantity}x{paniers.title}</td> <td>€{paniers._joinData.price.toFixed(2)}</td>
                         </tr>
                      
                    
                        

                        ))}

            <tr>
            <td>
 Prix livraison
            </td>
             <td>
              
                    {
                      commandeinfo?.order?.delivery_price==0 ? 'Gratuit'
                      :
                      '€'+commandeinfo?.order?.delivery_price.toFixed(2)
                    }
            </td>
            </tr>

   <tr>
            <td>
 Total
            </td>
             <td>
              
                      €{commandeinfo.order?.total.toFixed(2)}
            </td>
            </tr>

 <tr>
            <td>
 Mode de paiement
            </td>
             <td>
              
                Paiement par carte bancaire
            </td>
            </tr>

         </tbody>
</Table>
 
                   
                
  
                   

                    </Col>
                  
</Row>

    <Row className="mb-3"  >
    <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key:"AIzaSyBkcUpl985AUArtxvhkoOZokGN5ZdKDykE"}}
          defaultCenter={center}
          defaultZoom={11}
        >
          <AnyReactComponent
            lat={latitudeliv}
            lng={langitudelivreur}
            text="My Marker"
          />
        
        </GoogleMapReact>
        </div>
    
</Row>

  */
 }                       

  
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;