import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateInfoUser } from "../../actions/user";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';

import { Menu } from "../../components";
const Page = () => {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
  
    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
         dispatch(updateInfoUser(formDataObj,user.user_id,user.api_token,'client'));
      
    }
    
    useEffect(() => {
        
    }, []);
    return (
        <div className="register profile" >

            <Container>
                <Row>
  <Col  className="text-center mx-auto my-5"  lg="3">

         <Menu slug='profile' />

               
                </Col>
                    <Col  className="text-center mx-auto my-5"  >
                                  <Form className="mb-5" onSubmit={handleSubmit} >
                            <h3 className="mb-4" >Mes coordonnées</h3>
                            <Row className="mb-3">
                                <Form.Group as={Col} >
                                    <Form.Label>Prénom*</Form.Label>
                                    <Form.Control 
                                        defaultValue={user.first_name}
                                        type="text" placeholder="Robert" name="first_name" />
                                </Form.Group>
                                <Form.Group as={Col} >
                                    <Form.Label>Nom *</Form.Label>
                                    <Form.Control 
                                        defaultValue={user.last_name}
                                        type="text" placeholder="Aliox" name="last_name" />
                                </Form.Group>
                            </Row>
                            <Form.Group className="mb-3" >
                                <Form.Label>Email*</Form.Label>
                                <Form.Control 
                                    defaultValue={user.email}
                                    type="email" placeholder="mail@website.com" name="email" />
                            </Form.Group>

                             <Row className="mb-3">

                                <Form.Group as={Col} >
                                <Form.Label>Téléphone*</Form.Label>
                                <Form.Control 
                                    defaultValue={user.phone}
                                    type="tel" placeholder="+225*****" name="phone" />
                                </Form.Group>

                                <Form.Group as={Col} >
                                    <Form.Label>Numéro wathsApp</Form.Label>
                                    <Form.Control 
                                        defaultValue={user.numwhatsap}
                                        type="tel" placeholder="+225*****" name="numwhatsap" />
                                </Form.Group>

                                </Row>
                            
                          
                             <Form.Group className="mb-3" >
                                <Form.Label>Mot de passe</Form.Label>
                                <Form.Control type="password" placeholder="Min. 8 character" name="password" />
                            </Form.Group>
                         
                            <Button className="ps-btn ps-btn--fullwidth ps-btn-dark"  type="submit">
                                Modifier
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Page;