import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Form, Button,Nav,Card} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateInfoUser } from "../../../actions/user";
import "react-datepicker/dist/react-datepicker.css";
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';

import { MenuVendeur } from "../../../components";
const Page = () => {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
  
    const history = useHistory()
    const [startDate, setStartDate] = useState(new Date());
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const formDataObj = Object.fromEntries(formData.entries())
         dispatch(updateInfoUser(formDataObj,user.user_id,user.api_token,'livreur'));
      
    }
    
   useEffect(() => {
          
        if (!user.user_id || user.google_id!='L' ) {
            history.replace("/")
        }
    }, [user.user_id])

    return (


                <div className="register profile livreruprof" >

            <Container>
                <Row>
 
                    <Col  className="text-center mx-auto my-5 " lg={12}  >
                        <Form className="mb-5" onSubmit={handleSubmit} >
                            <h3 className="mb-4" >Mes informations</h3>


                            <Row className="mb-3">
                                <Form.Group  className='col-md-6' >
                                    <Form.Label>Votre Prénom*</Form.Label>
                                    <Form.Control type="text" placeholder="ex:Aliox Robert"  defaultValue={user.first_name}
                                         name="first_name" required />
                                </Form.Group>
                                <Form.Group className='col-md-6' >
                                    <Form.Label>Votre Nom *</Form.Label>
                                    <Form.Control type="text" placeholder="ex:Chahda" defaultValue={user?.last_name}  name="last_name" required />
                                </Form.Group>
                          </Row>
                          <Row className="mb-3">
                          
                                <Form.Group className='col-md-6' >
                                    <Form.Label>Ville ou commune*</Form.Label>
                                    <Form.Control type="text" placeholder="" defaultValue={user?.city}  name="city" required />
                                </Form.Group>
                                      <Form.Group className='col-md-6' >
                                    <Form.Label>N° CNI ou Passeport*</Form.Label>
                                    <Form.Control type="text" placeholder=""  defaultValue={user?.cin} name="cin" required />
                                </Form.Group>
                          </Row>

                             <Row className="mb-3">
                                <Form.Group  className='col-md-6' >
                                    <Form.Label>Numéro de téléphone*</Form.Label>
                                    <Form.Control type="text" placeholder="" name="phone" defaultValue={user?.phone} required   />
                                </Form.Group>
                                <Form.Group className='col-md-6' >
                                    <Form.Label>Numéro wathsApp</Form.Label>
                                    <Form.Control type="text" placeholder="" name="numwhatsap" defaultValue={user?.numwhatsap} required />
                                </Form.Group>
                          </Row>
                        


                             <Row className="mb-3">

                              <Form.Group className="col-md-6" >
                                <Form.Label>Email</Form.Label>
                                <Form.Control 
                                    defaultValue={user.email}
                                    type="email" placeholder="mail@website.com" name="email" />
                               </Form.Group>

                               <Form.Group className="col-md-6" >
                                <Form.Label>Mot de passe</Form.Label>
                                <Form.Control type="password" placeholder="Min. 8 character" name="password" />
                            </Form.Group>



  
                            </Row>

 

              
                            <Button className="ps-btn ps-btn--fullwidth ps-btn-dark"  type="submit">
                                Modifier
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>


 
    );
}

export default Page;