import React,{ useState,useEffect } from "react";
import './styles.scss';
import { Container, Row, Col, Alert, Button,Nav,ListGroup,ListGroupItem,Card,Table,Modal,CloseButton} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { ProductItem, RestaurentItem } from "../../../components";
import { updateInfoUser,getOneCommandeById,UpdateUserAddresse,UpdateUserAddresseInf} from "../../../actions/user";
import "react-datepicker/dist/react-datepicker.css";
import {  getRestaurentById } from "../../../actions/restaurent";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { BiMap,BiShoppingBag,BiUserCircle,BiHistory } from 'react-icons/bi';
import { apiClient } from "../../../actions/api"
import { markerHome,defaultimg } from "../../../assets/images";
import { Menu } from "../../../components";
import GoogleMapReact from 'google-map-react';
import { SET_CURRENT_COMMANDE} from "../../../constants/actions";
import moment from 'moment'

const Page = () => {
    const { id } = useParams()
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user)
    const { commandeinfo,currentRestau } = useSelector(state => state.restaurent)
    const [latitude,setLatitude]=useState(0);
    const [langitude,setLangitude]=useState(0);
     const [note,setNote] = useState(0); 
    const [detailcommande,setDetailcommande]=useState({});

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const handleCloseAlert = () => setShowAlert(false);

    useEffect(() => {

         let paramsd={
              id_order:id,
              user_id:user.user_id,
              token:user.api_token,
              api_token:user.api_token,
              type:'client',
           };
           
           apiClient().post(`/getdetailcommande`,paramsd)
           .then((res) => {
         
             setDetailcommande(res.data.commande)
             dispatch({type:SET_CURRENT_COMMANDE,payload:res.data.commande})

             if(res.data.commande?.employee?.note){
            setNote(res.data.commande?.employee?.note?.note)
             }
           
           })



      /*
         dispatch(getOneCommandeById(id));
         if(commandeinfo?.order?.id){
          let paramsd={
            id_order:commandeinfo?.order?.id
           };
           apiClient().post(`/getposlivreur/`,paramsd)
           .then((res) => {
             if (res.data?.lat){
             setCenter({lat:res.data.lat,lng:res.data.lng});
             setLangitudelivreur(res.data.lng);
             setLatitudeliv(res.data.lat);
             }
           })
        }
         const interval = setInterval(() => {
          console.log('This will run every second!');
          if(commandeinfo?.order?.id){
            let paramsd={
              id_order:commandeinfo?.order?.id
             };
             apiClient().post(`/getposlivreur/`,paramsd)
             .then((res) => {
               if (res.data?.lat){
               setCenter({lat:res.data.lat,lng:res.data.lng});
               setLangitudelivreur(res.data.lng);
               setLatitudeliv(res.data.lat);
               }
             })
          }
       

        }, 20000);
        return () => clearInterval(interval);
      */
    }, []);

const setNoteL = (e,s) => {

    e.preventDefault();

    if(detailcommande?.employee?.note?.note >0 ){
      return false;
    }
    setNote(s)


         let paramsd={
              order_id:id,
              user_id:user.user_id,
              user_name:user.name,
              token:user.api_token,
              api_token:user.api_token,
              employee_id:detailcommande?.employee_id,
              note:s,
              typereq:'clientnote'
           };
           
           apiClient().post(`/updateorder`,paramsd)
           .then((res) => {
         
            
           
           })


}
    
const sendlocalisation = (e) => {
 
 e.preventDefault();

   navigator.geolocation.getCurrentPosition(function(position) {
           let lat=position.coords.latitude;
           let ln=position.coords.longitude;

              let paramsd={
              order_id:id,
              user_id:user.user_id,
              token:user.api_token,
              api_token:user.api_token,
              lat:lat,
              ln:ln,
              typereq:'client'
           };
           
           apiClient().post(`/updateorder`,paramsd)
           .then((res) => {
         
           
             setAlertMessage("Votre position géographique est bien envoyée au livreur ! ")
              setShowAlert(true)
           
           })


    });


}

const setstatuscommande =  (status) => {

 

    
        let paramsd={
              id_order:id,
              user_id:user.user_id,
              token:user.api_token,
              api_token:user.api_token,
              status:status,
              motifrefus:'',
              client:'1'
           };
           
           apiClient().post(`/setstatusorder`,paramsd)
           .then((res) => {
         
             setDetailcommande(res.data.commande)
             dispatch({type:SET_CURRENT_COMMANDE,payload:res.data.commande})
           
           })

}


   const { zoom } = useState(11);
   const [center,setCenter]=useState({lat:47.7198437,lng:-1.3211272});
   const AnyReactComponent = ({ text }) => <Row><img src={markerHome} alt="" className={"imgliv"}  /></Row>;
    return (
        <div className="register detailcmd" >
            <Container>
                <Row>
            <Col  className="text-center mx-auto my-5"  lg="3">

                  <Menu slug='commandes' />
               
                </Col>
              
                    <Col  className="text-center mx-auto my-5"  >
                        
                  <form className="mb-5 mb-5mob">
                    <div className='flexdiv bty7'>
                      <h4 className="mb-4" style={{    marginBottom: '0px !important'}} >Commande Réf : {commandeinfo.track_code}</h4>
                        {commandeinfo.order_status==0 && <div className="badge  alert-warning"  >
                              En attente
                            </div>}
                         {commandeinfo.order_status==1 && <div className="badge alert-success"  >
                               Traité
                            </div>}
                        {commandeinfo.order_status==2 && <div className="badge alert-danger"  > Annulé</div>}
                        {commandeinfo.order_status==3 && <div className="badge alert-info"  > Livrée</div>}
                        {commandeinfo.order_status==4 && <div className="badge alert-secondary"  > En cours de livraison</div>}
                        {commandeinfo.order_status==5 && <div className="badge alert-valider"  > Validée</div>}
                        {commandeinfo.order_status==6 && <div className="badge alert-primary"  > Incomplet</div>}
                        {commandeinfo.order_status==7 && <div className="badge alert-danger2"  > Refusée</div>}
                    </div>
                     




    <Row className="mb-3"  >
                           
                           <Col>


                     

                       <br/>
                       <h3> {moment(new Date(commandeinfo.created_at)).format("DD MMM YYYY")}</h3>
                          
                       
                          
                           </Col>
                        
                          </Row>

                              
 


                      {
                             commandeinfo.order_status==0 &&
                          <Row>
                           <Col >

                              <a  href="javascript:void(0)"  onClick={()=>setstatuscommande(2)}   className="btnrmv btn-danger" data-microtip-position="left" data-tooltip="Modifier">Annuler ma commande <i class="icon-remove"></i></a>
                         
                          
                           </Col>
                        
                          </Row>
                        }

<Row className="g-5 " >

 <Col md={12} className='mb-5mob'>
           <Table striped bordered hover variant="dark">
            
              <tbody>

            {
              (commandeinfo?.type_commande == '3' || commandeinfo?.type_commande==3) && 

            <tr>
            
                    <td>
                       Prix demandé 
                    </td>
                     <td>
                      
                          {
                            detailcommande?.details_order?.length >0 &&

                            parseFloat(detailcommande?.details_order[0]?.product_price).toFixed(2)

                             } CFA
                               
                    </td>
                      
                
            </tr>

            }

             {
              (commandeinfo?.type_commande == '3' || commandeinfo?.type_commande==3 ) && 

            <tr>
            
                
                     <td>
                       Prix proposé 
                    </td>
                     <td>
                      
                         {parseFloat(commandeinfo.total_price).toFixed(2)}  CFA
                               
                    </td>
            </tr>

            }


            {
           
            commandeinfo?.type_commande != '3' &&



            <tr>
            
                    <td>
                Sous total
                    </td>
                     <td>
                      
                          {(parseFloat(commandeinfo.total_price) - parseFloat(commandeinfo.fraislivraison) ).toFixed(2)} CFA
                               
                    </td>
            </tr>



            }
   

            <tr>

            <td>
 Expédition
            </td>
             <td>

              {
                commandeinfo.fraislivraison>0 ?
                   parseFloat(commandeinfo.fraislivraison) +' CFA'
                   :
                   'Gratuite'

              }
              
                   
                       
            </td>
            </tr>

   <tr>
            <td>
 Total
            </td>
             <td>
              
                      {(parseFloat(commandeinfo.total_price)).toFixed(2)} CFA
            </td>
            </tr>

 <tr>
            <td>
 Mode de paiement
            </td>
             <td>

               {
                parseInt(commandeinfo.type_paiement) == 1 ? 'Paiement à la livraison' :  parseInt(commandeinfo.type_paiement) == 2 ? 'Orange Money, Moov Money,MTN Money': parseInt(commandeinfo.type_paiement) == 3 ? 'Wave' : 'Paiement par carte bancaire'
               }
              
                
            </td>
            </tr>

         </tbody>
</Table>
  </Col>
    <Col md={12}><h4>Détails des articles</h4><div><img style={{    width: '30px'}} src='/images/fleshtobtm.png'/></div></Col>
                 
                  <Col md={12} className=''  >
 

<div className='detcmdmobile'>
 <div className='item'>
   <h6> {
      commandeinfo.type_commande=='1' ? 'Fashion Style' : commandeinfo.type_commande=='2' ? 'Afro Création' :commandeinfo.type_commande=='3' ? 'Ventout' : 'Sur commande' 
    }</h6>

     {detailcommande?.details_order?.map((item) => ( 
          
     <div className='itemrow flswebYYU8'>
      <div className='img'>
         <img src={item?.products?.image_url} title={item.pro_name}/>
      </div>
      <div className='data'>
          <table>
          {
            item.size && 
              <tr><td><span>Taille: </span></td><td><div>{item.size} </div></td></tr>
          }
          {
            item.color && 
              <tr><td><span>Couleur: </span></td><td>
           <div className="_1xuQr" style={{backgroundColor: item.color}}></div>
            </td></tr>
          }
          
           
             <tr><td><span>Quantité: </span></td><td><div>{item.quantity}</div></td></tr>

          </table>
       <strong>{parseFloat(item.product_price).toFixed(2)} CFA</strong>
      </div>
   </div>

      ))}


 



 </div>


</div>
 <br/> <br/>
 <Row>
  <Col md={12} className='mb-5mob'>
    <div class="ps-block--shopping-total cardlivraison">
       <h4>Adresse de livraison</h4>
    
       <label>{commandeinfo.address?.name} {commandeinfo.address?.surname} </label> 
       <label>{commandeinfo.address?.street},{commandeinfo.address?.postal_code} </label>
       <label>{commandeinfo.address?.city},{commandeinfo.address?.state} </label>
       <label>{commandeinfo.address?.phone_number}</label>
       <br/>
       <h4>Date de livraison</h4>
       <label> { commandeinfo.datelivraison ? moment(new Date(commandeinfo.datelivraison)).format("DD/MM/YYYY") : '-'}</label>
        
    </div>
  </Col>



</Row>
                   
  </Col>
                  
</Row>

<Row className="mb-3"  >
 <Col>
 
 </Col>

</Row>

{
  detailcommande?.employee_id &&


   <Row className="mb-3 suivicmd"  >


     <Col md={12}><h4>Suivi de ma commande</h4><div><img style={{    width: '30px'}} src='/images/fleshtobtm.png'/></div></Col>

    <p>Service de livraison géré par <br/><strong>Mixed Mode Express</strong></p>
    <br/>

    <div className='col-md-3'>

      <div className='item' style={{marginBottom:'10px'}}>
       <label style={{display: 'block'}}>
        <strong style={{    textTransform: 'uppercase'}}>{detailcommande?.employee?.name}</strong>
       </label>

     
       
          {(detailcommande?.employee.picture=="" || !detailcommande?.employee.picture) && <Card.Img variant="top"  src={defaultimg} />}
          {detailcommande?.employee.picture && <Card.Img   style={{width:100,height:100,backgroundImage:`url(${"https://mixedmode.fr/gestion/public/uploads/"+detailcommande?.employee.picture})`,borderRadius:50,backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',alignSelf: 'center' ,marginTop:20}} />}
      
      </div>

    </div>

     <div className='col-md-3'>

        <div className='item'>
         <label >
           Contact du livreur
         </label>
         <div><a style={{color:'#666'}} href={"tel:"+detailcommande?.employee?.phone}> {detailcommande?.employee?.phone} </a> </div>

          
          
       </div>
           
    </div>

     <div className='col-md-3'>
        <div className='item' onClick={(e)=>sendlocalisation(e)} style={{cursor:'pointer'}}>
         <label >
          J'envoie ma géolocalisation au liveur
         </label>
          
          <div className='ico'> <img src='/images/maps.png' /></div>
          
       </div>
    </div>


 

    <div className='col-md-3'>
        <a className='item' style={{    lineHeight: '22px'}} download href={detailcommande?.payment?.payment_id ?  detailcommande?.payment?.invoice_url : '#'}>
         <label>
         Voir ma facture de livraison
         </label>
          
          <div className='ico'> <img src='/images/invoiceico.png' /></div>
          
       </a>
    </div>

    <div className='col-md-3'>
        <div className='item'>
         <label>
           Notez le livreur
         </label>
          
       
          <div className="ps-product__rating">
                        <div className="form-group form-group__rating">
                              <div className="br-wrapper br-theme-fontawesome-stars">
                                            <div className="br-widget">
                                                    <a onClick={(e)=>setNoteL(e,1)} data-rating-value="1" data-rating-text="1" className={(note>0 ? 'br-selected br-current' : '')}>
                                                    </a><a onClick={(e)=>setNoteL(e,2)} data-rating-value="2" data-rating-text="2" className={(note>1 ? 'br-selected br-current' : '')}>
                                                    </a><a onClick={(e)=>setNoteL(e,3)} data-rating-value="3" data-rating-text="3" className={(note>2 ? 'br-selected br-current' : '')}>
                                                    </a><a onClick={(e)=>setNoteL(e,4)} data-rating-value="4" data-rating-text="4" className={(note>3 ? 'br-selected br-current' : '')}>
                                                    </a><a onClick={(e)=>setNoteL(e,5)} data-rating-value="5" data-rating-text="5" className={(note>4 ? 'br-selected br-current' : '')}>
                                                    </a>

                                                    {
                                                      note > 0 &&

                                                       <div className="br-current-rating" style={{display:'block'}}>
                                                         ({note})
                                                    </div>
                                                    }

                                                   

                                            </div>
                                    </div>
                            </div>

          </div>
           <span>{detailcommande?.employee?.avg?.note ? detailcommande?.employee?.avg?.note : 0 }/5</span>
          
       </div>
    </div>


    </Row>




}

    

    <Row className="mb-3"  >



    
</Row>


 {
  /*
    <div style={{ height: '400px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key:"AIzaSyBkcUpl985AUArtxvhkoOZokGN5ZdKDykE"}}
          defaultCenter={center}
          defaultZoom={11}
        >
          <AnyReactComponent
            lat={latitudeliv}
            lng={langitudelivreur}
            text="My Marker"
          />
        
        </GoogleMapReact>
        </div>
  <Row className="mb-3"  >
                           
                           <Col>
                           {commandeinfo.order?.state_id==10  && <Button className="w-100" variant="primary" type="submit">
                               En cours
                            </Button>}
                          
                            {commandeinfo.order?.state_id==20  && <Button className="w-100" variant="success" type="submit">
                              Validée
                            </Button>}
                             {commandeinfo.order?.state_id==40  && <Button className="w-100" variant="danger" type="submit">
                             Annulée
                            </Button>}
                         {commandeinfo.order?.state_id==30  && <Button className="w-100" variant="primary" type="submit">
                              Livrée
                            </Button>}
                          
                           </Col>
                        
                          </Row>
<Row className="g-5" >
                    <Col md={6}  >
                        <RestaurentItem item={currentRestau.establishment} />
                    </Col>
                  <Col md={6}  >
                  <h3>Menu</h3>
                         <Table striped bordered hover variant="dark">
                          <thead>
                            <tr>
                                <th>Désignation</th>
                                <th key="0">Prix</th>
                            
                             
                            </tr>
                          </thead>
              <tbody>
                      {commandeinfo.order?.products?.map((paniers) => (
                       <tr>
                        <td>{paniers._joinData.quantity}x{paniers.title}</td> <td>€{paniers._joinData.price.toFixed(2)}</td>
                         </tr>
                      
                    
                        

                        ))}

            <tr>
            <td>
 Prix livraison
            </td>
             <td>
              
                    {
                      commandeinfo?.order?.delivery_price==0 ? 'Gratuit'
                      :
                      '€'+commandeinfo?.order?.delivery_price.toFixed(2)
                    }
            </td>
            </tr>

   <tr>
            <td>
 Total
            </td>
             <td>
              
                      €{commandeinfo.order?.total.toFixed(2)}
            </td>
            </tr>

 <tr>
            <td>
 Mode de paiement
            </td>
             <td>
              
                Paiement par carte bancaire
            </td>
            </tr>

         </tbody>
</Table>
 
                   
                
  
                   

                    </Col>
                  
</Row>

    <Row className="mb-3"  >
    <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key:"AIzaSyBkcUpl985AUArtxvhkoOZokGN5ZdKDykE"}}
          defaultCenter={center}
          defaultZoom={11}
        >
          <AnyReactComponent
            lat={latitudeliv}
            lng={langitudelivreur}
            text="My Marker"
          />
        
        </GoogleMapReact>
        </div>
    
</Row>

  */
 }                       

</form>  
                    </Col>
                </Row>

                     <Modal show={showAlert} onHide={handleCloseAlert} centered>
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
          {alertMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAlert} style={{background: '#000'}}>
            Fermer
          </Button>
          
        </Modal.Footer>
</Modal>

            </Container>
        </div>
    );
}

export default Page;